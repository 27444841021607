export enum EGenderType {
  MALE = 'M',
  FEMALE = 'F',
}

export enum EUserType {
  SUPPLIER = 'Supplier',
  CUSTOMER = 'Customer',
  DOCTOR = 'Doctor',
  VET247 = 'Vet247',
}
