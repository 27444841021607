import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '../scss/UserProfileEdit.scss';

import { EGenderType, EUserType } from '../shared/enum/user.enum';

import { BASE_IMG_URL } from '../store/constants/base.constant';

import { IUserUpdate } from '../shared/interface/user.interface';
import { IGlobalStorage } from '../shared/interface/reduxStore.interface';

import { updateUserInfo } from '../services/userService';

import { notifyMessage } from '../App';

import { fetchUserData } from '../store/actions/user/fetchAction';
import { fileValidation } from '../services/fileValidationService';

function UserProfileEditComponent() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IUserUpdate>({
    defaultValues: {
      gender: EGenderType.MALE,
      name: null,
      shipping_address: null,
      phone: null,
      profile_image: null,
    },
  });

  const { data } = useSelector((state: IGlobalStorage) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const selectedGender = watch('gender');
  const name = watch('name');
  const email = watch('email');
  const phone = watch('phone');
  const shipping_address = watch('shipping_address');
  const image = watch('profile_image');

  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fileUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const fileObj: File = e.target.files[0];
      const isValidate = fileValidation(fileObj);
      if (isValidate) {
        setFile(e.target.files[0]);
        setValue('profile_image', URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  const onSubmit = async () => {
    await updateUser();
  };

  const handleGenderChange = (gender: string) => {
    setValue('gender', gender);
  };

  const updateUser = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      name && formData.append('name', name);
      email && formData.append('email', email);
      phone && formData.append('phone', phone);
      selectedGender && formData.append('gender', selectedGender);
      shipping_address && formData.append('shipping_address', shipping_address);
      file && formData.append('image', file);
      const updatedRes = await updateUserInfo(formData);
      dispatch(fetchUserData(navigate));
      setLoading(false);
      notifyMessage.SUCCESS(updatedRes?.message ?? 'User updated successfully');
    } catch (error: any) {
      setLoading(false);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'User cannot be updated');
    }
  };

  const updateFormData = () => {
    setValue('name', data?.name ?? null);
    setValue('email', data?.email ?? null);
    setValue('phone', data?.user?.phone ?? null);
    setValue('shipping_address', data?.shipping_address ?? null);
    setValue('profile_image', data?.profile_image ?? null);
  };

  useEffect(() => {
    updateFormData();
  }, [data]);

  return (
    <>
      <div className='container'>
        <div className='w-sm-75 w-md-75 w-lg-50 mx-auto'>
          <div className='d-flex align-items-center'>
            {data.user.user_type === EUserType.CUSTOMER && (
              <Link to={'/'}>
                <a className='btn p-0 m-0 me-3'>
                  <i className='fa-solid fa-arrow-left'></i>
                </a>
              </Link>
            )}

            {data.user.user_type === EUserType.SUPPLIER && (
              <Link to={'/dashboard'}>
                <a className='btn p-0 m-0 me-3'>
                  <i className='fa-solid fa-arrow-left'></i>
                </a>
              </Link>
            )}

            {data.user.user_type === EUserType.DOCTOR && (
              <Link to={'/doctor/profile'}>
                <a className='btn p-0 m-0 me-3'>
                  <i className='fa-solid fa-arrow-left'></i>
                </a>
              </Link>
            )}
            <p className='fs-5 text-secondary mt-3 font-hahmlet-bold'>Edit User Profile</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className='user-edit-form'>
            <div className='upload-img'>
              <input hidden onChange={(e: ChangeEvent<HTMLInputElement>) => fileUploadHandler(e)} id='userProfileUploadFile' type='file' />
              <label htmlFor='userProfileUploadFile' className='uploader-backdrop'>
                <div className='user-img'>
                  {image ? (
                    <div className='h-100 w-100 overflow-hidden rounded-circle'>
                      <img className='h-100 w-100' src={!file ? BASE_IMG_URL + image : image} />
                    </div>
                  ) : (
                    <i className='fa-regular fa-user text-primary fs-3'></i>
                  )}

                  <i className='fa-solid fa-plus'></i>
                </div>
              </label>
            </div>
            <div>
              <label htmlFor='userFullName'>Full Name</label>
              <input {...register('name')} id='userFullName' type='text' className='form-control' placeholder='Enter Your Name' />
              <div className='validation-sign'>
                {!errors?.name && name && <i className='fa-solid fa-circle-check fs-md text-success'></i>}
                {errors?.name && <i className='fa-solid fa-circle-xmark fs-md text-danger'></i>}
              </div>
            </div>
            <div>
              <label>Gender</label>
              <div className='d-flex gender-button'>
                <button
                  type='button'
                  onClick={() => handleGenderChange(EGenderType.MALE)}
                  className={`${selectedGender === EGenderType.MALE && 'bg-primary text-white'} btn rounded-pill me-2 fw-bold`}>
                  <i className={`${selectedGender === EGenderType.MALE && 'text-white'} fa-solid fa-mars me-2`}></i>
                  Male
                </button>
                <button type='button' onClick={() => handleGenderChange(EGenderType.FEMALE)} className={`${selectedGender === EGenderType.FEMALE && 'bg-primary text-white'} btn rounded-pill fw-bold`}>
                  <i className={`${selectedGender === EGenderType.FEMALE && 'text-white'} fa-solid fa-venus me-2`}></i>
                  Female
                </button>
              </div>
            </div>
            <div>
              <label htmlFor='userEmail'>Email</label>
              <input
                {...register('email', {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                })}
                id='userEmail'
                type='email'
                className='form-control'
                placeholder='Enter Your Email'
              />
              <div className='validation-sign'>
                {!errors?.email && email && <i className='fa-solid fa-circle-check fs-md text-success'></i>}
                {errors?.email && <i className='fa-solid fa-circle-xmark fs-md text-danger'></i>}
              </div>
              {errors?.email?.type === 'pattern' && <p className='mb-0 fs-sm text-danger'>Invalid Email Address</p>}
            </div>
            <div>
              <label htmlFor='userPhone'>Phone</label>
              <div className='input-group'>
                <span className='input-group-text fs-6 bg-white text-primary fw-bold'>+88</span>
                <input disabled {...register('phone')} id='userPhone' type='text' className='form-control fs-6 ps-2' placeholder='1710 XXX XXX' />
              </div>
              <div className='validation-sign'>
                {!errors?.phone && phone && <i className='fa-solid fa-circle-check fs-md text-success'></i>}
                {errors?.phone && <i className='fa-solid fa-circle-xmark fs-md text-danger'></i>}
              </div>
              {errors?.phone && <p className='mb-0 fs-sm text-danger'>Invalid Phone Number</p>}
            </div>
            <div>
              <label htmlFor='userAddress'>Address</label>
              <textarea
                {...register('shipping_address', {
                  required: true,
                })}
                id='userAddress'
                rows={4}
                className='form-control'
                placeholder='Enter Address'></textarea>
              {errors?.shipping_address && <p className='mb-0 fs-sm text-danger'>Address Should Not Be Empty</p>}
            </div>
            <div className='text-center my-3'>
              <button disabled={loading} className='w-xl-30 w-lg-40 w-sm-50 w-50 mx-auto btn btn-primary py-2 rounded-pill'>
                {loading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UserProfileEditComponent;
