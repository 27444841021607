import DoctorReviewListComponent from '../components/review/DoctorReviewListComponent';

function DoctorReviewList() {
  return (
    <>
      <DoctorReviewListComponent />
    </>
  );
}

export default DoctorReviewList;
