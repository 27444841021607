import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { signup } from '../../store/actions/auth/signupAction';

const schemaSignup = yup.object().shape({
  phone: yup
    .string()
    .matches(/^\d{10,11}$/, 'Phone number must be in the correct format')
    .required('Phone number is required'),
});

interface ISignup {
  phone: string;
}

function SignupForm() {
  const navigate = useNavigate();
  const { error: errorMsg, loading } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch<any>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ISignup>({
    resolver: yupResolver(schemaSignup),
  });
  const onSubmitSignup: SubmitHandler<ISignup> = async (data: any) => {
    await dispatch(signup(data, reset));
    localStorage.setItem('phone', data.phone);
    navigate('/login/otp');
  };
  return (
    <>
      {errorMsg && errorMsg}
      <form onSubmit={handleSubmit(onSubmitSignup)} noValidate>
        <div className='my-2'>
          <input {...register('phone')} type='text' placeholder='Phone' className='w-100 form-control py-2 ps-4' />
          {errors.phone && <span className='display-10  text-primary'>**{errors.phone.message}</span>}
        </div>
        <button type='submit' disabled={loading} className='btn btn-primary text-white w-100 rounded-3 my-4 my-4'>
          {loading ? 'Loading...' : 'Submit'}
        </button>
        <Link to={'/'}>
          <button className='btn btn-outline-primary w-100'>Back to home</button>
        </Link>
      </form>
    </>
  );
}

export default SignupForm;
