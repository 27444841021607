import { Link } from 'react-router-dom';

import { BASE_IMG_URL } from '../../store/constants/base.constant';

function ShopByBrand({ brands }: any) {
  return (
    <div className='mt-2'>
      <div className='card border-0 bg-light-200 pt-0'>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <h5 className='text-secondary font-hahmlet-medium'>Shop By Brands</h5>
            <p>
              <Link to={`/shop/brand`}>
                <a>See More</a>
              </Link>
            </p>
          </div>
          <div className='row'>
            {brands?.map((item: any, index: number) => (
              <div key={`shopByBrand_${index}`} className='col-2 my-2'>
                <Link to={`/products/?sub_category=${item?.name}`}>
                  <div className='text-center h-100'>
                    <img className='shadow-sm w-100 rounded-3 h-100' src={`${BASE_IMG_URL}/${item.image}`} alt='' />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopByBrand;
