import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IProduct, IProductType } from '../shared/interface/product.interface';

import ProductCardComponent from '../components/product/Card';
import SearchComponent from '../components/product/Search';
import SortComponent from '../components/product/Sort';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { getAllProducts, getProductsByURL } from '../services/productService';

import { StateContext } from '../App';

import { IPagination } from '../shared/interface/app.interface';

function Product() {
  const { productTypeState } = useContext(StateContext);
  const [productTypes] = productTypeState;
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<IPagination<IProduct[]>>({
    results: [],
  });

  const [selectedProductType, setSelectedProductType] = useState<IProductType>(productTypes[0]);

  const [ordering, setOrdering] = useState('price');
  const [searchParam] = useSearchParams();
  const category: any = searchParam.get('category') ?? '';
  const sub_category: any = searchParam.get('sub_category') ?? '';

  const loadProductByType = async (productType: IProductType) => {
    setSelectedProductType(() => productType);
    loadAllProducts(productType?.name === 'All' ? undefined : productType?.name);
  };

  const loadAllProducts = async (product_type?: string) => {
    try {
      setLoading(() => true);
      const productsRes = await getAllProducts({ search, ordering, category, sub_category, product_type, page_size: 12 });
      setProducts(
        productsRes ?? {
          result: [],
        }
      );
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const loadAllProductsByURL = async (url?: string | null) => {
    try {
      if (url) {
        setLoading(() => true);
        const productsRes = await getProductsByURL(url);
        setProducts(
          productsRes ?? {
            result: [],
          }
        );
        setLoading(() => false);
      }
    } catch (error) {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    loadAllProducts();
  }, [search, ordering, category, sub_category]);

  const handleSearchChange = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  const handleSortChange = (e: any) => {
    const sort = e.target.value;
    setOrdering(sort);
  };
  return (
    <>
      <div className='py-3 bg-white'>
        <div className='bg-light-200 mx-3 rounded-3'>
          <div className='row pt-sm-3 pt-1 align-items-center container-fluid'>
            <div className='col-lg-2'>
              <h5 className='font-hahmlet-bold py-2 ps-1 ps-sm-2 bg-light-200'>All Products</h5>
            </div>
            <div className='col-lg-8'>
              <SearchComponent handleSearchChange={handleSearchChange} />
            </div>
            <div className='col-lg-2 text-end pe-lg-0'>
              <SortComponent handleSortChange={handleSortChange} />
            </div>
          </div>
          <div className='mb-3 mx-4'>
            {productTypes?.map((productType: IProductType, index: number) => (
              <span
                key={`${productType.id}_product_filter_${index}`}
                onClick={() => loadProductByType(productType)}
                className={`${selectedProductType?.id === productType?.id ? 'bg-primary' : 'border border-primary text-primary'} badge rounded-pill my-3 mx-1 cursor-pointer`}>
                {productType?.name ?? ''}
              </span>
            ))}
          </div>
          <div className='border-0 bg-light-200 rounded-3 px-3 px-sm-4'>
            {
              <>
                {loading && <TransparentSpinner />}
                <div>
                  {products?.results?.length ? (
                    <div className='row justify-content-center py-3'>
                      {products?.results?.map((product: IProduct, index: number) => (
                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 py-3' key={index}>
                          <ProductCardComponent product={product} />
                        </div>
                      ))}
                      <div>
                        <div className='d-flex justify-content-center'>
                          <div className='mx-1'>
                            <button disabled={!products?.previous ?? true} onClick={() => loadAllProductsByURL(products?.previous)} className='btn btn-primary'>
                              <i className='fa-solid fa-chevron-left'></i>
                            </button>
                          </div>
                          <div className='mx-1'>
                            <button disabled={!products?.next ?? true} onClick={() => loadAllProductsByURL(products?.next)} className='btn btn-primary'>
                              <i className='fa-solid fa-chevron-right'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p>No product found</p>
                  )}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
