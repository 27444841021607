import { useContext, useLayoutEffect, useState } from 'react';

import '../scss/home.scss';

import HotDealComponent from '../components/dashboard/HotDeal';
import ShopByPetComponent from '../components/dashboard/ShopByPets';
import ShopByBrandComponent from '../components/dashboard/ShopByBrands';
import VacationComponent from '../components/dashboard/Vacation';
import PetBlogComponent from '../components/dashboard/PetBlog';
import SubmitPetComponent from '../components/dashboard/SubmitPet';
import VetClinicVideoComponent from '../components/dashboard/VetClinicVideo';
import ServiceOverviewComponent from '../components/dashboard/ServiceOverview';
import SpecializedTeamComponent from '../components/dashboard/SpecializedTeam';
import FaqComponent from '../components/dashboard/FAQ';
import FullScreenSpinner from '../components/spinner/FullScreenSpinner';
import MessagePopupComponent from '../components/message-popup/MessagePopup';

import { getCarts } from '../services/cartService';

import { StateContext } from '../App';

import { IHomeCategories } from '../shared/interface/home.interface';
import MobileAppModalComponent from '../components/dashboard/MobileAppModal';
import { getIsAlreadyOpenMobileAppModal, setIsAlreadyOpenMobileAppModal } from '../shared/service/storageService';

function Home() {
  const { cartState, categoryState } = useContext(StateContext);
  const [categories]: [IHomeCategories] = categoryState;
  const setCarts = cartState[1];

  const [loading, setLoading] = useState<boolean>(false);
  const [showMobileAppModal, setShowMobileAppModal] = useState(false);
  const loadCarts = async () => {
    try {
      setLoading(() => true);
      const cartRes = await getCarts();
      setCarts(() => cartRes.data);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  useLayoutEffect(() => {
    const isOpen = getIsAlreadyOpenMobileAppModal();
    if (!isOpen) {
      setShowMobileAppModal(true);
    }
    loadCarts();
  }, []);

  return (
    <>
      <MobileAppModalComponent
        show={showMobileAppModal}
        onHide={() => {
          setIsAlreadyOpenMobileAppModal(true);
          setShowMobileAppModal(false);
        }}
      />
      <MessagePopupComponent />
      <div className='container-fluid'>
        {loading ? (
          <FullScreenSpinner />
        ) : (
          <div className='row'>
            <div className='col-lg-4 col-md-6 pt-2'>
              <div className='col-lg-12 col-md-12'>
                <HotDealComponent banners={categories?.banner} />
              </div>
              <div className='col-lg-12 col-md-12'>
                <ServiceOverviewComponent services={categories?.doctor_services} />
              </div>
              <div className='col-lg-12 col-md-12'>
                <ShopByPetComponent pets={categories?.pet_subcategories} />
              </div>
              <div className='col-lg-12 col-md-12'>
                <ShopByBrandComponent brands={categories?.brand_subcategories} />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 pt-2'>
              <div className='col-lg-12 col-md-12'>
                <VacationComponent />
              </div>
              <div className='col-lg-12 col-md-12'>
                <PetBlogComponent blogs={categories?.blogs ?? []} />
              </div>
              <div className='col-lg-12 col-md-12'>
                <SubmitPetComponent />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 pt-2'>
              <div className='col-lg-12 col-md-12'>
                <VetClinicVideoComponent />
              </div>
              <div className='col-lg-12 col-md-12'>
                <SpecializedTeamComponent />
                <FaqComponent faqs={categories?.faqs ?? []} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Home;
