import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import '../scss/ContactUs.scss';

import { getContactUs } from '../services/appService';

import { IContactUs } from '../shared/interface/app.interface';

import FullScreenSpinner from '../components/spinner/FullScreenSpinner';

function ContactUsComponent() {
  const [contactUs, setContactUs] = useState<IContactUs | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const loadContactUs = async () => {
    try {
      setLoading(true);
      const contactUsRes = await getContactUs();
      if (contactUsRes?.data?.[0]) {
        setContactUs(contactUsRes?.data?.[0] ?? null);
      } else {
        navigate('/not-found');
      }
      setLoading(false);
    } catch (error) {}
  };

  useLayoutEffect(() => {
    loadContactUs();
  }, []);
  return (
    <>
      {loading && <FullScreenSpinner />}
      <div className='container'>
        <p className='fs-5 text-secondary font-hahmlet-semibold mt-3'>Contact Us</p>
        <div className='card border-0'>
          <div className='card-body px-lg-5 bg-light-100 rounded-3'>
            <div className='row pt-3 justify-content-center pt-lg-5'>
              <div className='col-lg-4 col-md-6 pt-5 pt-lg-0'>
                <div className='contact-card card border-warning rounded-1 h-100'>
                  <div className='contact-icon'>
                    <i className='fa-solid fa-phone text-primary fs-4'></i>
                  </div>
                  <div className='card-body mx-sm-5 mt-3'>
                    <p className='mb-1'>Phone:</p>
                    {contactUs?.phones?.map((phone: string) => (
                      <p className='mb-0'>{phone}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 pt-5 pt-lg-0 align-self-stretch'>
                <div className='contact-card card border-warning rounded-1 h-100'>
                  <div className='contact-icon'>
                    <i className='fa-solid fa-map-location-dot text-primary fs-4'></i>
                  </div>
                  <div className='card-body mx-sm-4 mt-3'>
                    <p className='mb-1'>Address:</p>
                    {contactUs?.addresses?.map((address: string) => (
                      <p className='mb-0'>{address}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 pt-5 pt-lg-0 align-self-stretch'>
                <div className='contact-card card border-warning rounded-1 h-100'>
                  <div className='contact-icon'>
                    <i className='fa-solid fa-envelope text-primary fs-4'></i>
                  </div>
                  <div className='card-body mx-sm-5 mt-3'>
                    <p className='mb-1'>Email:</p>
                    {contactUs?.emails?.map((email: string) => (
                      <p className='mb-0'>{email}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='row pt-3 pb-4 pt-lg-5'>
              <div className='col-lg-7'>
                <p className='fs-5 text-secondary fw-bold mb-lg-4'>Send Message</p>
                <form className='contact-form'>
                  <input type='text' className='form-control' placeholder='Name' />
                  <div className='d-flex my-3'>
                    <input type='email' className='form-control w-50 me-3' placeholder='Email' />
                    <input type='text' className='form-control w-50' placeholder='Phone' />
                  </div>
                  <textarea className='form-control' rows={4} placeholder='Message'></textarea>
                  <div className='text-center mt-3'>
                    <button className='btn btn-primary rounded-pill px-5'>Send</button>
                  </div>
                </form>
              </div>
              <div className='col-lg-5'>
                <p className='fs-5 text-secondary fw-bold mb-lg-4'>View Location</p>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7303.60373768203!2d90.3799914!3d23.7544438!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b90e39358373%3A0x5c8948a154222082!2sNexo%20Technologies%20Limited!5e0!3m2!1sen!2sbd!4v1719745575391!5m2!1sen!2sbd'
                  className='w-100 h-75 rounded-3 shadow-sm'
                  height={200}
                  allowFullScreen={false}
                  loading='lazy'></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsComponent;
