import axios from 'axios';

import { IHttpResponse } from '../shared/interface/http.interface';

import { BASE_URL } from '../store/constants/base.constant';
import { ICategory, ISubCategory } from '../shared/interface/category.interface';
import { ISupplierProductPayload } from '../shared/interface/product.interface';
import { axiosConfig } from '../shared/constant/axiosConfig';

export const getAllCategories = async (): Promise<IHttpResponse<ICategory[]>> => {
  const res = await axios.get<IHttpResponse<ICategory[]>>(`${BASE_URL}/categories/categories`);
  return res.data;
};

export const getAllSubCategories = async (): Promise<IHttpResponse<ISubCategory[]>> => {
  const res = await axios.get<IHttpResponse<ISubCategory[]>>(`${BASE_URL}/categories/sub-categories`);
  return res.data;
};
