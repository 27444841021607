import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../../scss/home.scss';

import { StateContext } from '../../App';
import { IDoctor } from '../../shared/interface/doctor.interface';

function SpecializedTeam() {
  const { vetTeamState, loadDoctors } = useContext(StateContext);
  const [vetTeam, setVetTeam] = vetTeamState;

  useEffect(() => {
    loadDoctors();
  }, []);

  return (
    <div className='card border-0 bg-light-200 mt-2'>
      <div className='card-body'>
        <div className='d-flex justify-content-between'>
          <h5 className='text-secondary font-hahmlet-medium'>Specialized Vet Team</h5>
          <p>
            <Link to={`/doctors`}>
              <a>See More</a>
            </Link>
          </p>
        </div>
        <div className='row'>
          {vetTeam?.map((doctor: IDoctor, key: number) => (
            <div key={`specializedTeam_${key}`} className='col-4 my-2'>
              <div className='text-center'>
                <Link to={`/doctors/${doctor?.id}`}>
                  <img className='vet_team_img' src={doctor.profile_image} alt='' />
                  <small className='fw-bold d-block text-black mt-2'>{doctor?.name}</small>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SpecializedTeam;
