import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IBlogDetails } from '../shared/interface/home.interface';
import { IHttpResponse } from '../shared/interface/http.interface';
import { IContactUs, IGenericPageData } from '../shared/interface/app.interface';

export const getContactUs = async (): Promise<IHttpResponse<IContactUs[]>> => {
  const contactRes = await axios.get<IHttpResponse<IContactUs[]>>(`${BASE_URL}/base/generic/web/contacts/`);
  return contactRes.data;
};

export const getGenericData = async (key: string): Promise<IHttpResponse<IGenericPageData[]>> => {
  const contactRes = await axios.get<IHttpResponse<IGenericPageData[]>>(`${BASE_URL}/base/generic/web/${key}/`);
  return contactRes.data;
};

export const getBlogDetails = async (blogId: number): Promise<IHttpResponse<IBlogDetails>> => {
  const contactRes = await axios.get<IHttpResponse<IBlogDetails>>(`${BASE_URL}/base/generic/web/blog/${blogId}/`);
  return contactRes.data;
};

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month: string | number = today.getMonth() + 1;
  let day: string | number = today.getDate();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};
