function DashboardOrderList({ data }: any) {
  const formatDate = (dateString: any) => {
    const options: any = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  return (
    <>
      <div className='card rounded-4 pt-4 px-3 pb-2 border-snow-white shadow-sm'>
        <div className='card-title'>
          <h5>Order List</h5>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>#</th>
                  <th style={{ width: '40%' }}>Name</th>
                  <th style={{ width: '10%' }}>Quantity</th>
                  <th style={{ width: '20%' }}>Date</th>
                  <th style={{ width: '20%' }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {data.map((order: any, index: number) => (
                  <tr key={order.id}>
                    <td>{index + 1}</td>
                    <td>{order?.supplier_product?.title}</td>
                    <td>
                      <div className='value p-2 text-center rounded-3'>{order.quantity}</div>
                    </td>
                    <td>{order?.order_date && formatDate(order.order_date)}</td>
                    {order?.total_amount && <td>{`৳${order?.total_amount}`}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardOrderList;
