import { useContext, useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { StateContext, notifyMessage } from '../../App';

import { BASE_IMG_URL } from '../../store/constants/base.constant';
import { sliderSettings } from '../../shared/constant/sliderSettings.constant';

import { addToCart, createCart, getCarts } from '../../services/cartService';

import TransparentSpinner from '../spinner/TransparentSpinner';
import { EProductSliderType } from '../../shared/enum/product.enum';

function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <div className={'carousel-control-next'} onClick={onClick}>
      <button className='carousel-control-next' type='button'>
        <i className='fa-solid fa-chevron-right text-primary'></i>
      </button>
    </div>
  );
}

function PrevArrow(props: any) {
  const { onClick } = props;
  return (
    <div className={'carousel-control-prev'} onClick={onClick}>
      <button className='carousel-control-prev' type='button'>
        <i className='fa-solid fa-chevron-left text-primary'></i>
      </button>
    </div>
  );
}

function ProductSlider({ popularProducts, type }: { popularProducts: any[]; type: string }) {
  const { createCartId, cartState } = useContext(StateContext);
  const setCarts = cartState[1];
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean[]>([]);
  const settings = {
    ...sliderSettings(popularProducts?.length ?? 0),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  // const handleIncrease = async (product: any) => {
  //   try {
  //     setLoading(true);
  //     const cartRes = await addToCart({
  //       product_id: product.id,
  //       quantity: 1,
  //     });
  //     notifyMessage.SUCCESS(cartRes?.message ?? 'Quantity updated successfully');
  //     setLoading(false);
  //   } catch (error: any) {
  //     notifyMessage.ERROR(error?.response?.data?.message ?? 'Quantity cannot be updated');
  //     setLoading(false);
  //   }
  // };

  const loadCarts = async () => {
    try {
      setLoading(() => true);
      const cartRes = await getCarts();
      setCarts(() => cartRes?.data ?? []);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const handleCart = async (e: any, product_id: number, index: number) => {
    e.preventDefault();
    try {
      await createCartId();
      await handleAddToCart(product_id, index);
    } catch (error: any) {
      if (error?.response?.data?.message.includes('Invalid')) {
        await createCart();
        await handleAddToCart(product_id, index);
      } else {
        notifyMessage.ERROR(error?.response?.data?.message ?? 'Cannot be added to cart');
      }
    }
  };

  const handleAddToCart = async (product_id: number, index: number) => {
    setLoading(() => true);
    let tempBtnLoading = [...btnLoading];
    tempBtnLoading[index] = true;
    setBtnLoading(tempBtnLoading);
    const cartRes = await addToCart({
      product_id,
      quantity: 1,
    });
    await loadCarts();
    notifyMessage.SUCCESS(cartRes?.message ?? 'Successfully added to cart');
    tempBtnLoading = [...btnLoading];
    tempBtnLoading[index] = false;
    setBtnLoading(tempBtnLoading);
    setLoading(() => false);
  };

  return (
    <>
      {loading && <TransparentSpinner />}
      <div className='mt-2'>
        <div className='card border-0 bg-light-200 pt-0'>
          <div className='card-body'>
            <h5 className='text-secondary font-hahmlet-medium mb-3 '>
              {type === EProductSliderType.POPULAR && 'Popular'} {type === EProductSliderType.DISCOUNT && 'Discount'} {type === EProductSliderType.TOP && 'Top'} Products
            </h5>
            <Slider {...settings}>
              {popularProducts?.map((item: any, index: number) => (
                <div key={index} className='px-1'>
                  <div className='card border-0 shadow-sm rounded-3'>
                    <div className='card-body pt-0 pb-2 px-2'>
                      <small className='d-block fs-xs fw-bold text-ellipse py-2'>{item?.supplier_product?.title}</small>
                      <img
                        src={`${BASE_IMG_URL}/${item?.supplier_product.images[0].image}`}
                        alt=''
                        className='card-img-top'
                        style={{
                          height: '9rem',
                          borderRadius: '10px',
                          objectFit: 'contain',
                        }}
                      />
                      <div className='d-flex py-1 justify-content-between border-bottom border-1'>
                        {item?.supplier_product?.avarage_rating == null ? (
                          ''
                        ) : (
                          <>
                            <small>
                              <i className='fa-regular fa-heart'></i>
                            </small>
                            <small>
                              <i className='fa-solid fa-star text-warning'></i>
                              {item?.supplier_product?.avarage_rating}
                            </small>
                          </>
                        )}
                      </div>
                      <div className='d-flex flex-wrap justify-content-between align-items-center pt-2'>
                        <span className='fs-xs'>
                          <b>৳{item && Math.floor(item?.selling_price)}</b>
                        </span>
                        <button className='btn btn-primary pt-0 pb-1 rounded-pill'>
                          <span onClick={(e) => handleCart(e, item?.id, index)} className='fs-xs'>
                            Add to cart
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSlider;
