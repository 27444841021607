import { ISupplierProduct } from '../../../shared/interface/supplier.interface';

function DashboardTopProducts({ data }: { data: ISupplierProduct[] }) {
  return (
    <>
      <div className='card rounded-4 pt-4 px-3 pb-2 border-snow-white shadow-sm'>
        <div className='card-title'>
          <h5>Top Products</h5>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>#</th>
                  <th style={{ width: '40%' }}>Name</th>
                  <th style={{ width: '35%' }}>Stock</th>
                  <th style={{ width: '15%' }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((product: ISupplierProduct, index: number) => (
                  <tr key={product.slug}>
                    <td>{index + 1}</td>
                    <td>{product?.title}</td>
                    <td className='text-center'>
                      {product?.stock}
                      {/* <div style={{ height: '15px' }} className='progress' role='progressbar' aria-label={`example${index + 1}`} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}>
                        <div className='progress-bar' style={{ width: `${product?.stock}%`, height: '15px' }}>
                          {product?.stock}
                        </div>
                      </div> */}
                    </td>
                    <td>
                      <div className='value p-2 text-center rounded-3'>{product?.price}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardTopProducts;
