import {
  FETCH_DASHBOARD_CATEGORY_REQUEST,
  FETCH_DASHBOARD_CATEGORY_FAILURE,
  FETCH_DASHBOARD_CATEGORY_SUCCESS
} from "../../constants/homeCategory/category";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const homeCategoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_DASHBOARD_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DASHBOARD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_DASHBOARD_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default homeCategoryReducer;
