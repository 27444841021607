export const sliderSettings = (totalItems: number) => ({
  slidesToShow: Math.min(3, totalItems), // Adjust the value based on your requirements
  arrows: true,
  infinite: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: false,
  centerMode: true,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: Math.min(2, totalItems),
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: Math.min(3, totalItems),
      },
    },
    {
      breakpoint: 612,
      settings: {
        slidesToShow: Math.min(2, totalItems),
      },
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: Math.min(1, totalItems),
      },
    },
  ],
});
