import axios from 'axios';

import {
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_ONE_PRODUCT_REQUEST,
  FETCH_ONE_PRODUCT_FAILURE,
  FETCH_ONE_PRODUCT_SUCCCES,
} from '../../constants/customerProduct/product';
import { BASE_URL } from '../../constants/base.constant';

export const fetchProductRequest = () => {
  return {
    type: FETCH_PRODUCT_REQUEST,
  };
};

export const fetchProductSuccess = (data: any) => {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const fetchProductFailure = (error: any) => {
  return {
    type: FETCH_PRODUCT_FAILURE,
    payload: error,
  };
};
export const fetchOneProductFailure = (error: any) => {
  return {
    type: FETCH_ONE_PRODUCT_FAILURE,
    payload: error,
  };
};

export const fetchOneProductSuccess = (data: any) => {
  return {
    type: FETCH_ONE_PRODUCT_SUCCCES,
    payload: data,
  };
};

export const fetchOneProductRequest = () => {
  return {
    type: FETCH_ONE_PRODUCT_REQUEST,
  };
};

export const fetchProductData = (search = '', ordering = '', category = '', sub_category = '') => {
  return async (dispatch: any) => {
    if (category === null && !category) {
      category = '';
    }
    if (sub_category === null && !sub_category) {
      sub_category = '';
    }
    const token = localStorage.getItem('access_token');

    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

    dispatch(fetchProductRequest());
    try {
      const { data } = await axios.get(`${BASE_URL}/customers/products/?all&search=${search}&ordering=${ordering}&category=${category}&sub_category=${sub_category}`, config);
      dispatch(fetchProductSuccess(data?.data));
    } catch (error: any) {
      dispatch(fetchProductFailure(error.message));
    }
  };
};

export const fetchOneProductData = (id: any) => {
  return async (dispatch: any) => {
    try {
      const token = localStorage.getItem('access_token');

      dispatch(fetchOneProductRequest());

      const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};

      const { data } = await axios.get(`${BASE_URL}/customers/products/${id}/`, config);

      dispatch(fetchOneProductSuccess(data?.data));
    } catch (error: any) {
      dispatch(fetchOneProductFailure(error.message));
      console.error(error);
    }
  };
};
