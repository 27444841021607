import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { IGlobalStorage } from '../../shared/interface/reduxStore.interface';
import { BASE_IMG_URL } from '../../store/constants/base.constant';

function DashboardNavbar() {
  const { data } = useSelector((state: IGlobalStorage) => state.user);

  const [role, setRole] = useState<string | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  useEffect(() => {
    const role = localStorage.getItem('role');
    const phone = localStorage.getItem('phone');
    setRole(role);
    setPhone(phone);
  }, []);

  return (
    <>
      <div className='container-fluid'>
        <nav className='dashboard-navbar navbar navbar-expand-lg bg-light'>
          {/* <button className="navbar-toggler ms-auto mt-3 m-lg-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button> */}
          <div className='navbar-container' id='navbarNav'>
            <div className='navbar-nav align-items-center'>
              <Link to={'/dashboard'}>
                <a className='nav-link route-link w-lg-25 w-100 text-center text-lg-start' href='#'>
                  Dashboard
                </a>
              </Link>
              <div className='d-flex align-items-center w-lg-75 w-100 justify-content-sm-end justify-content-between'>
                {/* <div className='search-input w-xl-40 flex-grow-lg-0 flex-grow-1 my-3'>
                  <input type='text' className='form-control py-2  border-0 rounded-pill bg-light-2' placeholder='Search here...' />
                  <span>
                    <i className='fa-solid fa-magnifying-glass'></i>
                  </span>
                </div> */}
                {/* <div className='d-flex align-items-center w-xl-25 mx-xl-5 mx-2 my-3'>
                  <img src='/images/usa.png' width={24} alt='' />
                  <select className='form-select ms-2 border-0 d-none d-xl-inline-block'>
                    <option value='Eng(US)'>Eng(US)</option>
                    <option value='Ban(BN)'>Ban(BN)</option>
                    <option value='Frn(FR)'>Frn(FR)</option>
                  </select>
                </div> */}
                <div className='d-flex my-3 w-xl-35'>
                  {/* <Link to={'/'}>
                    <a className='nav-link'>
                      <span className='bg-warning-light rounded-3 shape-square px-2 position-relative'>
                        <i className='fa-regular fa-bell text-warning'></i>
                        <span className='position-absolute top-0 start-100 translate-middle bg-danger border border-2 border-white badge-bubble'></span>
                      </span>
                    </a>
                  </Link> */}
                  <Link to={'/profile'}>
                    <a className='nav-link py-0 px-3'>
                      <img className='dashboard-image rounded-3' src={data?.profile_image ? `${BASE_IMG_URL}/${data?.profile_image}` : '/images/user.jpg'} alt='' />
                    </a>
                  </Link>
                  <Link to={'/profile'}>
                    <a>
                      <div className='w-xl-100 d-none d-xl-inline-block'>
                        {phone && <p className='m-0 ps-0 pb-1 text-black fw-bold border-0'>{phone}</p>}
                        {/* <select className='form-select ps-0 pb-1 fw-bold border-0'>
                      <option value={phone ?? ''}>{phone && phone}</option>
                    </select> */}
                        <small className='text-black'>{role && role}</small>
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default DashboardNavbar;
