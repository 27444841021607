import { useEffect, useState } from 'react';

import DashboardAddProductComponent from './dashboard-product/AddProduct';
import DashboardAllProductsComponent from './dashboard-product/AllProduct';

import TransparentSpinner from '../spinner/TransparentSpinner';

import { getAllSupplierProducts } from '../../services/supplierService';
import { ISupplierProduct } from '../../shared/interface/product.interface';

function DashboardProducts() {
  const [loading, setLoading] = useState(false);
  const [isAddedProductFinished, setIsAddedProductFinished] = useState(false);
  const [productsData, setProductsData] = useState<ISupplierProduct[]>([]);

  const loadAllSupplierProduct = async () => {
    try {
      setLoading(true);
      const topProductsResponse = await getAllSupplierProducts();
      setProductsData(topProductsResponse?.data ?? []);
      setLoading(false);
      setIsAddedProductFinished(() => false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAllSupplierProduct();
  }, [isAddedProductFinished]);

  return (
    <>
      {loading && <TransparentSpinner />}
      <div>
        <div className='row pb-4'>
          <div className='col-12 col-lg-6 py-3'>
            <DashboardAllProductsComponent data={productsData} />
          </div>
          <div className='col-12 col-lg-6 py-3'>
            <DashboardAddProductComponent setIsAddedProductFinished={setIsAddedProductFinished} />
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardProducts;
