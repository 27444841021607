import axios from 'axios';

import { FETCH_SHOP_BY_PET_FAILURE, FETCH_SHOP_BY_PET_REQUEST, FETCH_SHOP_BY_PET_SUCCESS } from '../../constants/shopByPet/shopByPet';
import { BASE_URL } from '../../constants/base.constant';

export const fetchRequest = () => {
  return {
    type: FETCH_SHOP_BY_PET_REQUEST,
  };
};

export const fetchSuccess = (data: any) => {
  return {
    type: FETCH_SHOP_BY_PET_SUCCESS,
    payload: data,
  };
};

export const fetchFailure = (error: any) => {
  return {
    type: FETCH_SHOP_BY_PET_FAILURE,
    payload: error,
  };
};

export const fetchShopByPetData = () => {
  return async (dispatch: any) => {
    dispatch(fetchRequest());
    try {
      const { data } = await axios.get(`${BASE_URL}/customers/shop-by-pets`);
      dispatch(fetchSuccess(data.data));
    } catch (error: any) {
      dispatch(fetchFailure(error.message));
    }
  };
};
