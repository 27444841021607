import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import { IDoctorReview } from '../../shared/interface/doctorProfile.interface';

import { getAllDoctorReview } from '../../services/doctorService';

import TransparentSpinner from '../spinner/TransparentSpinner';

import StarRating from '../../utils/StarRating';

import { BASE_IMG_URL } from '../../store/constants/base.constant';

function DoctorReviewListComponent() {
  const [reviews, setReviews] = useState<IDoctorReview[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { doctorId } = useParams();

  const getAllReviews = async (doctorId: number) => {
    try {
      setLoading(true);
      const reviewRes = await getAllDoctorReview(doctorId);
      setReviews(reviewRes?.data ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (doctorId !== undefined) {
      getAllReviews(Number(doctorId));
    }
  }, [doctorId]);
  return (
    <>
      {loading && <TransparentSpinner />}
      <div>
        <p className='bg-primary py-5 text-white font-hahmlet-semibold fs-5 text-center mb-4'>Reviews</p>
        <div className='container'>
          {reviews?.length ? (
            reviews?.map((review: IDoctorReview, key: number) => {
              return (
                <div key={`doctorReview_${review.id}_${key}`} className='review-card bg-white rounded-4 mb-4 border'>
                  <div className='reviewer-info'>
                    <div className='reviewer-img'>
                      <img src={`${review?.customer?.profile_image ? BASE_IMG_URL + review?.customer?.profile_image : ''}`} alt='' />
                    </div>
                    <p className='mb-0 font-pop-medium fs-5'>{review?.customer?.name}</p>
                    <p>
                      <StarRating count={review?.rating ?? 0} />
                    </p>
                  </div>
                  <div className='px-3'>
                    <p className='text-gray fs-sm w-75 mt-5'>{review?.comment}</p>
                    <div className='d-flex justify-content-between pb-3'>
                      <p className='text-black fs-sm mb-0'>
                        Verified Review <i className='ms-2 fa-solid fa-circle-check fs-sm text-success'></i>
                      </p>
                      <p className='mb-0 text-secondary-tint-1 fs-md'>{dayjs(review?.created_at).format('MMMM D, YYYY')}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No reviews found!</p>
          )}
        </div>
      </div>
    </>
  );
}

export default DoctorReviewListComponent;
