import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ICartItem, ICartResponse } from '../shared/interface/cart.interface';

import TransparentSpinner from '../components/spinner/TransparentSpinner';
import CartCardComponent from '../components/cart/CartCard';

import { getCarts } from '../services/cartService';

import { StateContext } from '../App';

import ReactPixel from 'react-facebook-pixel';

function Cart() {
  const { cartState }: { cartState: [ICartResponse, React.Dispatch<React.SetStateAction<ICartResponse | null>>] } = useContext(StateContext);
  const [carts, setCarts] = cartState;

  const [loading, setLoading] = useState<boolean>(false);

  const loadCarts = async () => {
    try {
      setLoading(() => true);
      const cartRes = await getCarts();
      setCarts(() => cartRes?.data);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const handleCheckoutClick = () => {
    const contents = carts?.items?.map((item) => ({
      title: item.product.product_title,       // Product title
      price: item.total_price,       // Product price
      quantity: item.quantity, // Product quantity
      category: item.product.category
    }));

    ReactPixel.track('InitiateCheckout', {
      value: carts?.total_amount,      // The value of the checkout
      currency: 'BDT',   // The currency of the transaction
      contents: contents,
      content_type: 'product',
    });
  };

  useEffect(() => {
    loadCarts();
  }, []);
  return (
    <>
      {loading && <TransparentSpinner />}
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 py-3'>
            <div>
              <h4 className='text-secondary display-8 text-center'>Shopping Cart</h4>
              <div className='bg-light-200 rounded-3 px-4 py-3 my-3'>
                {carts?.items?.length ? (
                  <>
                    {carts?.items?.map((item: ICartItem) => (
                      <CartCardComponent cartItem={item} />
                    ))}
                    <div>
                      <div className='row'>
                        <div className='col-12 col-md-7 py-3'></div>
                        <div className='col-12 col-md-5 py-3'>
                          <div>
                            <div className='py-1 d-flex align-items-center justify-content-between'>
                              <p className='text-secondary'>Subtotal</p>
                              <p className='text-success fw-bold'>৳{carts?.subtotal}</p>
                            </div>
                            {carts?.coupon_discount > 0 && (
                              <div className='d-flex align-items-center justify-content-between'>
                                <p className='text-secondary'>Coupon Discount</p>
                                <p className='text-primary fw-bold'>-৳{carts?.coupon_discount}</p>
                              </div>
                            )}
                            {carts?.discount > 0 && (
                              <div className='d-flex align-items-center justify-content-between'>
                                <p className='text-secondary'>Discount</p>
                                <p className='text-primary fw-bold'>-৳{carts?.discount}</p>
                              </div>
                            )}
                            <div className='checkout-price-border-bottom'></div>
                            <div className='py-2 d-flex align-items-center justify-content-between'>
                              <p className='text-secondary'>Total Cost</p>
                              <p className='text-secondary fw-bold'>৳{carts && carts?.total_amount}</p>
                            </div>
                            <div className='text-end mt-4'>
                              <Link to={'/checkout'} onClick={handleCheckoutClick}>
                                <button className='rounded-3 btn btn-primary px-5 py-2'>Checkout</button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='py-5 text-center'>Cart is Empty</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
