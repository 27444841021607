import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { notifyMessage } from '../App';

import { IPackage } from '../shared/interface/appointment.interface';
import { postPackage } from '../services/appointmentService';

function PackageModal(props: any) {
  const [selectedPackage, setSelectedPackage] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(false);

  const handlePayNow = async () => {
    if (selectedPackage) {
      setLoading(() => true);
      const packageRes = await postPackage({ package_type: selectedPackage });
      setLoading(() => false);
      props?.setModalShow(false);
      window.open(packageRes.data.GatewayPageURL[1], '_blank');
    } else {
      notifyMessage.WARN('Please select packages');
    }
  };
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <div className='modal-header py-3 bg-primary border-0'>
        <h6 className='text-white mb-0 font-pop-semibold'>Choose a Package</h6>
        <button onClick={() => props?.setModalShow(false)} type='button' className='btn text-white py-0 px-1'>
          <i className='fa-solid fa-xmark'></i>
        </button>
      </div>
      <Modal.Body>
        <div className='py-4 px-4'>
          {props?.packages?.map((pkg: IPackage) => {
            return (
              <div className='form-check'>
                <input onChange={() => setSelectedPackage(() => pkg.id)} className='form-check-input' type='radio' name='option' id={`${pkg.name}_${pkg.id}_${pkg.price}`} />
                <label onClick={() => setSelectedPackage(() => pkg.id)} className='form-check-label ps-2' htmlFor={`${pkg.name}_${pkg.id}_${pkg.price}`}>
                  <h4 className='font-pop-semibold text-secondary'>৳ {pkg?.price}</h4>
                  <p>{pkg?.name ?? 'N/A'}</p>
                </label>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <div className='modal-footer border-0'>
        <button disabled={loading} onClick={() => handlePayNow()} type='button' className='btn btn-primary w-100 py-3 rounded-4'>
          {loading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Pay Now
        </button>
      </div>
    </Modal>
  );
}

export default PackageModal;
