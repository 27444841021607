import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { BASE_IMG_URL } from '../../store/constants/base.constant';

import { StateContext, notifyMessage } from '../../App';

import { ICartItem } from '../../shared/interface/cart.interface';

import { addToCart, getCarts, removeCartItems } from '../../services/cartService';

import TransparentSpinner from '../spinner/TransparentSpinner';

function CartCardComponent({ cartItem }: { cartItem: ICartItem }) {
  const { cartState } = useContext(StateContext);
  const [loading, setLoading] = useState<boolean>(false);
  const setCarts = cartState[1];

  const handleIncreaseDecrease = async (quantity: number) => {
    try {
      setLoading(true);
      const cartRes = await addToCart({
        product_id: cartItem.product.id,
        quantity,
      });
      notifyMessage.SUCCESS(cartRes?.message ?? 'Quantity updated successfully');
      setLoading(false);
      await loadCarts();
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Quantity cannot be updated');
      setLoading(false);
    }
  };

  const loadCarts = async () => {
    try {
      setLoading(() => true);
      const cartRes = await getCarts();
      setCarts(() => cartRes?.data);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const cartRes = await removeCartItems(cartItem.id);
      notifyMessage.SUCCESS(cartRes?.message ?? 'Cart item has been removed');
      setLoading(false);
      await loadCarts();
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Cart item cannot be removed');
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <TransparentSpinner />}
      <div className='px-2 bg-white my-2'>
        <div className='row'>
          <div className='col-12 col-md-3 py-2'>
            <img src={`${BASE_IMG_URL}/${cartItem?.product?.image}`} style={{ height: '96px', width: '96px' }} alt='cart-img.png' />
          </div>
          <div className='col-12 col-md-6 py-2'>
            <div>
              <p className=''>
                <Link className='display-9 fw-normal text-secondary' to={`/products/${cartItem?.product?.id}`}>
                  {cartItem?.product?.product_title}
                </Link>
              </p>
              <div className='d-flex align-items-center'>
                <div onClick={() => (cartItem?.quantity > 1 ? handleIncreaseDecrease(-1) : handleDelete())} className='mx-2 bg-white shadow-lg rounded-circle d-flex align-items-center cursor-pointer'>
                  <span>-</span>
                </div>
                <div className='mx-2'>{cartItem?.quantity}</div>
                <div onClick={() => handleIncreaseDecrease(1)} className='mx-2 bg-white shadow-lg rounded-circle d-flex align-items-center cursor-pointer'>
                  <span>+</span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-3 py-2'>
            <div className='row align-items-end justify-content-end h-100'>
              <div className='col-12'>
                <p className='text-secondary mb-3 text-end me-4'>
                  <span>৳</span>
                  <span>{cartItem?.total_price}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartCardComponent;
