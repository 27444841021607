import { EUserType } from '../shared/enum/user.enum';

interface SidebarData {
  id: number;
  display: string;
  icon: JSX.Element;
  path?: string;
  entry?: string[];
}

export const sidebarListData: SidebarData[] = [
  {
    id: 1,
    display: 'Dashboard',
    path: '/dashboard',
    icon: <i className='fa-solid fa-chart-pie'></i>,
    entry: ['SUPPLIER', EUserType.SUPPLIER],
  },
  {
    id: 2,
    display: 'Products',
    path: '/dashboard/products',
    icon: <i className='fa-solid fa-bag-shopping'></i>,
    entry: ['SUPPLIER', EUserType.SUPPLIER],
  },
  {
    id: 3,
    display: 'Orders',
    path: '/dashboard/orders',
    icon: <i className='fa-solid fa-cart-shopping'></i>,
    entry: ['SUPPLIER', EUserType.SUPPLIER],
  },
  {
    id: 4,
    display: 'Sales',
    path: '/dashboard/sales',
    icon: <i className='fa-solid fa-sack-dollar'></i>,
    entry: ['SUPPLIER', EUserType.SUPPLIER],
  },
  // {
  //   id: 5,
  //   display: "My pets",
  //   path: "/dashboard/my-pets",
  //   icon: <i className="fa-solid fa-sack-dollar"></i>,
  //   entry: ["CUSTOMER"],
  // },
  // {
  //   id: 6,
  //   display: "Order List",
  //   path: "/dashboard/order-lists",
  //   icon: <i className="fa-solid fa-basket-shopping"></i>,
  //   entry: ["CUSTOMER"],
  // },
  // {
  //   id: 7,
  //   display: "Vet service",
  //   path: "/dashboard/vet-services",
  //   icon:<i className="fas fa-user-md"></i>,
  //   entry: ["CUSTOMER"],
  // },
  // {
  //   id: 8,
  //   display: "Settings",
  //   path: "/dashboard/settings",
  //   icon: <i className="fa-solid fa-gear"></i>,
  //   entry: ["CUSTOMER"],
  // },
];
