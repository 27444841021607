import { ISupplierSale } from '../../../shared/interface/supplier.interface';

function DashboardSaleHistory({ data }: { data: ISupplierSale[] }) {
  const formatDate = (dateString: any) => {
    const options: any = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  return (
    <>
      <div className='card rounded-4 pt-4 px-3 pb-2 border-snow-white shadow-sm'>
        <div className='card-title'>
          <h5>Sales List</h5>
        </div>
        <div className='card-body'>
          {data?.length ? (
            <div className='table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th style={{ width: '10%' }}>#</th>
                    <th style={{ width: '40%' }}>Name</th>
                    <th style={{ width: '10%' }}>Quantity</th>
                    <th style={{ width: '20%' }}>Date</th>
                    <th style={{ width: '20%' }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((saleHistory: ISupplierSale, index: number) => (
                    <tr key={saleHistory.id}>
                      <td>{index + 1}</td>
                      <td>{saleHistory?.product_title ?? 'N/A'}</td>
                      <td>
                        <div className='value p-2 text-center rounded-3'>{saleHistory?.quantity ?? 'N/A'}</div>
                      </td>
                      <td>{saleHistory?.invoice_date && formatDate(saleHistory?.invoice_date)}</td>
                      <td>{`৳${saleHistory?.grand_total}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No sales history found</p>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardSaleHistory;
