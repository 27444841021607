import DashboardProductsComponent from "../components/supplier-dashboard/DashboardProducts";

function ProductDashboard() {
  return (
    <>
      <div className="overflow-hidden">
        <DashboardProductsComponent />
      </div>
    </>
  );
}

export default ProductDashboard;
