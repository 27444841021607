export enum EStorageType {
  VET247_VIDEO_CALL_TOKEN = 'vet247_token',
  CUSTOM_VIDEO_CALL_TOKEN = 'cus_token',
  DOCTOR_VIDEO_CALL_TOKEN = 'doc_token',
  IS_ALREADY_OPEN_MOBILE_APP_MODAL = 'is_already_open_mobile_app_modal',
}

export const setIsAlreadyOpenMobileAppModal = (is_already_open_mobile_app_modal: boolean): void => {
  localStorage.setItem(EStorageType.IS_ALREADY_OPEN_MOBILE_APP_MODAL, is_already_open_mobile_app_modal ? 'true' : 'false');
};

export const getIsAlreadyOpenMobileAppModal = (): boolean => {
  const isOpen = localStorage.getItem(EStorageType.IS_ALREADY_OPEN_MOBILE_APP_MODAL);
  return isOpen === 'true' ? true : false;
};

export const setVet247VideoCallToken = (token: string): void => {
  localStorage.setItem(EStorageType.VET247_VIDEO_CALL_TOKEN, token);
};

export const getVet247VideoCallToken = (): string | null => {
  const token = localStorage.getItem(EStorageType.VET247_VIDEO_CALL_TOKEN);
  return token ?? null;
};

export const removeVet247VideoCallToken = (): void => {
  localStorage.removeItem(EStorageType.VET247_VIDEO_CALL_TOKEN);
};

export const setCustomerVideoCallToken = (token: string): void => {
  localStorage.setItem(EStorageType.CUSTOM_VIDEO_CALL_TOKEN, token);
};

export const getCustomerVideoCallToken = (): string | null => {
  const token = localStorage.getItem(EStorageType.CUSTOM_VIDEO_CALL_TOKEN);
  return token ?? null;
};

export const removeCustomerVideoCallToken = (): void => {
  localStorage.removeItem(EStorageType.CUSTOM_VIDEO_CALL_TOKEN);
};

export const setDoctorVideoCallToken = (token: string): void => {
  localStorage.setItem(EStorageType.DOCTOR_VIDEO_CALL_TOKEN, token);
};

export const getDoctorVideoCallToken = (): string | null => {
  const token = localStorage.getItem(EStorageType.DOCTOR_VIDEO_CALL_TOKEN);
  return token ?? null;
};

export const removeDoctorVideoCallToken = (): void => {
  localStorage.removeItem(EStorageType.DOCTOR_VIDEO_CALL_TOKEN);
};

export const removeStorage = () => {
  localStorage.clear();
};
