import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import '../scss/PetLife.scss';

import FullScreenSpinner from '../components/spinner/FullScreenSpinner';

import { IGenericPageData } from '../shared/interface/app.interface';
import { EGenericPageApiKey, EGenericPageRouteType } from '../shared/enum/generic.enum';

import { getGenericData } from '../services/appService';

function GenericTemplateComponent() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [pageHeader, setPageHeader] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IGenericPageData | null>(null);

  const loadGenereicData = async () => {
    try {
      setLoading(true);
      const path = pathname.replace('/', '');
      setPageHeader(path.split('-').join(' '));
      if (path === EGenericPageRouteType.PET_LIFESTYLE) {
        const res = await getGenericData(EGenericPageApiKey.PET_LIFESTYLE);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.PET_ENTERTAINMENT) {
        const res = await getGenericData(EGenericPageApiKey.PET_ENTERTAINMENT);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.PET_GROOMING) {
        const res = await getGenericData(EGenericPageApiKey.PET_GROOMING);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.PET_GUARD) {
        const res = await getGenericData(EGenericPageApiKey.PET_GUARD);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.PET_CARE) {
        const res = await getGenericData(EGenericPageApiKey.PET_CARE);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.PET_FRIENDLY) {
        const res = await getGenericData(EGenericPageApiKey.PET_FRIENDLY);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.ABOUT_US) {
        const res = await getGenericData(EGenericPageApiKey.ABOUT_US);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.COMMUNITY) {
        const res = await getGenericData(EGenericPageApiKey.COMMUNITY);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.PRIVACY) {
        const res = await getGenericData(EGenericPageApiKey.PRIVACY);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.POLICY) {
        const res = await getGenericData(EGenericPageApiKey.POLICY);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.TERMS_OF_USE) {
        const res = await getGenericData(EGenericPageApiKey.TERMS_OF_USE);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.MEDICINE) {
        const res = await getGenericData(EGenericPageApiKey.MEDICINE);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.VACCINE) {
        const res = await getGenericData(EGenericPageApiKey.VACCINE);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.HEALTH_TIPS) {
        const res = await getGenericData(EGenericPageApiKey.HEALTH_TIPS);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.OTHER) {
        const res = await getGenericData(EGenericPageApiKey.OTHER);
        setData(res?.data?.[0] ?? null);
      } else if (path === EGenericPageRouteType.OUR_BUSINESS_INFORMATION) {
        setData({
          title: 'Our business information',
          sub_title: 'Get Connected',
          description: `
            Unlock the true potential of your business with Nexo Technologies’ IoT and Digital Services solutions. To learn more about our offerings or discuss your project requirements, please visit our website or contact our team.
            Empower your connectivity with Nexo Technologies - Your Partner in IoT & Digital Transformation.
          `,
          image: '/images/trade.jpg',
        });
      } else {
        navigate('/not-found');
      }
      setLoading(false);
    } catch (error) {
      navigate('/not-found');
      setLoading(false);
    }
  };

  useEffect(() => {
    loadGenereicData();
  }, [pathname]);
  return (
    <>
      {loading && <FullScreenSpinner />}
      <div>
        <p className='bg-primary py-5 text-white font-hahmlet-semibold fs-5 text-center mb-4'>{pageHeader}</p>
        <div className='container'>
          <div className='pet-detail-wrap'>
            <div className='w-lg-35 w-md-50 w-sm-50 w-100 pet-picture me-sm-5 mb-sm-2 bg-gray'>
              {data?.image && (
                <a href={data.image}>
                  <img className='w-100 h-100' src={data.image} alt='' />
                </a>
              )}
            </div>
            <div className='pet-detail'>
              <p className='font-hahmlet-semibold fs-5 text-secondary text-capitalize'>{data?.title ?? 'N/A'}</p>
              <div dangerouslySetInnerHTML={{ __html: data?.description ?? 'N/A' }} />
            </div>
          </div>
          {data?.key_notes?.length ? (
            <div className='mt-4'>
              <p className='fs-5 text-secondary font-hahmlet-semibold'>Notes</p>
              <ul className='ps-0 pb-3 pb-md-5'>
                {data?.key_notes?.map((keyNote: string, key: number) => {
                  return (
                    <li key={`${data.title}_${key}`} className='mb-3 text-gray d-flex'>
                      <i className='fa-solid fa-check text-primary me-3 mt-1'></i>
                      <span>{keyNote}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default GenericTemplateComponent;
