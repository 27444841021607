import { Link, useLocation } from 'react-router-dom';

function OrderCancel() {
  const location = useLocation();
  return (
    <div className='container pt-3'>
      <div className='bg-light-200 d-flex py-5 align-items-center justify-content-center rounded-3 confirmation-box-height'>
        <div className='w-30 text-center'>
          <img src='/images/order-failed.png' className='mx-auto w-50' alt='' />
          <h3 className='my-3 text-primary text-danger font-hahmlet-bold text-capitalize'>{location?.pathname?.split('/')?.[1]?.split('-')?.join(' ') ?? 'Order Cancelled'}</h3>
          <Link className='text-white display-9 ' to={'/products'}>
            <button className='btn btn-primary py-2 px-4 lh-2 rounded-3'>Continue Shopping</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OrderCancel;
