import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { addProductReview } from '../services/productService';

import { notifyMessage } from '../App';
import { addDoctorReview } from '../services/doctorService';

export enum EReviewModalType {
  CUSTOMER = 'CUSTOMER',
  DOCTOR = 'DOCTOR',
}
interface IProps {
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  productId?: number;
  orderId?: number;
  doctorId?: number;
  onHide: () => void;
  type: string;
}

function GiveReviewModal(props: IProps) {
  const { setModalShow, productId, orderId, doctorId, onHide, type } = props;
  const maxStars = 5;

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);
  const [star, setStar] = useState<number>(0);
  const [comment, setComment] = useState<string>('');

  const stars = Array.from({ length: maxStars }, (_, index) => (
    <i
      key={index}
      className={`fa-solid fa-star cursor-pointer ${index < 0 || (hoveredStar !== null && index <= hoveredStar) || (star !== 0 && index <= star) ? 'text-warning' : ''} me-1`}
      onClick={() => setStar(index)}
      onMouseEnter={() => setHoveredStar(index)}
      onMouseLeave={() => setHoveredStar(null)}></i>
  ));

  const onSubmitDoctorReview = async (doctorId: number) => {
    try {
      setIsSaving(true);
      const reviewRes = await addDoctorReview(doctorId, {
        doctor_id: doctorId,
        comment,
        rating: star,
      });
      notifyMessage.SUCCESS(reviewRes?.message ?? 'Review added successfully');
      setComment('');
      setStar(0);
      onHide();
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Review cannot be added');
    }
  };

  const onSubmitProductReview = async (productId: number, orderId: number) => {
    try {
      setIsSaving(true);
      const reviewRes = await addProductReview(productId, {
        orderitem_id: orderId,
        comment,
        rating: star,
      });
      notifyMessage.SUCCESS(reviewRes?.message ?? 'Review added successfully');
      setComment('');
      setStar(0);
      onHide();
      setIsSaving(false);
    } catch (error: any) {
      setIsSaving(false);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Review cannot be added');
    }
  };

  const onSubmitReview = async () => {
    if (type === EReviewModalType.CUSTOMER && productId && orderId) {
      await onSubmitProductReview(productId, orderId);
    }

    if (type === EReviewModalType.DOCTOR && doctorId) {
      await onSubmitDoctorReview(doctorId);
    }
  };

  useEffect(() => {
    return () => {
      setComment('');
      setStar(0);
    };
  }, []);

  return (
    <>
      <Modal {...props} size='sm' aria-labelledby='contained-modal-title-vcenter' centered>
        <form>
          <div className='modal-header py-3 bg-primary border-0'>
            <h6 className='text-white mb-0 font-pop-semibold'>Add Review</h6>
            <button onClick={() => setModalShow(false)} type='button' className='btn text-white py-0 px-1'>
              <i className='fa-solid fa-xmark'></i>
            </button>
          </div>
          <Modal.Body>
            <div className='p-1'>
              <div className='text-center'>{stars}</div>
              <Form.Control value={comment} onChange={(e) => setComment(e.target.value)} className='my-3' as='textarea' placeholder='Write something' rows={3} />
              <Button onClick={onSubmitReview} className='w-100' color='primary'>
                {isSaving && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Submit
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
}

export default GiveReviewModal;
