import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import dayjs from 'dayjs';

import { IReminder } from '../shared/interface/pet.interface';

import { FormatDate } from '../shared/constant/time.constant';
import { createReminder, getReminers } from '../services/petService';
import { notifyMessage } from '../App';

interface IProps {
  petId: any;
  setReminders: React.Dispatch<React.SetStateAction<IReminder[]>>;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  onHide: () => void;
}

function CreatePetReminderModal(props: IProps) {
  const { petId, setReminders, setModalShow } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit, reset } = useForm<IReminder>({
    defaultValues: {
      title: '',
      date: dayjs().format(FormatDate),
    },
  });

  const createPetReminder = async (payload: IReminder) => {
    try {
      setLoading(true);
      const createdRes = await createReminder(petId, payload);
      await loadReminers();
      reset();
      setModalShow(false);
      notifyMessage.SUCCESS(createdRes?.message ?? 'Reminder created successfully');
      setLoading(false);
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Reminder cannot be created');
      setLoading(false);
    }
  };

  const loadReminers = async () => {
    const reminers = await getReminers(petId);
    setReminders(reminers?.data ?? []);
  };

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <form onSubmit={handleSubmit(createPetReminder)}>
        <div className='modal-header py-3 bg-primary border-0'>
          <h6 className='text-white mb-0 font-pop-semibold'>Create Reminder</h6>
          <button onClick={() => props?.setModalShow(false)} type='button' className='btn text-white py-0 px-1'>
            <i className='fa-solid fa-xmark'></i>
          </button>
        </div>
        <Modal.Body>
          <div className='py-4 px-4'>
            <div className='mb-3'>
              <label htmlFor='reminerTitleForm' className='form-label'>
                Title
              </label>
              <input {...register('title')} type='text' className='form-control' id='reminerTitleForm' />
            </div>
            <div className='mb-3'>
              <label htmlFor='reminerDateForm' className='form-label'>
                Date
              </label>
              <input {...register('date')} type='date' className='form-control' id='reminerDateForm' />
            </div>
          </div>
        </Modal.Body>
        <div className='modal-footer border-0'>
          <button disabled={loading} type='submit' className='btn btn-primary w-100 py-3 rounded-4'>
            {loading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Create
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default CreatePetReminderModal;
