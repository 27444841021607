import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Router from '../../routes/route';
import FooterComponent from '../footer/Footer';
import NavbarComponent from '../navbar/Navbar';

import { fetchUserData } from '../../store/actions/user/fetchAction';

import DashboardSidebarComponent from '../../components/supplier-dashboard/Sidebar';

import DashboardMain from '../supplier-dashboard/Dashboard';

import '../../scss/supplier-dashboard/Dashboard-body.scss';

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(fetchUserData(navigate));
    }
  }, [dispatch, navigate]);

  const isHideNavbarAndFooter = ['/login', '/login/otp'].includes(location.pathname);
  const isSupplierDashboardPage = location.pathname.startsWith('/dashboard');

  return (
    <div>
      {!isHideNavbarAndFooter && !isSupplierDashboardPage && <NavbarComponent />}
      {!isSupplierDashboardPage ? (
        <Router />
      ) : (
        <>
          <DashboardSidebarComponent />
          <div>
            <DashboardMain>
              <Router />
            </DashboardMain>
          </div>
        </>
      )}

      {!isHideNavbarAndFooter && !isSupplierDashboardPage && <FooterComponent />}
    </div>
  );
};

export default Layout;
