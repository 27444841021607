import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../components/product/Card';
import DetailImageComponent from '../components/productDetail/DetailImage';
import DetailDescriptionComponent from '../components/productDetail/DetailDescription';
import ReviewComponent from '../components/productDetail/Review';
import TransparentSpinner from '../components/spinner/TransparentSpinner';
import { fetchOneProductData, fetchProductData } from '../store/actions/customerProduct/fetchAction';
import { BASE_URL } from '../store/constants/base.constant';
import ReactPixel from 'react-facebook-pixel';

function ProductDetail() {
  const { id } = useParams();
  const dispatch = useDispatch<any>();
  const [productReview, setProductReview] = useState<any>(null);
  const { data: allData, loading: allDataLoading } = useSelector((state: any) => state.products);
  const { data: detailProduct, loading, error } = useSelector((state: any) => state.productDetail);


  useEffect(() => {
    if (detailProduct != undefined){
      // Custom event for tracking a product view
      ReactPixel.track('ViewContent', {
        product_name: detailProduct?.supplier_product?.title,
        brand: detailProduct?.supplier_product?.product_type?.name,
        category:detailProduct?.supplier_product?.category,
        price: detailProduct?.selling_price
      });
    }
    
    // ReactPixel.track('ViewContent', {
    //   content_name: detailProduct?.supplier_product?.title,
    //   content_category: product.category,
    //   content_ids: [product.id],
    //   content_type: 'product',
    //   value: product.price,
    //   currency: 'USD'
    // });
  }, [detailProduct]);


  useEffect(() => {
    if (id) {
      dispatch(fetchOneProductData(id));
    }
    if (detailProduct?.supplier_product?.category) {
      const category = detailProduct.supplier_product.category;
      dispatch(fetchProductData('', '', category, ''));
    }
  }, [id, detailProduct?.supplier_product?.category, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!detailProduct) {
          return;
        }
        const response = await axios.get(`${BASE_URL}/customers/products/${detailProduct.id}/reviews/`);
        setProductReview(response.data?.data[0]);
      } catch (error) {
        console.error('Error fetching product reviews:', error);
      }
    };

    fetchData();
  }, [detailProduct, setProductReview]);
  return (
    <>
      <div className='bg-white container-fluid'>
        {(loading || allDataLoading) && <TransparentSpinner />}
        {error && <div>Error loading product details.</div>}
        <div className='row mt-4 px-3'>
          <div className='col-lg-5 gap-2'>
            <div className='row'>
              <DetailImageComponent images={detailProduct?.supplier_product?.images} />
            </div>
          </div>
          <div className='col-lg-4 mt-4 mt-lg-0' style={{ height: '30rem' }}>
            <DetailDescriptionComponent product={detailProduct} />
          </div>
          {productReview && <div className='col-lg-3'>{<ReviewComponent product={productReview} />}</div>}
        </div>
        <div className='container-fluid'>
          <h4 className='p-2 text-secondary font-hahmlet-bold'>You Might Also Need</h4>
          <div className='row justify-content-center'>
            {allData?.slice(0, 4).map((product: any, index: number) => (
              <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 my-1' key={index}>
                <Card product={product} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
