import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IHttpResponse } from '../shared/interface/http.interface';
import { ICODResponse, IOPResponse, IOrderList, IPlaceOrderPayload } from '../shared/interface/order.interface';
import { axiosConfig } from '../shared/constant/axiosConfig';
import { EOrderFilterType } from '../shared/enum/filter.enum';

export const getAllOrder = async (status?: string): Promise<IHttpResponse<IOrderList[]>> => {
  const res = await axios.get<IHttpResponse<IOrderList[]>>(`${BASE_URL}/customers/orders/${status && '?status=' + status}`, axiosConfig());
  return res.data;
};

export const getOneOrder = async (orderId: number): Promise<IHttpResponse<IOrderList>> => {
  const res = await axios.get<IHttpResponse<IOrderList>>(`${BASE_URL}/customers/orders/${orderId}/`, axiosConfig());
  return res.data;
};

export const getUpcomingOrder = async (): Promise<IHttpResponse<IOrderList[]>> => {
  const res = await axios.get<IHttpResponse<IOrderList[]>>(`${BASE_URL}/customers/orders/?status=${EOrderFilterType.UPCOMING}`, axiosConfig());
  return res.data;
};

export const getPastOrder = async (): Promise<IHttpResponse<IOrderList[]>> => {
  const res = await axios.get<IHttpResponse<IOrderList[]>>(`${BASE_URL}/customers/orders/?status=${EOrderFilterType.PAST}`, axiosConfig());
  return res.data;
};

export const placeOrder = async (payload: IPlaceOrderPayload) => {
  const res = await axios.post<IHttpResponse<any | ICODResponse | IOPResponse>>(`${BASE_URL}/customers/orders/`, payload, axiosConfig());
  return res.data;
};
