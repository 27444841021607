import { Link } from 'react-router-dom';

import '../../scss/PetBlog.scss';

import { IBlog } from '../../shared/interface/home.interface';

import { BASE_IMG_URL } from '../../store/constants/base.constant';
import { EGenericPageRouteType } from '../../shared/enum/generic.enum';

function PetBlog({ blogs }: { blogs: IBlog[] }) {
  return (
    <div className='mt-md-2 my-4 mb-md-0 h-100'>
      <div>
        <div className='row m-0 p-0 w-100'>
          <div className='col-3 px-0'>
            <div style={{ height: '120px' }}>
              <Link to={`/${EGenericPageRouteType.MEDICINE}`}>
                <img className='w-100 h-100' src='images/medicine.svg' alt='images/medicine.svg' />
              </Link>
            </div>
          </div>
          <div className='col-3 px-0'>
            <div style={{ height: '120px' }}>
              <Link to={`/${EGenericPageRouteType.VACCINE}`}>
                <img className='w-100 h-100' src='images/vaccine.svg' alt='images/vaccine.svg' />
              </Link>
            </div>
          </div>
          <div className='col-3 px-0'>
            <div style={{ height: '120px' }}>
              <Link to={`/${EGenericPageRouteType.HEALTH_TIPS}`}>
                <img className='w-100 h-100' src='images/health-tips.svg' alt='images/health-tips.svg' />
              </Link>
            </div>
          </div>
          <div className='col-3 px-0'>
            <div style={{ height: '120px' }}>
              <Link to={`/${EGenericPageRouteType.OTHER}`}>
                <img className='w-100 h-100' src='images/other.svg' alt='images/other.svg' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <h5 className='text-secondary font-hahmlet-medium'>Pet Blog</h5>
      <div style={{ height: '150px' }} className='row overflow-y-auto'>
        {blogs?.map((blog: IBlog, index: number) => (
          <div key={`blogs_${blog.id}_${index}`} className='col-6 overflow-y-auto'>
            <div className='card border-0 h-80'>
              <div className='card-body p-0 h-80'>
                <img className='w-100 rounded-3 h-100' src={`${BASE_IMG_URL}/${blog?.image}`} alt='' />
              </div>
              <div className='card-footer bg-transparent border-0 pt-0 pb-1 ps-0'>
                <Link to={`/blogs/${blog.id}`}>
                  <a>
                    <small className='fw-bold text-nowrap'>
                      <span className='text-secondary fw-bold me-2'>{blog?.sub_title ?? '-'}</span>
                      <i className='fa-solid fa-arrow-right'></i>
                    </small>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        ))}
        {/* {blogs.length && (
          <div style={{ height: '250px' }} className='col-md-8 col-lg-12 col-xl-8'>
            <div className='position-relative'>
              <img className='w-100 h-100' src={`${BASE_IMG_URL}/${blogs?.[0]?.image}`} alt='' />
              <div className='shadow-sm p-3 rounded-3 bg-white position-absolute bottom-0 w-100'>
                <h6>{blogs?.[0]?.sub_title}</h6>
                <p className=' fs-xs'>{blogs?.[0]?.description}</p>
              </div>
            </div>
          </div>
        )} */}

        {/* <div style={{ height: '250px' }} className='col-md-4 col-lg-12 col-xl-4 mt-3 mt-md-0 mt-lg-3 mt-xl-0 pet-blog-end overflow-y-auto'>
          <div className='row flex-row flex-md-column flex-lg-row flex-xl-column'>
            {blogs?.map((blog: IBlog, index: number) => (
              <div key={`blogs_${blog.id}_${index}`} className='col-4 col-md-12 col-lg-4 col-xl-12'>
                <div className='card border-0'>
                  <div className='card-body p-0'>
                    <img className='w-100' src={`${BASE_IMG_URL}/${blog?.image}`} alt='' />
                  </div>
                  <div className='card-footer bg-transparent border-0 pt-0 pb-1 ps-0'>
                    <Link to={`/blogs/${blog.id}`}>
                      <a>
                        <small className='fw-bold text-nowrap'>
                          <span className='text-secondary fw-bold me-2'>{blog?.sub_title ?? '-'}</span>
                          <i className='fa-solid fa-arrow-right'></i>
                        </small>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PetBlog;
