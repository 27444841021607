import axios from 'axios';

import { axiosConfig } from '../shared/constant/axiosConfig';
import { BASE_URL } from '../store/constants/base.constant';

import { IHttpResponse } from '../shared/interface/http.interface';
import { ISupplierProduct } from '../shared/interface/product.interface';
import { ISupplierOrder, ISupplierProductCreatedResponse, ISupplierSale, ISupplierTopProductResponse, ISupplierTopSaleResponse } from '../shared/interface/supplier.interface';

export const getAllSupplierProducts = async (): Promise<IHttpResponse<ISupplierProduct[]>> => {
  const productsResponse = await axios.get<IHttpResponse<ISupplierProduct[]>>(`${BASE_URL}/suppliers/products`, axiosConfig());
  return productsResponse.data;
};

export const getAllTopSupplierProducts = async (): Promise<IHttpResponse<ISupplierTopProductResponse>> => {
  const topProductsResponse = await axios.get<IHttpResponse<ISupplierTopProductResponse>>(`${BASE_URL}/suppliers/top-products`, axiosConfig());
  return topProductsResponse.data;
};

export const getAllSupplierOrders = async (): Promise<IHttpResponse<ISupplierOrder[]>> => {
  const orderListsResponse = await axios.get<IHttpResponse<ISupplierOrder[]>>(`${BASE_URL}/suppliers/orders`, axiosConfig());
  return orderListsResponse.data;
};

export const getAllTopSales = async (): Promise<IHttpResponse<ISupplierTopSaleResponse>> => {
  const orderListsResponse = await axios.get<IHttpResponse<ISupplierTopSaleResponse>>(`${BASE_URL}/suppliers/top-sales`, axiosConfig());
  return orderListsResponse.data;
};

export const getAllSaleHistory = async (): Promise<IHttpResponse<ISupplierSale[]>> => {
  const orderListsResponse = await axios.get<IHttpResponse<ISupplierSale[]>>(`${BASE_URL}/suppliers/sale-histories`, axiosConfig());
  return orderListsResponse.data;
};

export const addSupplierProduct = async (payload: FormData): Promise<IHttpResponse<ISupplierProductCreatedResponse>> => {
  const res = await axios.post<IHttpResponse<ISupplierProductCreatedResponse>>(`${BASE_URL}/suppliers/products`, payload, axiosConfig());
  return res.data;
};
