import { Link } from 'react-router-dom';
import { BASE_IMG_URL } from '../../store/constants/base.constant';

function ShopByPet({ pets }: any) {
  return (
    <div className='mt-2'>
      <div className='card border-0 bg-light-200 pt-0'>
        <div className='card-body'>
          <div className='d-flex justify-content-between'>
            <h5 className='text-secondary font-hahmlet-medium'>Shop By Pets</h5>
            <p>
              <Link to={`/shop/pet`}>
                <a>See More</a>
              </Link>
            </p>
          </div>
          <div className='row'>
            {pets?.map((item: any, index: number) => (
              <div key={`${item?.name}_${index}`} className='col-2 my-2'>
                <Link to={`/products/?sub_category=${item?.name}`}>
                  <div className='text-center'>
                    <img src={`${BASE_IMG_URL}${item.image}`} className='shadow-sm w-100 rounded-3' alt='' />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopByPet;
