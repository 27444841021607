import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { notifyMessage } from '../App';

import { getAllVet247Packages, postVet247Package } from '../services/vet247Service';

import { IVet247Package } from '../shared/interface/vet247.interface';
import TransparentSpinner from './spinner/TransparentSpinner';
import { BASE_IMG_URL } from '../store/constants/base.constant';
import ReactPixel from 'react-facebook-pixel';

export interface IVet247PackageModalProps {
  show: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  onHide: () => void;
}

function Vet247PackageModal(props: IVet247PackageModalProps) {
  const { setModalShow } = props;
  const [packages, setPackages] = useState<IVet247Package[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<number | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const handlePayNow = async () => {
    if (selectedPackage) {
      setBtnLoading(() => true);

      const contents = packages?.filter(item => item.id === selectedPackage)
      .map((item) => ({
        plan: item.name,       
        auto_renewal_status: item.name.includes("Without Auto Renewal") ? "Inactive" : "Activated", 
        price: item.price,
        // payment_method: item.subscription_keyword
      }));

      // Track the subscribe event
      ReactPixel.track('Subscribe', {
        content_name: 'Vet24/7 Subscription',
        content_category: 'Subscription',
        content: contents,
        currency: 'BDT'
      });
      
      // var selectedPkg;
      // packages.array.forEach(element => {
      //   if (element.id ==  selectedPackage){
      //     selectedPkg = element;
      //   }
      // });
      // // Track the subscribe event
      // ReactPixel.track('Subscribe', {
      //   content_name: selectedPkg.name,
      //   content_category: 'Subscription',
      //   value: 10,
      //   currency: 'USD',
      //   status: 'subscribed'
      // });

      const packageRes = await postVet247Package({ subscription_type: selectedPackage });
      setBtnLoading(() => false);
      setModalShow(false);
      window.open(packageRes.data.GatewayPageURL[1], '_blank');
    } else {
      notifyMessage.WARN('Please select packages');
    }
  };

  const getAllPackages = async () => {
    try {
      setBtnLoading(() => true);
      const pkgRes = await getAllVet247Packages();
      setPackages(pkgRes?.data ?? []);
      setBtnLoading(() => false);
    } catch (error) {
      setBtnLoading(() => false);
    }
  };

  useEffect(() => {
    getAllPackages();
  }, []);
  return (
    <>
      {loading && <TransparentSpinner />}
      <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <div className='modal-header py-3 bg-primary border-0'>
          <h6 className='text-white mb-0 font-pop-semibold'>Choose a Package</h6>
          <button onClick={() => setModalShow(false)} type='button' className='btn text-white py-0 px-1'>
            <i className='fa-solid fa-xmark'></i>
          </button>
        </div>
        <Modal.Body>
          <div className='py-4 px-4'>
            {packages?.map((pkg: IVet247Package, key: number) => {
              return (
                <div key={pkg.id + '_' + pkg.name + '_' + key} className='form-check mb-4'>
                  <input onChange={() => setSelectedPackage(() => pkg.id)} className='form-check-input' type='radio' name='option' id={`${pkg.name}_${pkg.id}_${pkg.price}`} />
                  <label onClick={() => setSelectedPackage(() => pkg.id)} className='form-check-label ps-2' htmlFor={`${pkg.name}_${pkg.id}_${pkg.price}`}>
                    <h4 className='font-pop-semibold text-secondary'>৳ {pkg?.price}</h4>
                    <p className='text-gray mb-0'>
                      {pkg?.name ?? 'N/A'} ({pkg?.duration_days + ' days'})
                    </p>
                    {pkg?.description && <p className='text-xs mt-1'>{pkg.description}</p>}
                  </label>
                </div>
              );
            })}
            <div className='mt-3'>
              <div className='d-flex align-items-center justify-content-center'>
                {packages?.[0]?.images?.length ? (
                  packages?.[0]?.images?.map((img) => (
                    <div className='mx-3'>
                      <img style={{ width: '100px' }} src={`${BASE_IMG_URL}${img?.image}`} alt={`${img?.image}`} />
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className='modal-footer border-0'>
          <button disabled={btnLoading} onClick={() => handlePayNow()} type='button' className='btn btn-primary w-100 py-3 rounded-4'>
            {btnLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Pay Now
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Vet247PackageModal;
