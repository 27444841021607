import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import '../scss/PetProfile.scss';

import { StateContext, notifyMessage } from '../App';

import { createPet, getAllPets, getPetDetails, updatePet } from '../services/petService';

import { ICreatePetPayload, IPetForm, IReminder } from '../shared/interface/pet.interface';
import { IGlobalStorage } from '../shared/interface/reduxStore.interface';

import { EPetGenderType } from '../shared/enum/pet.enum';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { fileValidation } from '../services/fileValidationService';
import CreatePetReminderModal from '../components/CreatePetReminderModal';

function PetProfileComponent() {
  const { data } = useSelector((state: IGlobalStorage) => state.user);
  const { register, handleSubmit, watch, setValue, reset } = useForm<IPetForm>({
    defaultValues: {
      id: null,
      customer: null,
      name: null,
      species: null,
      breed: null,
      size: null,
      gender: EPetGenderType.MALE.toString(),
      date_of_birth: dayjs().format('DD/MM/YYYY'),
      is_vaccinated: false,
      is_friendly_with_dog: false,
      is_friendly_with_cat: false,
      is_friendly_with_kids_below_10: false,
      is_friendly_with_kids_above_10: false,
      microchipped: false,
      is_neutered: false,
      pet_image: null,
    },
  });
  const { petId } = useParams();
  const { petState } = useContext(StateContext);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [reminders, setReminders] = useState<IReminder[]>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const setPets = petState[1];

  const selectedGender = watch('gender');
  const petImage = watch('pet_image');
  const id = watch('id');

  const handleGenderChange = (gender: string) => {
    setValue('gender', gender);
  };

  const handleFileUplaod = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const fileObj: File = e.target.files[0];
      const isValidate = fileValidation(fileObj);
      if (isValidate) {
        setFile(fileObj);
        setValue('pet_image', URL.createObjectURL(fileObj));
      }
    }
  };

  const onSubmit = async (formData: any) => {
    if (id) {
      await updatePetProfile(id, formData);
    } else {
      await createPetProfile({ ...formData, customer: data?.id });
    }
  };

  const updatePetProfile = async (id: number, data: ICreatePetPayload) => {
    try {
      setBtnLoading(() => true);
      const formData = setupFormData(data);
      const petRes = await updatePet(id, formData);
      await initiate();
      await loadAllPets();
      notifyMessage.SUCCESS(petRes?.message ?? 'Pet successfully updated!');
      setBtnLoading(() => false);
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Pet cannot be updated!');
      setBtnLoading(() => false);
    }
  };

  const createPetProfile = async (payload: ICreatePetPayload) => {
    try {
      setLoading(() => true);
      const formData = setupFormData(payload);
      const petRes = await createPet(formData);
      notifyMessage.SUCCESS(petRes?.message ?? 'Pet successfully created!');
      reset()
      await loadAllPets();
      setLoading(() => false);
      return petRes.data;
    } catch (error: any) {
      setLoading(() => false);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Pet cannot be created!');
    }
  };

  const loadAllPets = async () => {
    try {
      setLoading(() => true);
      const petRes = await getAllPets();
      setPets(() => petRes?.data ?? []);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const loadPetDetails = async () => {
    const petRes = await getPetDetails(Number(petId));
    if (petRes?.data) {
      updateFormData(petRes.data);
      setReminders(() => petRes.data?.reminders ?? []);
    }
    setLoading(() => false);
  };

  const updateFormData = (data: IPetForm) => {
    const {
      id,
      breed,
      customer,
      date_of_birth,
      gender,
      name,
      pet_image,
      size,
      species,
      is_vaccinated,
      is_friendly_with_dog,
      is_friendly_with_cat,
      is_friendly_with_kids_below_10,
      is_friendly_with_kids_above_10,
      microchipped,
      is_neutered,
    } = data;

    setValue('id', id);
    setValue('breed', breed);
    setValue('customer', customer);
    setValue('date_of_birth', date_of_birth);
    setValue('gender', gender);
    setValue('name', name);
    setValue('pet_image', pet_image);
    setValue('size', size);
    setValue('species', species);
    setValue('is_vaccinated', is_vaccinated);
    setValue('is_friendly_with_dog', is_friendly_with_dog);
    setValue('is_friendly_with_dog', is_friendly_with_dog);
    setValue('is_friendly_with_cat', is_friendly_with_cat);
    setValue('is_friendly_with_kids_below_10', is_friendly_with_kids_below_10);
    setValue('is_friendly_with_kids_above_10', is_friendly_with_kids_above_10);
    setValue('microchipped', microchipped);
    setValue('is_neutered', is_neutered);
  };

  const setupFormData = (payload: ICreatePetPayload): FormData => {
    const {
      customer,
      name,
      species,
      breed,
      size,
      gender,
      date_of_birth,
      is_vaccinated,
      is_friendly_with_dog,
      is_friendly_with_cat,
      is_friendly_with_kids_below_10,
      is_friendly_with_kids_above_10,
      microchipped,
      is_neutered,
    } = payload;
    const formData = new FormData();
    formData.append('customer', customer.toString());
    formData.append('name', name);
    formData.append('species', species);
    formData.append('breed', breed);
    formData.append('size', size);
    formData.append('gender', gender);
    formData.append('size', size);
    formData.append('date_of_birth', date_of_birth);
    formData.append('is_vaccinated', is_vaccinated.toString());
    formData.append('is_friendly_with_dog', is_friendly_with_dog.toString());
    formData.append('is_friendly_with_cat', is_friendly_with_cat.toString());
    formData.append('is_friendly_with_kids_below_10', is_friendly_with_kids_below_10.toString());
    formData.append('is_friendly_with_kids_above_10', is_friendly_with_kids_above_10.toString());
    formData.append('microchipped', microchipped.toString());
    formData.append('is_neutered', is_neutered.toString());
    file && formData.append('pet_image', file);
    return formData;
  };

  const initiate = async () => {
    setLoading(() => true);
    await loadPetDetails();
    setLoading(() => false);
  };

  useEffect(() => {
    if (petId) {
      initiate();
    } else {
      reset();
    }
  }, [petId]);
  return (
    <>
      {loading && <TransparentSpinner />}
      <CreatePetReminderModal petId={petId} setReminders={setReminders} setModalShow={setModalShow} show={modalShow} onHide={() => setModalShow(false)} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='container-fluid pet-profile'>
          <div className='row mt-2'>
            <div className='col-xl-4 pb-4 pb-xl-0'>
              <div className='w-lg-25 w-md-35 w-sm-75 w-xl-100'>
                <p className='fs-4 font-hahmlet-semibold text-secondary'>Add Pet Details</p>
                <div className='pet-img w-98'>
                  <img className='pet-img-tag rounded-2 w-100 h-100' src={petImage ? petImage : '/images/upload-image-icon.jpg'} alt='' />
                  <button type='button' className='btn btn-rounded btn-primary fs-5'>
                    <input onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileUplaod(e)} id='petProfileUploaderFile' type='file' hidden />
                    <label className='cursor-pointer' htmlFor='petProfileUploaderFile'>
                      +
                    </label>
                  </button>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6'>
              <div className=''>
                <p className='fs-4 font-hahmlet-semibold text-secondary'>General Information</p>
              </div>
              <div className='mt-3'>
                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Pet's Name</label>
                  <input {...register('name')} type='text' placeholder='Your Beautiful Pet Name' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                </div>
                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Species of your pet </label>
                  <input {...register('species')} type='text' placeholder='Your Pet Species' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                  {/* <select {...register('basic.species')} className='form-select border-bottom rounded-0 ps-0 py-1 shadow-none'>
                    <option>Dog</option>
                    <option>Cat</option>
                    <option>Bird</option>
                  </select> */}
                </div>
                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Pet's Breed</label>
                  <input {...register('breed')} type='text' placeholder='Your Pet Breed' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                  {/* <select {...register('basic.breed')} className='form-select py-1 border-bottom rounded-0 ps-0 shadow-none'>
                    <option>Toy terrier</option>
                    <option>Toy terrier</option>
                    <option>Toy terrier</option>
                  </select> */}
                </div>
                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Size (Optional)</label>
                  <input {...register('size')} type='text' placeholder='Your Pet Size' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                  {/* <select {...register('basic.size')} className='form-select py-1 border-bottom rounded-0 ps-0 shadow-none'>
                    <option>Select</option>
                    <option>1 ft</option>
                    <option>2 ft</option>
                  </select> */}
                </div>
                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Gender</label>
                  <div className='d-flex'>
                    <label
                      onClick={() => handleGenderChange(EPetGenderType.MALE)}
                      htmlFor='petGenderMale'
                      className={`btn ${selectedGender === EPetGenderType.MALE ? 'btn-primary' : 'btn-outline-secondary-tint-2 text-secondary'} w-50 mx-1 rounded-pill shadow`}>
                      <i className={`fa-solid fa-mars me-2 ${selectedGender !== EPetGenderType.MALE && 'text-primary'}`}></i>Male
                    </label>
                    <label
                      onClick={() => handleGenderChange(EPetGenderType.FEMALE)}
                      htmlFor='petGenderFemale'
                      className={`btn ${selectedGender === EPetGenderType.FEMALE ? 'btn-primary' : 'btn-outline-secondary-tint-2 text-secondary'} w-50 mx-1 rounded-pill`}>
                      <span>
                        <i className={`fa-solid fa-venus me-2 ${selectedGender !== EPetGenderType.FEMALE && 'text-primary'}`}></i>Female
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Date of birth</label>
                  <input {...register('date_of_birth')} type='date' className='form-control border-bottom rounded-0 ms-0 py-1' />
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6'>
              <div className='bg-light-200 px-3 pt-1 pb-2 rounded-1'>
                <p className='fs-4 font-hahmlet-semibold text-secondary'>Additional Informations</p>
                <div className='d-flex justify-content-between align-items-center checkbox-container'>
                  <span className='text-gray'>Neutered</span>
                  <label className='switch'>
                    <input {...register('is_neutered')} type='checkbox' />
                    <span className='slider round'></span>
                  </label>
                </div>
                <div className='d-flex justify-content-between align-items-center checkbox-container'>
                  <span className='text-gray'>Vaccinated</span>
                  <label className='switch'>
                    <input {...register('is_vaccinated')} type='checkbox' />
                    <span className='slider round'></span>
                  </label>
                </div>
                <div className='d-flex justify-content-between align-items-center checkbox-container'>
                  <span className='text-gray'>Friendly with dogs</span>
                  <label className='switch'>
                    <input {...register('is_friendly_with_dog')} type='checkbox' />
                    <span className='slider round'></span>
                  </label>
                </div>
                <div className='d-flex justify-content-between align-items-center checkbox-container'>
                  <span className='text-gray'>Friendly with cats</span>
                  <label className='switch'>
                    <input {...register('is_friendly_with_cat')} type='checkbox' />
                    <span className='slider round'></span>
                  </label>
                </div>
                <div className='d-flex justify-content-between align-items-center checkbox-container'>
                  <span className='text-gray'>Friendly with kids &lt;10 year</span>
                  <label className='switch'>
                    <input {...register('is_friendly_with_kids_below_10')} type='checkbox' />
                    <span className='slider round'></span>
                  </label>
                </div>
                <div className='d-flex justify-content-between align-items-center checkbox-container'>
                  <span className='text-gray'>Friendly with kids &gt;10 year</span>
                  <label className='switch'>
                    <input {...register('is_friendly_with_kids_above_10')} type='checkbox' />
                    <span className='slider round'></span>
                  </label>
                </div>
                <div className='d-flex justify-content-between align-items-center checkbox-container'>
                  <span className='text-gray'>Microchipped</span>
                  <label className='switch'>
                    <input {...register('microchipped')} type='checkbox' />
                    <span className='slider round'></span>
                  </label>
                </div>
                {/* <div className='mb-3'>
                  <label className='text-gray d-block'>Pet’s nursery name (optional)</label>
                  <input type='text' className='bg-transparent form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                </div> */}
              </div>
              <button disabled={btnLoading} type='submit' className='btn btn-primary w-100 mt-2'>
                {btnLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Save
              </button>
            </div>
          </div>
          {petId && (
            <div className='mt-3'>
              <p className='fs-4 font-hahmlet-semibold text-secondary mb-1'>Reminders</p>
              <p className='text-gray w-xxl-27 w-xl-30 w-lg-40 w-md-50 '>Add vaccines, haircuts, pills, estrus, etc. and you will receive notifications for the next event.</p>
              <div className='d-flex flex-wrap'>
                <div className='w-xxl-12 w-xm-12 w-lg-25 w-md-33 w-sm-50 w-100 mb-3 mb-xxl-0 ps-xxl-0 px-2 py-2'>
                  <div className='w-100 h-100 py-4 shadow-sm bg-light-100 rounded-2 text-center'>
                    <button onClick={() => setModalShow(true)} type='button' className='btn btn-primary btn-rounded shadow mt-2'>
                      +
                    </button>
                    <p className='font-pop-semibold mt-2 mb-2'>Add Event</p>
                  </div>
                </div>
                {reminders?.map((reminder: IReminder, index: number) => (
                  <div key={`${reminder.date}_${index}`} className='w-xxl-12 w-xm-12 w-lg-25 w-md-33 w-sm-50 w-100 mb-3 mb-xxl-0 px-2 py-2'>
                    <div className='w-100 h-100 py-4 shadow-sm rounded-2 text-center'>
                      <i className='fa-solid fa-syringe'></i>
                      <p className='mb-1 text-secondary font-manrope-bold'>{reminder?.title ?? 'N/A'}</p>
                      <p className='mb-0 fs-sm text-secondary-tint-2'>{reminder?.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default PetProfileComponent;
