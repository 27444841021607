import React from "react";
import DashboardNavbarComponent from "./Navbar";
import LogoutModalComponent from "./LogoutModal";
import "../../scss/supplier-dashboard/Dashboard-body.scss";

interface DashboardMainProps {
  children: React.ReactNode;
}

function DashboardMain({ children }: DashboardMainProps) {
  return (
    <div className="dashboard-container">
      <DashboardNavbarComponent />
      <div className="mt-3">
        {children}
        <LogoutModalComponent />
      </div>
    </div>
  );
}

export default DashboardMain;