import DashboardOrdersComponent from "../components/supplier-dashboard/DashboardOrders";

function OrderDashboard() {
  return (
    <>
      <div className="overflow-hidden">
        <DashboardOrdersComponent />
      </div>
    </>
  );
}

export default OrderDashboard;
