import { useEffect, useState } from 'react';
import axios from 'axios';

import DashboardOrderListComponent from './dashboard-home/OrderLists';
import DashboardSaleHistoryComponent from './dashboard-home/SaleHistory';
import DashboardTopProductsComponent from './dashboard-home/TopProducts';

import TransparentSpinner from '../spinner/TransparentSpinner';

import { ISupplierOrder, ISupplierProduct } from '../../shared/interface/supplier.interface';

import { getAllSupplierOrders, getAllTopSales, getAllTopSupplierProducts } from '../../services/supplierService';

function DashboardHomeComponent() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [topProductsData, setTopProductsData] = useState<ISupplierProduct[]>([]);
  const [orderListsData, setOrderListsData] = useState<ISupplierOrder[]>([]);
  const [saleListsData, setSaleListsData] = useState<ISupplierOrder[]>([]);

  const loadAllTopProduct = async () => {
    const topProductRes = await getAllTopSupplierProducts();
    setTopProductsData(topProductRes?.data?.top_products ?? []);
  };

  const loadAllOrder = async () => {
    const orderRes = await getAllSupplierOrders();
    setOrderListsData(orderRes?.data ?? []);
  };

  const loadAllTopSale = async () => {
    const orderRes = await getAllTopSales();
    setSaleListsData(orderRes?.data?.top_sales ?? []);
  };

  const initiate = async () => {
    try {
      setLoading(true);
      await loadAllTopProduct();
      await loadAllOrder();
      await loadAllTopSale();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    initiate();
  }, []);

  return (
    <>
      <div className='tab-pane show active' id='v-pills-home' role='tabpanel' aria-labelledby='v-pills-home-tab'>
        {error ? (
          <div className='text-center py-5'>{error}</div>
        ) : loading ? (
          <TransparentSpinner />
        ) : (
          <div className='row pb-4'>
            <div className='col-12  py-2 col-lg-6'>
              <DashboardTopProductsComponent data={topProductsData} />
              <div className='mb-4'></div>
              <DashboardOrderListComponent data={orderListsData} />
            </div>
            <div className='col-12  py-2 col-lg-6 mt-4 mt-lg-0'>
              <DashboardSaleHistoryComponent data={saleListsData} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DashboardHomeComponent;
