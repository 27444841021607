import { Link } from 'react-router-dom';

function VetClinicVideo() {
  return (
    <div className='card border-0'>
      <div className='card-body p-0 position-relative'>
        <img src='/images/dashboard/vet-doctor-thumb.png' alt='' className='w-100 rounded-3' />
        <div className='position-absolute top-0 start-0 w-100 h-100 rounded-3 text-center'>
          <div className='w-100 rounded-top-3 border-bottom bg-secondary-shade border-white py-2 mb-3'>
            <h6 className='text-secondary font-hahmlet-bold mb-0 ms-3 text-white'>Experience Vet Clinic And Services</h6>
          </div>
        </div>
        <Link to={'https://www.youtube.com/@nexopetbd'} target='_blank'>
          <a className='position-absolute top-50 start-50 transform-middle btn-rounded text-white btn-lg'>
            <i className='fa-solid fa-circle-play'></i>
          </a>
        </Link>
      </div>
    </div>
  );
}

export default VetClinicVideo;
