import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { StateContext } from '../../App';
import { IDoctor } from '../../shared/interface/doctor.interface';

function DoctorListComponent() {
  const { doctorState, loadDoctors } = useContext(StateContext);
  const [doctors] = doctorState;

  useEffect(() => {
    loadDoctors();
  }, []);
  return (
    <>
      <div className='py-3 bg-white'>
        <div className='bg-light-200 mx-3 rounded-3'>
          <div className='row pt-sm-3 pt-1 align-items-center container-fluid'>
            <div className='col-lg-2'>
              <h5 className='font-hahmlet-bold py-2 ps-1 ps-sm-2 bg-light-200'>All Doctors</h5>
            </div>
          </div>
          <div className='border-0 bg-light-200 rounded-3 px-3 px-sm-4'>
            {
              <>
                {/* {loading && <TransparentSpinner />} */}
                <div className='row justify-content-center py-3'>
                  {doctors?.length ? (
                    doctors?.map((doctor: IDoctor, index: number) => (
                      <div className='col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 py-3' key={`${doctor.name}_${index}`}>
                        <Link className='text-white display-9' to={`/doctors/${doctor?.id}`}>
                          <div className='card border-0 shadow-sm rounded-3'>
                            <div className='card-body'>
                              <div>
                                <img src={`${doctor?.profile_image}`} className='card-img-top' style={{ height: '9rem', borderRadius: '10px', objectFit: 'contain' }} alt='...' />
                              </div>
                              <div>
                                <h5 className='text-center mt-3'>{doctor.name}</h5>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <p>No doctor found</p>
                  )}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorListComponent;
