import { useNavigate } from 'react-router';
import { notifyMessage } from '../../App';

function LogoutModal() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('phone');
    localStorage.removeItem('role');
    notifyMessage.SUCCESS('logged out');
    window.open('/login', '_self');
  };

  return (
    <>
      <div className='modal fade' id='staticBackdrop' data-bs-backdrop='static' data-bs-keyboard='false' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-body pt-4 px-4 text-center'>Are you sure you want to log-out?</div>
            <div className='modal-footer border-0 mx-auto'>
              <button type='button' className='btn btn-outline-primary' data-bs-dismiss='modal'>
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handleLogout}>
                Yes, Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LogoutModal;
