import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { sliderSettings } from './../../shared/constant/sliderSettings.constant';
import { ISubCategory } from '../../shared/interface/category.interface';
import { Link } from 'react-router-dom';

function NextArrow(props: any) {
  const { onClick } = props;
  return (
    <div className={'carousel-control-next'} onClick={onClick}>
      <button className='carousel-control-next' type='button'>
        <i className='fa-solid fa-chevron-right text-primary'></i>
      </button>
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={'carousel-control-prev'} onClick={onClick}>
      <button className='carousel-control-prev' type='button'>
        <i className='fa-solid fa-chevron-left text-primary'></i>
      </button>
    </div>
  );
}

function BrandNameSlider({ categories }: { categories: ISubCategory[] }) {
  const settings = {
    ...sliderSettings(5),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <div className='position-relative'>
        <div className='card border-0 bg-light-200 pt-0'>
          <div className='card-body py-4'>
            <Slider {...settings}>
              {categories?.map((category: ISubCategory) => (
                <div className='px-1'>
                  <Link to={`/products/?sub_category=${category?.name}`}>
                    <button className='btn btn-primary-white w-100 rounded-3 py-2'>
                      <small>{category.name}</small>
                    </button>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandNameSlider;
