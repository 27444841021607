const FETCH_SUPPLIER_PRODUCT_REQUEST = 'FETCH_SUPPLIER_PRODUCT_REQUEST';
const FETCH_SUPPLIER_PRODUCT_SUCCESS = 'FETCH_SUPPLIER_PRODUCT_SUCCESS';
const FETCH_SUPPLIER_PRODUCT_FAILURE = 'FETCH_SUPPLIER_PRODUCT_FAILURE';

export const ADD_SUPPLIER_PRODUCT_REQUEST = 'ADD_SUPPLIER_PRODUCT_REQUEST';
export const ADD_SUPPLIER_PRODUCT_SUCCESS = 'ADD_SUPPLIER_PRODUCT_SUCCESS';
export const ADD_SUPPLIER_PRODUCT_FAILURE = 'ADD_SUPPLIER_PRODUCT_FAILURE';

export const DELETE_SUPPLIER_PRODUCT_REQUEST = 'DELETE_SUPPLIER_PRODUCT_REQUEST';
export const DELETE_SUPPLIER_PRODUCT_SUCCESS = 'DELETE_SUPPLIER_PRODUCT_SUCCCESS';
export const DELETE_SUPPLIER_PRODUCT_FAILURE = 'DELETE_SUPPLIER_PRODUCT_FAILURE';

export const EDIT_SUPPLIER_PRODUCT_REQUEST = 'EDIT_SUPPLIER_PRODUCT_REQUEST';
export const EDIT_SUPPLIER_PRODUCT_SUCCESS = 'EDIT_SUPPLIER_PRODUCT_SUCCCESS';
export const EDIT_SUPPLIER_PRODUCT_FAILURE = 'EDIT_SUPPLIER_PRODUCT_FAILURE';

export const FETCH_ONE_SUPPLIER_PRODUCT = 'FETCH_ONE_SUPPLIER_PRODUCT';

export {
  FETCH_SUPPLIER_PRODUCT_REQUEST,
  FETCH_SUPPLIER_PRODUCT_SUCCESS,
  FETCH_SUPPLIER_PRODUCT_FAILURE,
};