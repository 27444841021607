export enum EGenericPageApiKey {
  PET_LIFESTYLE = 'petlifestyle',
  PET_ENTERTAINMENT = 'petentertainment',
  PET_GROOMING = 'grooming',
  PET_GUARD = 'peteguard',
  PET_CARE = 'petcare',
  PET_FRIENDLY = 'petfriendly',
  ABOUT_US = 'aboutus',
  COMMUNITY = 'community',
  PRIVACY = 'privacy',
  POLICY = 'policy',
  TERMS_OF_USE = 'termsofuse',
  MEDICINE = 'medicine',
  VACCINE = 'vaccine',
  HEALTH_TIPS = 'healthtips',
  OTHER = 'other',
}

export enum EGenericPageRouteType {
  PET_LIFESTYLE = 'pet-lifestyle',
  PET_ENTERTAINMENT = 'pet-entertainment',
  PET_GROOMING = 'pet-grooming',
  PET_GUARD = 'pet-guard',
  PET_CARE = 'pet-care',
  PET_FRIENDLY = 'pet-friendly',
  ABOUT_US = 'about-us',
  COMMUNITY = 'community',
  MEDICINE = 'medicine',
  VACCINE = 'vaccine',
  HEALTH_TIPS = 'healthtips',
  OTHER = 'other',
  PRIVACY = 'privacy',
  POLICY = 'policy',
  TERMS_OF_USE = 'terms-of-use',
  OUR_BUSINESS_INFORMATION = 'our-business-information',
}
