import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BrandNameSliderComponent from '../components/shopbybrand/BrandNameSlider';
import ProductSlider from '../components/shopbybrand/ProductSlider';
import BrandOfferComponent from '../components/shopbybrand/BrandOffer';
import AllProductComponent from '../components/shopbybrand/AllProducts';
import TopBrandsComponent from '../components/shopbybrand/TopBrands';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { fetchShopByPetData } from '../store/actions/shopByPet/fetchAction';
import { EProductSliderType, EShopByType } from '../shared/enum/product.enum';
import { StateContext } from '../App';
import { IHomeCategories } from '../shared/interface/home.interface';

function ShopByPet() {
  const { categoryState } = useContext<{ categoryState: [IHomeCategories, React.Dispatch<React.SetStateAction<IHomeCategories | null>>] }>(StateContext);
  const [categories] = categoryState;

  const { data, error, loading } = useSelector((state: any) => state.shopByPet);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchShopByPetData());
  }, [dispatch]);

  return (
    <>
      <div className='container-fluid'>
        <h5 className='text-center text-secondary font-hahmlet-bold py-2 mb-0'>Shop By Pet</h5>
        {error ? (
          <div className='text-center py-5'>{error}</div>
        ) : loading ? (
          <TransparentSpinner />
        ) : (
          <div className='row'>
            <div className='col-lg-4'>
              <BrandNameSliderComponent categories={categories?.pet_subcategories ?? []} />
              <ProductSlider type={EProductSliderType.POPULAR} popularProducts={data?.popular_products} />
              <ProductSlider type={EProductSliderType.TOP} popularProducts={data?.top_products} />
            </div>
            <div className='col-lg-8'>
              <BrandOfferComponent banner={data?.banner} />
              <AllProductComponent category={'pet'} />
            </div>
          </div>
        )}
        <div className='mt-2'>
          <TopBrandsComponent type={EShopByType.PET} topProducts={data?.top_products} />
        </div>
      </div>
    </>
  );
}

export default ShopByPet;
