import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { IOrderList } from '../shared/interface/order.interface';

import { notifyMessage } from '../App';

import { EOrderFilterType } from '../shared/enum/filter.enum';

import { getAllOrder } from '../services/orderService';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { FormatDateAndTime } from '../shared/constant/time.constant';
import ViewProductsFromOrder from '../components/ViewProductsFromOrderModal';

function OrderListComponent() {
  const [orderList, setOrderList] = useState<IOrderList[]>([]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [order, setOrder] = useState<IOrderList | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>(EOrderFilterType.UPCOMING);
  const [loading, setLoading] = useState<boolean>(false);

  const loadOrders = async (key: string) => {
    try {
      setLoading(() => true);
      if (key === EOrderFilterType.UPCOMING) {
        const appointmentRes = await getAllOrder(EOrderFilterType.UPCOMING);
        setOrderList(() => appointmentRes?.data ?? []);
      }

      if (key === EOrderFilterType.PAST) {
        const appointmentRes = await getAllOrder(EOrderFilterType.PAST);
        setOrderList(() => appointmentRes?.data ?? []);
      }

      setLoading(() => false);
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
      setLoading(() => false);
    }
  };

  useEffect(() => {
    loadOrders(selectedFilter);
  }, [selectedFilter]);

  return (
    <>
      {loading && <TransparentSpinner />}
      <ViewProductsFromOrder selectedFilter={selectedFilter} order={order} setModalShow={setModalShow} show={modalShow} onHide={() => setModalShow(false)} />
      <div className='container'>
        <div className='pt-3'>
          <h5 className='text-secondary font-hahmlet-semibold mb-3'>Order list</h5>
          <div className='appointment-nav d-inline-block rounded-pill'>
            <div className='nav-item d-inline-block'>
              <button
                onClick={() => setSelectedFilter(EOrderFilterType.UPCOMING)}
                className={`rounded-pill nav-button ${selectedFilter === EOrderFilterType.UPCOMING && 'bg-primary active'}`}
                type='button'>
                Upcoming
              </button>
            </div>
            <div className='nav-item d-inline-block'>
              <button onClick={() => setSelectedFilter(EOrderFilterType.PAST)} className={`rounded-pill nav-button ${selectedFilter === EOrderFilterType.PAST && 'bg-primary active'}`}>
                Past
              </button>
            </div>
          </div>
        </div>
        <div className='card bg-light-200 border-0'>
          <div className='card-body doc-card-container pb-1'>
            {orderList?.length ? (
              orderList?.map((order: IOrderList) => (
                <div className='card border-0 shadow-sm mb-2'>
                  <div className='card-body'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='fw-bold fs-md'>{order?.invoice_no ?? 'N/A'}</p>
                      <p className='mb-0'>
                        <a
                          onClick={() => {
                            setOrder(order);
                            setModalShow(true);
                          }}
                          className='text-decoration-underline fw-bold fs-sm'
                          type='button'
                          role='button'>
                          View Products
                        </a>
                      </p>
                    </div>
                    {order?.order_date && (
                      <p className='fs-sm text-secondary-tint-1 border-bottom border-bottom-dashed pb-2 mb-2'>Order at Nexopet : {dayjs(order.order_date).format(FormatDateAndTime)}</p>
                    )}
                    <div className='row justify-content-between'>
                      <div className='col-xl-4 col-lg-5 col-md-6 fs-sm'>
                        <div className=' d-flex justify-content-between mb-2'>
                          <span className='text-secondary-tint-1'>Order Status</span>
                          <span className='text-secondary'>{order?.status ?? 'N/A'}</span>
                        </div>
                        <div className=' d-flex justify-content-between'>
                          <span className='text-secondary-tint-1'>Items</span>
                          <span className='text-secondary'>{order?.items?.length} Items purchased</span>
                        </div>
                      </div>
                      <div className='col-xl-4 col-lg-5 col-md-6 fs-sm mt-2 mt-md-0'>
                        <div className=' d-flex justify-content-between mb-2'>
                          <span className='text-secondary-tint-1'>Payment status</span>
                          <span className='text-secondary'>{order?.payment_method}</span>
                        </div>
                        <div className=' d-flex justify-content-between'>
                          <span className='text-secondary-tint-1'>Price</span>
                          <span className='text-primary fw-bold'>
                            <i className='fa-solid fa-bangladeshi-taka-sign'></i> {order?.total_amount}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No order found!</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderListComponent;
