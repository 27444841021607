function QuantityBtnComponent({ quantity, setQuantity }: any) {
  const handleIncrease = () => {
    setQuantity((prevQuantity: number) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity: number) => prevQuantity - 1);
    }
  };

  return (
    <div className='input-group border-none rounded'>
      <span className='input-group-btn'>
        <button type='button' className='btn btn-outline-primary' data-type='minus' data-field='' onClick={handleDecrease}>
          -
        </button>
      </span>
      <input type='text' id='quantity' name='quantity' className='form-control fs-6 bg-white input-number mx-2' style={{ width: '3rem' }} value={quantity} min='1' max='100' readOnly />
      <span className='input-group-btn'>
        <button type='button' className='btn btn-outline-primary' data-type='plus' data-field='' onClick={handleIncrease}>
          +
        </button>
      </span>
    </div>
  );
}

export default QuantityBtnComponent;
