import axios from 'axios';
import { SIGNUP_USER_REQUEST, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAILURE } from '../../constants/auth/auth';
import { BASE_URL } from '../../constants/base.constant';

export const signupRequest = () => {
  return {
    type: SIGNUP_USER_REQUEST,
  };
};

export const signupSuccess = (data: any) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: data,
  };
};

export const signupFailure = (error?: any) => {
  return {
    type: SIGNUP_USER_FAILURE,
    payload: error,
  };
};

export const signup = (userData: any, reset: any) => async (dispatch: (arg0: { type: string; payload?: any }) => void) => {
  dispatch(signupRequest());
  try {
    const response = await axios.post(`${BASE_URL}/users/send-otp`, userData);
    dispatch(signupSuccess(response?.data));
    reset();
  } catch (error: any) {
    dispatch(signupFailure(error?.message));
  }
};
