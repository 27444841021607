import { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

type RoutePermissions = {
  [key: string]: string[];
};

const routePermissions: RoutePermissions = {
  "/dashboard": ["SUPPLIER"],
  "/dashboard/products": ["SUPPLIER"],
  "/dashboard/orders": ["SUPPLIER"],
  "/dashboard/sales": ["SUPPLIER"],
};

const PrivateRoutes = () => {
  const location = useLocation();
  const [isTokenValid, setIsTokenValid] = useState<any>(null);
  const [role, setRole] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const role = localStorage.getItem("role");

    if (token) {
      setIsTokenValid(true);
      setRole(role?.toLocaleUpperCase());
    } else {
      setIsTokenValid(false);
    }
  }, []);

  return isTokenValid === false ? (
    <Navigate state={{ prevUrl: location.pathname }} to="/login" replace />
  ) : (
    role && checkRolePermissions()
  );

  function checkRolePermissions() {
    const requiredRoles = routePermissions[location.pathname];
    if (requiredRoles && role && !requiredRoles.includes(role)) {
      return (
        <Navigate
          state={{ prevUrl: location.pathname }}
          to="/dashboard/unauthorized"
          replace
        />
      );
    } else {
      return <Outlet />;
    }
  }
};

export default PrivateRoutes;
