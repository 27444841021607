import axios from 'axios';
import { useState, useEffect } from 'react';
import { BASE_URL } from '../../store/constants/base.constant';
import DashboardNewOrdersComponent from './dashboard-orders/NewOrder';
import DashboardOldOrdersComponent from './dashboard-orders/PreviousOrder';
import TransparentSpinner from '../spinner/TransparentSpinner';

function DashboardOrdersComponent() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [newOrdersData, setNewOrdersData] = useState<any>([]);
  const [oldOrdersData, setOldOrderssData] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        const newOrdersResponse = await axios.get(`${BASE_URL}/suppliers/orders?status=new`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setNewOrdersData(newOrdersResponse?.data);

        const previousListsResponse = await axios.get(`${BASE_URL}/suppliers/orders?status=previous`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setOldOrderssData(previousListsResponse?.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data from the server');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div>
        {error ? (
          <div className='text-center py-5'>{error}</div>
        ) : loading ? (
          <TransparentSpinner />
        ) : (
          <div className='row pb-4'>
            <div className='col-lg-6'>
              <DashboardNewOrdersComponent data={newOrdersData?.data} />
            </div>
            <div className='col-lg-6 mt-4 mt-lg-0'>
              <DashboardOldOrdersComponent data={oldOrdersData?.data} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DashboardOrdersComponent;
