import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import '../scss/Appointment.scss';
import { cancelAppointment, getPastAppointments, getUpcomingAppointments } from '../services/appointmentService';

import { IAppointment } from '../shared/interface/appointment.interface';

import { EAppointmentFilterType } from '../shared/enum/filter.enum';

import TransparentSpinner from '../components/spinner/TransparentSpinner';
import StarRating from '../utils/StarRating';

import { notifyMessage } from '../App';

import { BASE_IMG_URL } from '../store/constants/base.constant';
import { EUserType } from '../shared/enum/user.enum';
import { useSelector } from 'react-redux';
import { IGlobalStorage } from '../shared/interface/reduxStore.interface';
import AddPrescriptionModal from '../components/modal/AddPrescriptionModal';
import { Link } from 'react-router-dom';
import GiveReviewModal, { EReviewModalType } from '../components/GiveReviewModal';
import { setCustomerVideoCallToken, setDoctorVideoCallToken } from '../shared/service/storageService';

function AppointmentComponent() {
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>(EAppointmentFilterType.UPCOMING);
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean[]>([]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [appointmentId, setAppointmentId] = useState<number | null>(null);
  const [doctorId, setDoctorId] = useState<number | undefined>(undefined);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const { data } = useSelector((state: IGlobalStorage) => state.user);
  const navigate = useNavigate();

  const loadAppointments = async (key: string) => {
    try {
      setLoading(() => true);
      if (key === EAppointmentFilterType.UPCOMING) {
        const appointmentRes = await getUpcomingAppointments();
        setAppointments(() => appointmentRes?.data ?? []);
      }

      if (key === EAppointmentFilterType.PAST) {
        const appointmentRes = await getPastAppointments();
        setAppointments(() => appointmentRes?.data ?? []);
      }

      setLoading(() => false);
    } catch (error: any) {
      setAppointments(() => []);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
      setLoading(() => false);
    }
  };

  const handleCancelAppointment = async (appointmentId: number, index: number) => {
    let tempBtnLoading = [...btnLoading];
    try {
      tempBtnLoading[index] = true;
      setBtnLoading(tempBtnLoading);
      const res = await cancelAppointment(appointmentId);
      notifyMessage.SUCCESS(res?.message ?? 'Appointment canceled');
      await loadAppointments(selectedFilter);
      tempBtnLoading[index] = false;
      setBtnLoading(tempBtnLoading);
    } catch (error: any) {
      tempBtnLoading[index] = false;
      setBtnLoading(tempBtnLoading);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
    }
  };

  const handleJoinCall = (appointment: IAppointment) => {
    let userType = data?.user?.user_type ?? EUserType.CUSTOMER;

    if (data?.user?.user_type === EUserType.DOCTOR) {
      setDoctorVideoCallToken(appointment?.doc_token ?? null);
    }
    if (data?.user?.user_type === EUserType.CUSTOMER) {
      setCustomerVideoCallToken(appointment?.cus_token ?? null);
    }

    navigate(`/video-call/${appointment?.room_id}?userType=${userType}`);
  };

  useEffect(() => {
    loadAppointments(selectedFilter);
  }, [selectedFilter]);

  return (
    <>
      {loading && <TransparentSpinner />}
      {openReviewModal && <GiveReviewModal doctorId={doctorId} type={EReviewModalType.DOCTOR} onHide={() => setOpenReviewModal(false)} show={openReviewModal} setModalShow={setOpenReviewModal} />}
      {modalShow && (
        <AddPrescriptionModal type={EUserType.DOCTOR} setAppointments={setAppointments} appointmentId={appointmentId} setModalShow={setModalShow} show={modalShow} onHide={() => setModalShow(false)} />
      )}
      <div className='container'>
        <div className='pt-3'>
          <h5 className='text-secondary font-hahmlet-semibold mb-3'>Appointments</h5>
          <div className='appointment-nav d-inline-block rounded-pill'>
            <div className='nav-item d-inline-block'>
              <button
                onClick={() => setSelectedFilter(EAppointmentFilterType.UPCOMING)}
                className={`rounded-pill nav-button ${selectedFilter === EAppointmentFilterType.UPCOMING && 'bg-primary active'}`}
                type='button'>
                Upcoming
              </button>
            </div>
            <div className='nav-item d-inline-block'>
              <button onClick={() => setSelectedFilter(EAppointmentFilterType.PAST)} className={`rounded-pill nav-button ${selectedFilter === EAppointmentFilterType.PAST && 'bg-primary active'}`}>
                Past
              </button>
            </div>
          </div>
          <div className='mt-3'>
            <div className='tab-pane px-0'>
              {appointments.length ? (
                <div className='card bg-light-200 border-0'>
                  <div className='card-body doc-card-container'>
                    {appointments?.map((appointment: IAppointment, index: number) => (
                      <div key={`appointment_list_${appointment.id}`} className='card border-0 shadow-sm vet-doc-card'>
                        <div className='card-body row align-items-center'>
                          {data?.user?.user_type === EUserType.CUSTOMER && (
                            <div className='col-lg-4 col-sm-6 d-flex align-items-center'>
                              <div className='vet-doc-img'>
                                <img src={appointment?.doctor?.profile_image ? BASE_IMG_URL + appointment.doctor.profile_image : '/images/doc-img-sqr.png'} alt='' />
                              </div>
                              <div className='ps-4'>
                                <p className='text-secondary font-pop-semibold mb-0'>{appointment?.doctor?.name ?? 'N/A'}</p>
                                <p className='text-gray-100 fs-md mb-1'>{appointment?.doctor?.designation ?? 'N/A'}</p>
                                <small className='fs-sm'>
                                  <StarRating count={appointment?.doctor?.average_rating ?? 0} />
                                  <span className='text-secondary-tint-1'>{appointment?.doctor?.total_reviews ?? 0} reviews</span>
                                </small>
                                {appointment?.doctor?.packages?.[0]?.visit_fee && (
                                  <div>
                                    <span className='btn btn-rounded btn-sm bg-secondary-tint-2'>
                                      <i className='fa-solid fa-wallet'></i>
                                    </span>
                                    <span className='ms-1 fs-sm text-secondary'>৳ {appointment?.doctor?.packages?.[0]?.visit_fee}</span>
                                  </div>
                                )}
                                {appointment?.prescription && (
                                  <Link target='_blank' to={`${BASE_IMG_URL}${appointment?.prescription}`}>
                                    <a className='d-block mt-2 text-decoration-underline fs-sm'>View Prescription</a>
                                  </Link>
                                )}
                              </div>
                            </div>
                          )}

                          {data?.user?.user_type === EUserType.DOCTOR && (
                            <div className='col-lg-4 col-sm-6 d-flex align-items-center'>
                              <div className='vet-doc-img'>
                                <img src={appointment?.customer?.profile_image ? BASE_IMG_URL + appointment.customer.profile_image : '/images/doc-img-sqr.png'} alt='' />
                              </div>
                              <div className='ps-4'>
                                <p className='text-secondary font-pop-semibold mb-0'>{appointment?.customer?.name ?? 'N/A'}</p>
                                <p className='text-gray-100 fs-md mb-1'>{appointment?.customer?.user?.user_type ?? 'N/A'}</p>
                                {appointment?.prescription && (
                                  <Link target='_blank' to={`${BASE_IMG_URL}${appointment?.prescription}`}>
                                    <a className='text-decoration-underline fs-sm'>View</a>
                                  </Link>
                                )}
                                <a
                                  onClick={() => {
                                    setAppointmentId(appointment?.id);
                                    setModalShow(true);
                                  }}
                                  className='ms-2 text-decoration-underline fs-sm'
                                  type='button'
                                  role='button'>
                                  {appointment?.prescription ? <span>Edit Prescription</span> : <span>Add Prescription</span>}
                                </a>
                              </div>
                            </div>
                          )}

                          <div className='col-lg-4 col-sm-6 pt-3 pt-sm-0'>
                            <div className='bg-light-200 p-2 rounded-3'>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <span className='btn btn-rounded btn-secondary-tint-2'>
                                    <i className='fa-solid fa-briefcase'></i>
                                  </span>
                                </div>
                                <div className='ms-3'>
                                  <p className='fs-md text-secondary mb-0 font-pop-semibold'>Veterinary clinic "Alden-Vet"</p>
                                  <span className='fs-sm text-gray-100'>141 N Union Ave, Los Angeles, CA</span>
                                </div>
                              </div>
                              <div className='d-flex align-items-center mt-2'>
                                <div>
                                  <span className='btn btn-rounded btn-secondary-tint-2'>
                                    <i className='fa-regular fa-clock'></i>
                                  </span>
                                </div>
                                <div className='ms-3'>
                                  <span className='fs-sm text-secondary'>
                                    {appointment?.appointment_date} — {appointment?.time_alloted}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {selectedFilter === EAppointmentFilterType.UPCOMING && (
                            <div className='col-lg-4 pt-3 pt-lg-0'>
                              <div className='w-lg-80 d-flex ms-auto'>
                                <button className='btn btn-primary rounded-pill w-100 me-1' onClick={() => handleJoinCall(appointment)}>
                                  Join Call
                                </button>
                                <button disabled={btnLoading[index]} onClick={() => handleCancelAppointment(appointment.id, index)} className='btn btn-secondary-tint-2 rounded-pill w-100 ms-1'>
                                  {btnLoading[index] && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Cancel
                                </button>
                              </div>
                            </div>
                          )}
                          {selectedFilter === EAppointmentFilterType.PAST && (
                            <div className='col-lg-4 pt-3 pt-lg-0'>
                              <div className='w-lg-80 d-flex ms-auto'>
                                <button
                                  className='btn btn-primary rounded-pill w-100 me-1'
                                  onClick={() => {
                                    setOpenReviewModal(true);
                                    setDoctorId(appointment?.doctor?.id);
                                  }}>
                                  Give Review
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No appointments found!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentComponent;
