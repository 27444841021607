import {
FETCH_SHOP_BY_BRAND_FAILURE,FETCH_SHOP_BY_BRAND_REQUEST,FETCH_SHOP_BY_BRAND_SUCCESS
  } from "../../constants/shopByBrand/shopByBrand";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
  };
  
  const shopByBrandReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case FETCH_SHOP_BY_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SHOP_BY_BRAND_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case FETCH_SHOP_BY_BRAND_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default shopByBrandReducer;
  