import { ISupplierOrder } from '../../../shared/interface/supplier.interface';

function DashboardTopProducts({ data }: { data: ISupplierOrder[] }) {
  return (
    <>
      <div className='card rounded-4 pt-4 px-3 pb-2 border-snow-white shadow-sm'>
        <div className='card-title'>
          <h5>Top Sales</h5>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>#</th>
                  <th style={{ width: '40%' }}>Name</th>
                  <th style={{ width: '35%' }}>QTY</th>
                  <th style={{ width: '15%' }}>status</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((product: ISupplierOrder, index: number) => (
                  <tr key={product.id}>
                    <td>{index + 1}</td>
                    <td>{product?.supplier_product?.title}</td>
                    <td>
                      <div className='progress' role='progressbar' aria-label={`example${index + 1}`} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}>
                        <div className='progress-bar' style={{ width: `${product?.quantity}%` }}></div>
                      </div>
                    </td>
                    <td>
                      <div className='value p-2 text-center rounded-3'>{product?.status}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardTopProducts;
