import { useContext, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import '../scss/Checkout.scss';

import { placeOrder } from '../services/orderService';
import { applyCoupon } from '../services/cartService';

import { EDeliveryChargeType, EPaymentType } from '../shared/enum/payment.enum';

import { StateContext, notifyMessage } from '../App';

import { ICartResponse } from '../shared/interface/cart.interface';
import { ICODResponse } from '../shared/interface/order.interface';
import { IGlobalStorage } from '../shared/interface/reduxStore.interface';

import OrderConfirmation from '../components/OrderConfirmation';

function Checkout() {
  const { cartState }: { cartState: [ICartResponse, React.Dispatch<React.SetStateAction<ICartResponse | null>>] } = useContext(StateContext);
  const [carts, setCarts] = cartState;
  const [coupon, setCoupon] = useState<string | null>(null);

  const { data } = useSelector((state: IGlobalStorage) => state.user);

  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isOrderConfirmed, setIsOrderConfirmed] = useState<boolean>(false);
  const [orderConfirmResponse, setOrderConfirmResponse] = useState<ICODResponse | null>(null);
  const [isAgreeSubmit, setIsAgreeSubmit] = useState<boolean>(false);

  const navigate = useNavigate();

  const [selectedPayment, setSelectedPayment] = useState(EPaymentType.CASH_ON_DELIVERY);
  const [deliveryCost, setDeliveryCost] = useState(carts?.delivery_charge_inside_dhaka ?? 0);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(EDeliveryChargeType.INSIDE_OF_DHAKA);

  const handleSelectDeliveryChargeType = (type: string) => {
    if (type === EDeliveryChargeType.INSIDE_OF_DHAKA) {
      setDeliveryCost(() => carts?.delivery_charge_inside_dhaka ?? 0);
      setSelectedDeliveryType(EDeliveryChargeType.INSIDE_OF_DHAKA);
    }

    if (type === EDeliveryChargeType.OUTSIDE_OF_DHAKA) {
      setDeliveryCost(() => carts?.delivery_charge_outside_dhaka ?? 0);
      setSelectedDeliveryType(EDeliveryChargeType.OUTSIDE_OF_DHAKA);
    }
  };

  const handlerOrder = async () => {
    try {
      setLoading(true);
      const cartId = carts.id;
      const response = await placeOrder({
        cart_id: cartId,
        payment_method: selectedPayment,
        area: selectedDeliveryType,
      });

      if (selectedPayment === EPaymentType.ONLINE_PAYMENT) {
        setCarts(null);
        const gatewayPageUrl = response?.data?.GatewayPageURL?.[1] ?? null;
        if (gatewayPageUrl) {
          localStorage.removeItem('cartId');
          window.location.replace(gatewayPageUrl);
        } else {
          notifyMessage.ERROR('Gateway page URL not found in the response.');
        }
      }

      if (selectedPayment === EPaymentType.CASH_ON_DELIVERY) {
        setCarts(null);
        setIsOrderConfirmed(true);
        localStorage.removeItem('cartId');
        setOrderConfirmResponse(response?.data ?? null);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Error fetching payment');
    }
  };

  const handleApplyCoupon = async () => {
    try {
      setBtnLoading(true);
      const cartId = carts.id;
      const res = await applyCoupon({ coupon_code: coupon, cart_id: cartId });
      if (res?.cart) {
        setCarts(res.cart);
      }
      setBtnLoading(false);
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.details?.error ?? 'Cannot apply coupon');
      setBtnLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (!carts) {
      navigate('/cart');
    }
  }, []);

  return (
    <>
      {isOrderConfirmed ? (
        <OrderConfirmation orderConfirmResponse={orderConfirmResponse} />
      ) : (
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-12 col-md-10 py-2'>
              <div>
                <h5 className='text-secondary font-hahmlet-bold text-secondary display-8 text-center'>Check Out</h5>
                <div className='card bg-light-200 border-0 rounded-2'>
                  <div className='card-body px-md-5'>
                    <div>
                      <p className='mb-2 text-secondary'>Shipping Information</p>
                      <div className='bg-white rounded-2 px-4 py-3 d-flex align-items-center justify-content-between mb-2'>
                        <div>
                          <h6 className='text-secondary fw-normal'>Name: {data?.name ?? 'N/A'}</h6>
                          <p className='text-gray fw-light mb-0'>Address: {data?.shipping_address ?? 'N/A'}</p>
                        </div>
                        {/* <div>
                          <a href='#'>Change</a>
                        </div> */}
                      </div>
                    </div>
                    <div>
                      <p className='mb-2 text-secondary'>Payment</p>
                      <div
                        className={`bg-white rounded-2 px-4 py-3 d-flex align-items-md-center justify-content-between flex-column flex-md-row mb-2
                      ${selectedPayment === EPaymentType.ONLINE_PAYMENT && 'border border-gray'}
                    `}>
                        <div className='d-flex align-items-center gap-3 '>
                          <input
                            style={{ cursor: 'pointer' }}
                            type='radio'
                            name='paymentMethod'
                            id={EPaymentType.ONLINE_PAYMENT}
                            value={EPaymentType.ONLINE_PAYMENT}
                            checked={selectedPayment === EPaymentType.ONLINE_PAYMENT}
                            onChange={() => setSelectedPayment(() => EPaymentType.ONLINE_PAYMENT)}
                            className='form-check-input'
                          />
                          <div className='pay-method-img shadow-sm rounded-3'>
                            <img className='w-100' src='/images/sslcommerz.png' alt='' />
                          </div>
                          <div className='ps-4'>
                            <p className='fs-6 fs-md-5 mb-0 text-secondary'>Online Payment</p>
                          </div>
                        </div>
                        {/* <div className='text-end'>
                      <a href='#'>Change</a>
                    </div> */}
                      </div>
                      <div className={`mt-2 bg-white rounded-2 px-4 py-3 mb-2 ${selectedPayment === EPaymentType.CASH_ON_DELIVERY && 'border border-gray'}`}>
                        <div className='d-flex align-items-center gap-3'>
                          <input
                            style={{ cursor: 'pointer' }}
                            type='radio'
                            name='paymentMethod'
                            id={EPaymentType.CASH_ON_DELIVERY}
                            value={EPaymentType.CASH_ON_DELIVERY}
                            checked={selectedPayment === EPaymentType.CASH_ON_DELIVERY}
                            onChange={() => setSelectedPayment(() => EPaymentType.CASH_ON_DELIVERY)}
                            className='form-check-input'
                          />
                          <div className='pay-method-img shadow-sm rounded-3'>
                            <img src='/images/cod.png' alt='' />
                          </div>
                          <div className='ps-4'>
                            <p className='fs-6 fs-md-5 mb-0 text-secondary'>Cash On Delivery</p>
                          </div>
                        </div>
                      </div>

                      <div className='my-3'>
                        <div className='form-check'>
                          <input
                            checked={selectedDeliveryType === EDeliveryChargeType.INSIDE_OF_DHAKA}
                            value={carts?.delivery_charge_inside_dhaka ?? 0}
                            className='form-check-input'
                            type='radio'
                            name='deliveryChargeRadio'
                            id='deliveryChargeInsideDhaka'
                            onChange={() => handleSelectDeliveryChargeType(EDeliveryChargeType.INSIDE_OF_DHAKA)}
                          />
                          <label className='form-check-label' htmlFor='deliveryChargeInsideDhaka'>
                            Inside of dhaka
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            checked={selectedDeliveryType === EDeliveryChargeType.OUTSIDE_OF_DHAKA}
                            value={carts?.delivery_charge_outside_dhaka ?? 0}
                            className='form-check-input'
                            type='radio'
                            name='deliveryChargeRadio'
                            id='deliveryChargeOutsideDhaka'
                            onChange={() => handleSelectDeliveryChargeType(EDeliveryChargeType.OUTSIDE_OF_DHAKA)}
                          />
                          <label className='form-check-label' htmlFor='deliveryChargeOutsideDhaka'>
                            Outside of dhaka
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='row'>
                    <div className='col-12 col-md-7 py-3'>
                      <div>
                        <div className='row'>
                          <div className='col-12 col-md-6 py-2'>
                            <input onChange={(e) => setCoupon(e.target.value)} className='form-control p-2' type='text' placeholder='Coupon Code' />
                          </div>
                          <div className='col-12 col-md-6 py-2 ps-md-0'>
                            <button disabled={btnLoading} onClick={() => handleApplyCoupon()} className='w-100 border btn bg-white text-secondary p-2 fw-bold'>
                              {btnLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Apply Coupon
                            </button>
                          </div>
                          <div className='col-12 mt-4'>
                            <div>
                              <div className='form-check'>
                                <input onChange={(e) => setIsAgreeSubmit(e.target.checked)} className='form-check-input' type='checkbox' id='agreeCheckbox' checked={isAgreeSubmit} />
                                <label className='form-check-label' htmlFor='agreeCheckbox'>
                                  I'm well acknowledged about the <a href='/privacy-and-policy'>Privacy & Policy</a>, <a href='/terms-of-use'>Terms of use</a> of Nexo Pet
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-5 py-3'>
                      <div>
                        <div className='d-flex align-items-center justify-content-between'>
                          <p className='text-secondary'>Subtotal</p>
                          <p className='text-success fw-bold'>৳{carts?.subtotal}</p>
                        </div>
                        {carts?.coupon_discount > 0 && (
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='text-secondary'>Coupon Discount</p>
                            <p className='text-primary fw-bold'>-৳{carts?.coupon_discount}</p>
                          </div>
                        )}
                        {carts?.discount > 0 && (
                          <div className='d-flex align-items-center justify-content-between'>
                            <p className='text-secondary'>Discount</p>
                            <p className='text-primary fw-bold'>-৳{carts?.discount}</p>
                          </div>
                        )}
                        <div className='d-flex align-items-center justify-content-between'>
                          <p className='text-secondary'>Delivery charge</p>
                          <p className='text-secondary fw-bold'>৳{deliveryCost}</p>
                        </div>
                        <div className='checkout-price-border-bottom'></div>
                        <div className='pt-3 d-flex align-items-center justify-content-between'>
                          <p className='text-secondary'>Total Cost</p>
                          <p className='text-secondary fw-bold'>
                            ৳{carts && selectedPayment === EPaymentType.CASH_ON_DELIVERY ? (Number(carts?.total_amount) + deliveryCost).toFixed(2) : carts?.total_amount}
                          </p>
                        </div>
                        <div className='text-end mt-3'>
                          {carts?.items?.length > 0 && (
                            <button disabled={loading || !isAgreeSubmit} onClick={handlerOrder} className='rounded-3 btn btn-primary text-white display-9 px-5 py-2'>
                              {loading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Submit Order
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-12'>
                      <img className='w-100' src='/images/ssl-banner.jpg' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Checkout;
