export enum EProductSliderType {
  POPULAR = 'popular',
  DISCOUNT = 'discount',
  TOP = 'top',
}

export enum EShopByType {
  BRAND = 'brand',
  PET = 'pet',
}
