import axios from 'axios';
import { useState } from 'react';
import '../../scss/SubmitPet.scss';
import { BASE_URL } from '../../store/constants/base.constant';
import { notifyMessage } from '../../App';

function SubmitPet() {
  const [email, setEmail] = useState('');

  const handleSubscribe = async (e:any) => {
    e.preventDefault();
    try {
      const endpoint = `${BASE_URL}/base/subscribe`;
      const response = await axios({
        method: 'post',
        url: endpoint,
        data: {
          email: email,
        },
      });
      setEmail('')
      notifyMessage.SUCCESS('Sent Successfully')
    } catch (error:any) {
      console.error('Error handling subscription:', error);
      notifyMessage.ERROR(error?.response?.data?.details?.email[0])
    }
  };

  const handleChange = (e:any) => {
    setEmail(e.target.value);
  };

  return (
    <div className='mt-4 mt-md-3 mt-lg-3 mb-0 mb-md-3 mb-lg-0'>
      <div className='card border-primary bg-primary'>
        <div className='card-body d-flex'>
          <div className='w-45 position-relative'>
            <img className='position-absolute start-50 translate-middle-x' src='/images/dashboard/women-with-dog-3.png' alt='' />
          </div>
          <div className='w-55 py-4 '>
            <h6 className='text-secondary font-hahmlet-bold text-white'>We Would Love To Hear From You</h6>
            <form onSubmit={handleSubscribe}>
              <div className='input-group'>
                <input
                  required
                  type='email'
                  className='form-control'
                  placeholder='Email Address'
                  value={email}
                  onChange={handleChange}
                />
                <button type='submit' className='input-group-text bg-warning text-white border-warning'>
                  <span className='d-none d-md-inline-block d-lg-none d-xxl-inline-block'>Subscribe</span>
                  <span className='d-inline-block d-md-none d-lg-inline-block d-xxl-none'>
                    <i className='fa-solid fa-paper-plane'></i>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitPet;
