import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import '../scss/PetLife.scss';

import FullScreenSpinner from '../components/spinner/FullScreenSpinner';

import { IBlogDetails } from '../shared/interface/home.interface';

import { getBlogDetails } from '../services/appService';

function BlogDetailsComponent() {
  const [loading, setLoading] = useState<boolean>(false);
  const [blogDetails, setBlogDetails] = useState<IBlogDetails | null>(null);

  const { blogId } = useParams();

  const loadBlogDetails = async (blogId: number) => {
    try {
      setLoading(() => true);
      const blogDetailsRes = await getBlogDetails(blogId);
      setBlogDetails(() => blogDetailsRes.data);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    if (blogId !== undefined) {
      loadBlogDetails(Number(blogId));
    }
  }, [blogId]);
  return (
    <>
      {loading && <FullScreenSpinner />}
      {blogId !== undefined && (
        <div>
          <p className='bg-primary py-5 text-white font-hahmlet-semibold fs-5 text-center mb-4'>Blog Details</p>
          <div className='container'>
            <div className='pet-detail-wrap'>
              <div className='w-lg-35 w-md-50 w-sm-50 w-100 pet-picture me-sm-5 mb-sm-2 bg-gray'>{blogDetails?.image && <img className='w-100 h-100' src={blogDetails.image} alt='' />}</div>
              <div className='pet-detail'>
                <p className='font-hahmlet-semibold fs-5 text-secondary text-capitalize'>{blogDetails?.title ?? 'N/A'}</p>
                <span className='text-gray'>{blogDetails?.description ?? 'N/A'}</span>
              </div>
            </div>
            <div className='mt-4'>
              <p className='fs-5 text-secondary font-hahmlet-semibold'>Keys</p>
              <ul className='ps-0 pb-3 pb-md-5'>
                {blogDetails?.key_notes?.map((keyNote: string, key: number) => {
                  return (
                    <li key={`${keyNote}_${key}`} className='mb-3 text-gray d-flex'>
                      <i className='fa-solid fa-check text-primary me-3 mt-1'></i>
                      <span>{keyNote}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BlogDetailsComponent;
