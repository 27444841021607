import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { customerProductReducer, customerOneProductReducer } from './reducers/customerProduct/productReducer';
import authReducer from './reducers/auth/authReducer';
import userReducer from './reducers/user/userReducer';
import homeCategoryReducer from './reducers/homeCategory/categoryReducer';
import shopByBrandReducer from './reducers/shopByBrand/shopByBrand';
import shopByPetReducer from './reducers/shopByPet/shopByBrand';
import supplierProductReducer from './reducers/supplierProduct/productReducer';
import { composeWithDevTools } from '@redux-devtools/extension';

const reducer = combineReducers({
  products: customerProductReducer,
  productDetail: customerOneProductReducer,
  supplierProduct: supplierProductReducer,
  auth: authReducer,
  user: userReducer,
  homeCategory: homeCategoryReducer,
  shopByBrand: shopByBrandReducer,
  shopByPet: shopByPetReducer,
});

const middleware = [thunk];
const initialState = {};

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
