import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useFieldArray, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import '../scss/PetProfile.scss';

import { EGenderType } from '../shared/enum/user.enum';

import { IDoctorProfileForm, ISchedule } from '../shared/interface/doctor.interface';
import { IDoctorProfile, IDoctorProfileReview } from '../shared/interface/doctorProfile.interface';

import StarRating from '../utils/StarRating';

import { getDoctorProfile, getSchedule, updateBulkSchedule, updateDoctorProfile } from '../services/doctorService';
import { fileValidation } from '../services/fileValidationService';

import { BASE_IMG_URL } from '../store/constants/base.constant';
import { dayNameList, dayNames, dayValues } from '../shared/constant/day.constant';

import { notifyMessage } from '../App';
import TransparentSpinner from './spinner/TransparentSpinner';

function DoctorOwnProfileComponent() {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue, control, reset } = useForm<IDoctorProfileForm>({
    defaultValues: {
      id: null,
      name: null,
      email: null,
      phone: null,
      designation: null,
      biography: null,
      education: null,
      available_days: [],
      schedules: [],
      professional_experience: null,
      experience_years: null,
      gender: EGenderType.MALE.toString(),
      date_of_birth: dayjs().format('DD/MM/YYYY'),
      profile_image: null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'schedules',
  });

  const [doctorInfo, setDoctorInfo] = useState<IDoctorProfile | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<string | null>(null);

  const id = watch('id');
  const doctorImage = watch('profile_image');
  const schedules = watch('schedules');

  const handleFileUplaod = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const fileObj: File = e.target.files[0];
      const isValidate = fileValidation(fileObj);
      if (isValidate) {
        setFile(fileObj);
        setValue('profile_image', URL.createObjectURL(fileObj));
        setImageBlob(URL.createObjectURL(fileObj));
      }
    }
  };

  const updateFormData = (doctorProfile: IDoctorProfile) => {
    const { id, name, profile_image, email, designation, gender, biography, education, professional_experience, experience_years } = doctorProfile;
    const { phone } = doctorProfile.user;
    setValue('id', id);
    setValue('name', name);
    setValue('email', email);
    setValue('profile_image', profile_image);
    setValue('phone', phone);
    setValue('designation', designation);
    gender && setValue('gender', gender);
    setValue('biography', biography);
    setValue('education', education);
    setValue('professional_experience', professional_experience);
    setValue('experience_years', experience_years.toString());
  };

  const onSubmit = async (doctorProfileFormData: IDoctorProfileForm) => {
    if (id) {
      try {
        setBtnLoading(true);
        const formData = buildFormData(doctorProfileFormData);
        const schedulesBulkUpdatePayload = schedules?.map((schedule: ISchedule) => ({
          id: schedule.id,
          doctor: schedule.doctor,
          start_time: schedule.start_time,
          end_time: schedule.end_time,
          per_patient_time: schedule.per_patient_time,
          status: schedule.status,
        }));
        const updatedRes = await updateDoctorProfile(formData);
        const updatedSchedule = await updateBulkSchedule(id, schedulesBulkUpdatePayload);
        notifyMessage.SUCCESS(updatedRes?.message ?? 'Profile updated successfully');
        setBtnLoading(false);
      } catch (error: any) {
        notifyMessage.ERROR(error?.response?.data?.message ?? 'Profile cannot be updated');
        setBtnLoading(false);
      }
    }
  };

  const buildFormData = (data: IDoctorProfileForm) => {
    const { name, email, phone, designation, biography, education, professional_experience, experience_years, gender, date_of_birth } = data;
    const formData = new FormData();
    name && formData.append('name', name);
    email && formData.append('email', email);
    phone && formData.append('phone', phone);
    designation && formData.append('designation', designation);
    biography && formData.append('biography', biography);
    education && formData.append('education', education);
    professional_experience && formData.append('professional_experience', professional_experience);
    experience_years && formData.append('experience_years', experience_years);
    gender && formData.append('gender', gender);
    date_of_birth && formData.append('date_of_birth', date_of_birth);
    file && formData.append('profile_image', file);
    return formData;
  };

  const updateScheduleForm = (schedules: ISchedule[]) => {
    reset({
      name: 'schedules',
    });
    schedules?.map((data: ISchedule) => {
      append(data);
    });
  };

  const loadDoctorProfile = async () => {
    try {
      setLoading(true);
      const doctorRes = await getDoctorProfile();
      const scheduleRes = await getSchedule(doctorRes.data.id);
      scheduleRes?.data && updateScheduleForm(scheduleRes?.data ?? []);
      doctorRes?.data && updateFormData(doctorRes.data);
      setDoctorInfo(() => doctorRes?.data ?? null);
      setLoading(false);
    } catch (error) {
      navigate('/not-found');
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDoctorProfile();
  }, []);

  return (
    <>
      {loading && <TransparentSpinner />}
      <div className='container-fluid pet-profile'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row w-100 m-0 p-0'>
            <div className='col-12 col-md-6 col-lg-4 py-3'>
              <div>
                <div className='pet-img w-98'>
                  {doctorImage ? (
                    <img className='pet-img-tag rounded-2 w-100 h-100' src={imageBlob ? `${imageBlob}` : `${BASE_IMG_URL}${doctorImage}`} />
                  ) : (
                    <img className='pet-img-tag rounded-2 w-100 h-100' src={'/images/upload-image-icon.jpg'} />
                  )}
                  <button type='button' className='btn btn-rounded btn-primary fs-5'>
                    <input onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileUplaod(e)} id='doctorProfileUploaderFile' type='file' hidden />
                    <label className='cursor-pointer' htmlFor='doctorProfileUploaderFile'>
                      +
                    </label>
                  </button>
                </div>
                <div className='mt-3'>
                  <p className='fs-4 font-hahmlet-semibold text-secondary mb-1'>Your Profile</p>
                </div>
                <div className='mt-3'>
                  <div className='mb-3'>
                    <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Full Name</label>
                    <input {...register('name')} type='text' placeholder='Your Full Name' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                  </div>
                  <div className='mb-3'>
                    <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Email</label>
                    <input {...register('email')} type='text' placeholder='Your Email' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                  </div>
                  <div className='mb-3'>
                    <label className='text-primary fs-sm font-pop-semibold d-block'>Phone</label>
                    <input {...register('phone')} type='text' placeholder='Your Phone' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 py-3'>
              <div>
                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Designation</label>
                  <input {...register('designation')} type='text' placeholder='Your Designation' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                </div>

                <div className='mb-3'>
                  <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Years of experience</label>
                  <input {...register('experience_years')} type='number' placeholder='Your Experience' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                </div>

                <div className='mb-3'>
                  {fields?.map((field: ISchedule, index: number) => {
                    return (
                      <div key={`schedule_${field.id}_${index}`} className='py-3'>
                        <div className='row'>
                          <div className='col-12'>
                            <span className='fw-bold'>{field?.available_days?.[0]?.name}</span>
                          </div>
                        </div>
                        <div className='row align-items-center'>
                          <div className='col-6 py-2'>
                            <div>
                              <label htmlFor='doctorStartTime' className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>
                                Start Time
                              </label>
                              <input type='time' {...register(`schedules.${index}.start_time`)} id='doctorStartTime' className='form-control'></input>
                            </div>
                          </div>
                          <div className='col-6 py-2'>
                            <div>
                              <label htmlFor='doctorEndTime' className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>
                                End Time
                              </label>
                              <input type='time' {...register(`schedules.${index}.end_time`)} id='doctorEndTime' className='form-control'></input>
                            </div>
                          </div>
                          <div className='col-6 py-2'>
                            <div>
                              <div className='form-check'>
                                <input {...register(`schedules.${index}.status`)} className='form-check-input' type='checkbox' id={`schedule_status_${field.id}`} />
                                <label className='form-check-label' htmlFor={`schedule_status_${field.id}`}>
                                  Enable
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='mt-3'>
                          <label className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>Per Patient Time (Minutes)</label>
                          <input {...register(`schedules.${index}.per_patient_time`)} type='number' className='form-control py-1 rounded-0 ps-0 border-bottom shadow-none' />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className='mb-3'>
                  <label htmlFor='doctorBiography' className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>
                    Add Biography
                  </label>
                  <textarea {...register('biography')} rows={4} id='doctorBiography' className='form-control'></textarea>
                </div>

                <div className='mb-3'>
                  <label htmlFor='doctorEducation' className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>
                    Add Education
                  </label>
                  <textarea {...register('education')} rows={4} id='doctorEducation' className='form-control'></textarea>
                </div>

                <div className='mb-3'>
                  <label htmlFor='doctorProfessionalExperience' className='text-secondary-tint-1 fs-sm font-pop-semibold d-block'>
                    Add Professional Experience
                  </label>
                  <textarea {...register('professional_experience')} rows={4} id='doctorProfessionalExperience' className='form-control'></textarea>
                </div>
                <div>
                  <button disabled={btnLoading} type='submit' className='btn btn-primary py-3 rounded-3 w-100'>
                    {btnLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Save
                  </button>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 py-3'>
              <div>
                <div className='bg-light-200 px-3 py-2 rounded-2'>
                  <div className='d-flex justify-content-between mb-3'>
                    <p className='font-hahmlet-medium text-secondary mb-1'>Reviews</p>
                    {/* <Link to={'/'}>
                  <a className='text-secondary fs-md'>See all reviews &gt;</a>
                </Link> */}
                  </div>
                  <div>
                    {doctorInfo?.reviews?.map((review: IDoctorProfileReview) => (
                      <div key={`doctorProfileReviews_${review.id}`} className='review-card bg-white rounded-4 my-4'>
                        <div className='reviewer-info'>
                          <div className='reviewer-img'>
                            <img src={review.image ? `${BASE_IMG_URL + review.image}` : `/images/navbar-user-thumb.png`} alt='' />
                          </div>
                          <p className='mb-0 font-pop-medium fs-5'>{review?.customer?.name ?? 'N/A'}</p>
                          <div>
                            <StarRating count={review?.rating ?? 0} />
                          </div>
                        </div>
                        <div className='px-3'>
                          <p className='text-gray fs-sm w-75 mt-3'>{review?.comment ?? ''}</p>
                          <div className='d-flex justify-content-between pb-3'>
                            <p className='text-black fs-sm mb-0'>
                              Verified Review <i className='ms-2 fa-solid fa-circle-check fs-sm text-success'></i>
                            </p>
                            <p className='mb-0 text-secondary-tint-1 fs-md'>{review?.created_at && dayjs(review.created_at).format('MMMM D, YYYY')}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default DoctorOwnProfileComponent;
