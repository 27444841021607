import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MobileAppModalComponent(props: any) {
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' className='mobile-app-modal' centered>
      <Modal.Body>
        <Button onClick={props.onHide} className='p-0 bg-transparent border-0'>
          <img src='/images/cross.png' alt='' />
        </Button>
        <img src='/images/mobile-app.png' alt='mobile-app' />
      </Modal.Body>
    </Modal>
  );
}

export default MobileAppModalComponent;
