import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';

import { addOrUpdatePrescription, getUpcomingAppointments } from '../../services/appointmentService';

import { notifyMessage } from '../../App';

import { EUserType } from '../../shared/enum/user.enum';
import { addOrUpdateVet247Prescription } from '../../services/vet247Service';

interface IProps {
  appointmentId: number | null;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  setAppointments: React.Dispatch<React.SetStateAction<any[]>>;
  type: string;
  show: boolean;
  onHide: () => void;
}

function AddPrescriptionModal(props: IProps) {
  const { appointmentId, setModalShow, setAppointments, type } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const { register, handleSubmit, reset, watch } = useForm<any>({
    defaultValues: {
      prescription_text: '',
    },
  });

  const prescription_text = watch('prescription_text');

  const addOrEditPrescription = async () => {
    if (appointmentId) {
      try {
        setLoading(true);
        if (type === EUserType.DOCTOR) {
          const updatedResult = await addOrUpdatePrescription(appointmentId, prescription_text);
          await loadAppointments();
          notifyMessage.SUCCESS(updatedResult?.message);
        } else if (type === EUserType.VET247) {
          const updatedResult = await addOrUpdateVet247Prescription(appointmentId, prescription_text);
          notifyMessage.SUCCESS(updatedResult?.message);
        }
        reset();
        setModalShow(false);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notifyMessage.ERROR('Something went wrong!');
      }
    } else {
      notifyMessage.ERROR('Appointment ID not found!');
    }
  };

  const loadAppointments = async () => {
    try {
      setLoading(() => true);
      const appointmentRes = await getUpcomingAppointments();
      setAppointments(() => appointmentRes?.data ?? []);

      setLoading(() => false);
    } catch (error: any) {
      setAppointments(() => []);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
      setLoading(() => false);
    }
  };

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <form onSubmit={handleSubmit(addOrEditPrescription)}>
        <div className='modal-header py-3 bg-primary border-0'>
          <h6 className='text-white mb-0 font-pop-semibold'>Add Or Edit Prescription</h6>
          <button onClick={() => setModalShow(false)} type='button' className='btn text-white py-0 px-1'>
            <i className='fa-solid fa-xmark'></i>
          </button>
        </div>
        <Modal.Body>
          <div className='py-4 px-4'>
            <div className='mb-3'>
              <label htmlFor='prescriptionTextForm' className='form-label'>
                Write your prescription
              </label>
              <textarea className='form-control' {...register('prescription_text')} name='prescription_text' id='prescriptionTextForm' cols={30} rows={10}></textarea>
            </div>
          </div>
        </Modal.Body>
        <div className='modal-footer border-0'>
          <button disabled={loading} type='submit' className='btn btn-primary w-100 py-3 rounded-4'>
            {loading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Save
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddPrescriptionModal;
