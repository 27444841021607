import { Link } from 'react-router-dom';

import '../../scss/Footer.scss';
import { EGenericPageRouteType } from '../../shared/enum/generic.enum';
import { Image } from 'react-bootstrap';

function FooterComponent() {
  return (
    <footer>
      <div className='container-fluid row justify-content-between'>
        <div className='col-sm-6 col-md-6 col-lg-8 text-center text-sm-start'>
          <div className='mb-3'>
            <Image width={150} src='/images/nexo-new-full-logo.png' alt='' />
          </div>
          <ul className='footer-nav flex-xl-row flex-column'>
            <li className='footer-navitem ps-xl-0'>
              <Link to={`https://about.nexopet.com`} target='_blank'>
                <a className='footer-navlink'>About us</a>
              </Link>
            </li>
            <li className='footer-navitem'>
              <Link to={`/${EGenericPageRouteType.COMMUNITY}`}>
                <a className='footer-navlink'>Community</a>
              </Link>
            </li>
            <li className='footer-navitem'>
              <Link to={`/privacy-and-policy`}>
                <a className='footer-navlink'>Privacy & Policy</a>
              </Link>
            </li>
            <li className='footer-navitem'>
              <Link to={`/our-business-information`}>
                <a className='footer-navlink'>Our Business Information</a>
              </Link>
            </li>
            {/* <li className='footer-navitem'>
              <Link to={`/${EGenericPageRouteType.POLICY}`}>
                <a className='footer-navlink'>Policy</a>
              </Link>
            </li> */}
            <li className='footer-navitem'>
              <Link to={`/${EGenericPageRouteType.TERMS_OF_USE}`}>
                <a className='footer-navlink'>Terms of use</a>
              </Link>
            </li>
            <li className='footer-navitem'>
              <Link to={'/contact-us'}>
                <a className='footer-navlink'>Contact us</a>
              </Link>
            </li>
            <li className='footer-navitem'>
              <Link to={'https://twitter.com/nexopetbd'} target='_blank'>
                <a className='btn btn-rounded btn-primary-white btn-sm'>
                  <i className='fa-brands fa-twitter'></i>
                </a>
              </Link>
              <Link to={'https://www.facebook.com/nexopetbd'} target='_blank'>
                <a className='btn btn-rounded btn-primary-white btn-sm mx-2'>
                  <i className='fa-brands fa-facebook-f'></i>
                </a>
              </Link>
              <Link to={'https://www.instagram.com/nexopetbd'} target='_blank'>
                <a className='btn btn-rounded btn-primary-white btn-sm me-2'>
                  <i className='fa-brands fa-instagram'></i>
                </a>
              </Link>
              <Link to={'https://www.tiktok.com/@nexopet'} target='_blank'>
                <a className='btn btn-rounded btn-primary-white btn-sm'>
                  <i className='fa-brands fa-tiktok'></i>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className='col-sm-6 col-md-6 col-lg-4 mt-3 mt-sm-0 text-center text-sm-end'>
          <h6>
            Download Our
            <br />
            Android
          </h6>
          <div>
            <Link to={'https://play.google.com/store/apps/details?id=com.nexo.pet'} target='_blank'>
              <a className='border-0 p-0 bg-transparent me-2'>
                <img src='/images/playstore.svg' />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
