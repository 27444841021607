// import { useState } from "react";

function SearchComponent({handleSearchChange}:any) {

  return (
    <>
      <div className='search-input'>
        <input onChange={(e) => handleSearchChange(e)} style={{ borderRadius: '10px' }} type='text' className='form-control py-2 rounded-4' placeholder='Search...' />
        <span>
          <i className='fa-solid fa-magnifying-glass'></i>
        </span>
      </div>
    </>
  );
}

export default SearchComponent;
