import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IHttpResponse } from '../shared/interface/http.interface';
import { IVet247Appointment, IVet247Package, IVet247PostPackagePayload } from '../shared/interface/vet247.interface';

import { axiosConfig } from '../shared/constant/axiosConfig';

export const getAllVet247Packages = async (): Promise<IHttpResponse<IVet247Package[]>> => {
  const res = await axios.get(`${BASE_URL}/vet247/all-packages/`, axiosConfig());
  return res.data;
};

export const postVet247Package = async (payload: IVet247PostPackagePayload): Promise<IHttpResponse> => {
  const res = await axios.post(`${BASE_URL}/vet247/subscription-purchase-packages/`, payload, axiosConfig());
  return res.data;
};

export const getVet247Service = async (serviceId: number): Promise<IHttpResponse<IVet247Appointment>> => {
  const res = await axios.get(`${BASE_URL}/vet247/service247/${serviceId}/`, axiosConfig());
  return res.data;
};

export const postVet247Service = async (): Promise<IHttpResponse<IVet247Appointment>> => {
  const res = await axios.post(`${BASE_URL}/vet247/service247/`, {}, axiosConfig());
  return res.data;
};

export const updateVet247Service = async (serviceId: number): Promise<IHttpResponse<IVet247Appointment>> => {
  const res = await axios.patch(`${BASE_URL}/vet247/service247/${serviceId}/`, { is_active: true }, axiosConfig());
  return res.data;
};

export const vet247Unsubscribe = async (): Promise<IHttpResponse> => {
  const res = await axios.post(`${BASE_URL}/vet247/unsubscribe/`, {}, axiosConfig());
  return res.data;
};

export const getVet247UpcomingAppointments = async (): Promise<IHttpResponse<IVet247Appointment[]>> => {
  const res = await axios.get(`${BASE_URL}/vet247/appointments/?status=new`, axiosConfig());
  return res.data;
};

export const addOrUpdateVet247Prescription = async (appointmentId: number, prescription_text: string): Promise<IHttpResponse<IVet247Appointment[]>> => {
  const res = await axios.patch(
    `${BASE_URL}/vet247/service247/${appointmentId}/update`,
    {
      prescription_text,
    },
    axiosConfig()
  );
  return res.data;
};

export const getVet247PastAppointments = async (): Promise<IHttpResponse<IVet247Appointment[]>> => {
  const res = await axios.get(`${BASE_URL}/vet247/appointments/?status=past`, axiosConfig());
  return res.data;
};
