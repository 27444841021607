import { useState } from 'react';

function TimeSelect({ slots, setSelectedTime, selectedTime }: any) {
  const [isSelected, setIsSelected] = useState<boolean[]>([]);

  const handleSelectTime = (slot: string, index: number) => {
    setSelectedTime(() => slot);
    setIsSelected(() => {
      const newSelected = Array.from({ length: slots.length }, () => false);
      newSelected[index] = true;
      return newSelected;
    });
  };

  return (
    <div className='mt-3 bg-primary-light py-2 px-3 w-100 rounded-4 shadow-sm '>
      <div>
        <span className='btn btn-rounded bg-white text-gray-100'>
          <i className='fa-regular fa-clock'></i>
        </span>
        <span className='text-gray fs-md px-3'>Select Time</span>
        <p className='d-inline-block w-auto m-0 fs-sm form-control text-secondary bg-transparent border-0 shadow-none'>{selectedTime ?? 'Not Selected'}</p>
      </div>
      <div className='mt-2 mb-1 d-flex flex-wrap'>
        {slots?.map((slot: string, index: number) => (
          <span
            onClick={() => handleSelectTime(slot, index)}
            className={`${isSelected[index] ? 'text-white bg-primary' : 'text-gray bg-white'} cursor-pointer hover-primary py-1 px-3 m-1 font-pop-semibold fs-md rounded-pill`}>
            {slot}
          </span>
        ))}
      </div>
    </div>
  );
}

export default TimeSelect;
