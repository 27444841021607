import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IHttpResponse } from '../shared/interface/http.interface';
import { IAppointment, IAppointmentSuccess, IBuyPackagePayload, ICreateAppointment, IPackage } from '../shared/interface/appointment.interface';

import { axiosConfig } from '../shared/constant/axiosConfig';

export const getAllPackages = async (): Promise<IHttpResponse<IPackage[]>> => {
  const res = await axios.get(`${BASE_URL}/vet-clinic/all-packages`, axiosConfig());
  return res.data;
};

export const postPackage = async (payload: IBuyPackagePayload): Promise<IHttpResponse<{ GatewayPageURL: string[] }>> => {
  const res = await axios.post(`${BASE_URL}/vet-clinic/packages/`, payload, axiosConfig());
  return res.data;
};

export const getAllAppointments = async (): Promise<IHttpResponse<IAppointment[]>> => {
  const res = await axios.get(`${BASE_URL}/vet-clinic/appointments/`, axiosConfig());
  return res.data;
};

export const getOneAppointment = async (id: number): Promise<IHttpResponse<IAppointmentSuccess>> => {
  const res = await axios.get(`${BASE_URL}/vet-clinic/appointments/${id}/`, axiosConfig());
  return res.data;
};

export const getUpcomingAppointments = async (): Promise<IHttpResponse<IAppointment[]>> => {
  const res = await axios.get(`${BASE_URL}/vet-clinic/upcoming-appointments`, axiosConfig());
  return res.data;
};

export const getPastAppointments = async (): Promise<IHttpResponse<IAppointment[]>> => {
  const res = await axios.get(`${BASE_URL}/vet-clinic/past-appointments`, axiosConfig());
  return res.data;
};

export const cancelAppointment = async (appointmentId: number): Promise<IHttpResponse<IAppointment[]>> => {
  const res = await axios.patch(
    `${BASE_URL}/vet-clinic/appointments/${appointmentId}/update/`,
    {
      is_rejected: true,
    },
    axiosConfig()
  );
  return res.data;
};

export const addOrUpdatePrescription = async (appointmentId: number, prescription_text: string): Promise<IHttpResponse<IAppointment[]>> => {
  const res = await axios.patch(
    `${BASE_URL}/vet-clinic/appointments/${appointmentId}/update`,
    {
      prescription_text,
      is_rejected: false,
    },
    axiosConfig()
  );
  return res.data;
};

export const createAppointment = async (payload: ICreateAppointment): Promise<IHttpResponse<IAppointment>> => {
  const res = await axios.post(`${BASE_URL}/vet-clinic/appointments/`, payload, axiosConfig());
  return res.data;
};
