import {
  FETCH_SUPPLIER_PRODUCT_FAILURE,
  FETCH_SUPPLIER_PRODUCT_REQUEST,
  FETCH_SUPPLIER_PRODUCT_SUCCESS,
  ADD_SUPPLIER_PRODUCT_REQUEST,
  ADD_SUPPLIER_PRODUCT_SUCCESS,
  ADD_SUPPLIER_PRODUCT_FAILURE,
  DELETE_SUPPLIER_PRODUCT_REQUEST,
  DELETE_SUPPLIER_PRODUCT_SUCCESS,
  DELETE_SUPPLIER_PRODUCT_FAILURE,
  EDIT_SUPPLIER_PRODUCT_REQUEST,
  EDIT_SUPPLIER_PRODUCT_SUCCESS,
  EDIT_SUPPLIER_PRODUCT_FAILURE,
  FETCH_ONE_SUPPLIER_PRODUCT,
} from "../../constants/supplierProduct/product";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const supplierProductReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_SUPPLIER_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SUPPLIER_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_SUPPLIER_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case FETCH_ONE_SUPPLIER_PRODUCT:
        return {
          data: action.payload,
          loading: false,
        };

    case ADD_SUPPLIER_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ADD_SUPPLIER_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [action.payload],
      };

    case ADD_SUPPLIER_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_SUPPLIER_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SUPPLIER_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case DELETE_SUPPLIER_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EDIT_SUPPLIER_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_SUPPLIER_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case EDIT_SUPPLIER_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default supplierProductReducer;
