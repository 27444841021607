import { useState, useEffect, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { addToCart, createCart, getCarts } from '../../services/cartService';
import { IProduct, IProductType } from '../../shared/interface/product.interface';

import { StateContext, notifyMessage } from '../../App';
import { getAllProducts, getProductsByURL } from '../../services/productService';
import { IPagination } from '../../shared/interface/app.interface';
import TransparentSpinner from '../spinner/TransparentSpinner';

function AllProduct({ category }: { category: string }) {
  const { createCartId, cartState, productTypeState } = useContext(StateContext);
  const [productTypes] = productTypeState;
  const setCarts = cartState[1];
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean[]>([]);
  const [ordering, setOrdering] = useState('price');
  const [selectedProductType, setSelectedProductType] = useState<IProductType>(productTypes[0]);
  const [products, setProducts] = useState<IPagination<IProduct[]>>({
    results: [],
  });

  const [searchParam] = useSearchParams();
  const sub_category: any = searchParam.get('sub_category') ?? '';

  const dispatch = useDispatch<any>();

  const loadAllProducts = async (product_type?: string) => {
    try {
      setLoading(() => true);
      const productsRes = await getAllProducts({ search, ordering, category, sub_category, product_type });
      setProducts(
        productsRes ?? {
          result: [],
        }
      );
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const loadAllProductsByURL = async (url?: string | null) => {
    try {
      if (url) {
        setLoading(() => true);
        const productsRes = await getProductsByURL(url);
        setProducts(
          productsRes ?? {
            result: [],
          }
        );
        setLoading(() => false);
      }
    } catch (error) {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    loadAllProducts();
  }, [search, ordering, category, sub_category]);

  const loadProductByType = (productType: IProductType) => {
    setSelectedProductType(() => productType);
    loadAllProducts(productType?.name === 'All' ? undefined : productType?.name);
  };

  const handleSearchChange = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  const handleSortChange = (e: any) => {
    const sort = e.target.value;
    setOrdering(sort);
  };

  const loadCarts = async () => {
    try {
      setLoading(() => true);
      const cartRes = await getCarts();
      setCarts(() => cartRes.data);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const handleCart = async (e: any, product_id: number, index: number) => {
    e.preventDefault();
    try {
      await createCartId();
      await handleAddToCart(product_id, index);
    } catch (error: any) {
      if (error?.response?.data?.message.includes('Invalid')) {
        await createCart();
        await handleAddToCart(product_id, index);
        await loadCarts();
      } else {
        notifyMessage.ERROR(error?.response?.data?.message ?? 'Cannot be added to cart');
      }
    }
  };

  const handleAddToCart = async (product_id: number, index: number) => {
    setLoading(() => true);
    let tempBtnLoading = [...btnLoading];
    tempBtnLoading[index] = true;
    setBtnLoading(tempBtnLoading);
    const cartRes = await addToCart({
      product_id,
      quantity: 1,
    });
    notifyMessage.SUCCESS(cartRes?.message ?? 'Successfully added to cart');
    tempBtnLoading = [...btnLoading];
    tempBtnLoading[index] = false;
    setBtnLoading(tempBtnLoading);
    setLoading(() => false);
  };

  return (
    <div className='mt-2'>
      {loading && <TransparentSpinner />}
      <div className='bg-light-200 px-2 px-sm-4 py-3 rounded-3'>
        <div className='row align-items-center'>
          <div className='col-xl-3 col-6 col-lg-4 order-0'>
            <h5 className='text-secondary font-hahmlet-medium me-3'>All Products</h5>
          </div>
          <div className='col-xl-8 col-lg-6 d-flex justify-content-between justify-content-lg-start align-items-center order-2 order-lg-1'>
            <div className='search-input w-90'>
              <input onChange={(e) => handleSearchChange(e)} type='text' className='form-control rounded-pill placeholder-gray border-0' placeholder='Search' />
              <span>
                <i className='fa-solid fa-magnifying-glass'></i>
              </span>
            </div>

            <select name='sort' onChange={(e) => handleSortChange(e)} defaultValue={'null'} id='size' className='fs-md bg-light-200 border-0 py-2 px-3 ' style={{ cursor: 'pointer' }}>
              <option className='option' defaultValue='' disabled>
                <b className='pe-2'>Sort By</b>
                <i className='fa-solid fa-caret-down'></i>
              </option>
              <option className='option' value='selling_price'>
                price
              </option>
              <option className='option' value='quantity'>
                inventory
              </option>
            </select>
          </div>
          <div className='col-xl-1 col-lg-2 col-6 text-end order-1 order-lg-2'>
            <Link to={`/products/?category=${category}`}>
              <a>
                <small className='fs-xs text-nowrap'>
                  See All<i className='fa-solid fa-chevron-right ms-1'></i>
                </small>
              </a>
            </Link>
          </div>
        </div>
        <div className='mb-3'>
          {productTypes?.map((productType: IProductType, index: number) => (
            <span
              key={`${productType.id}_product_filter_${index}`}
              onClick={() => loadProductByType(productType)}
              className={`${selectedProductType?.id === productType?.id ? 'bg-primary' : 'border border-primary text-primary'} badge rounded-pill my-3 mx-1 cursor-pointer`}>
              {productType?.name ?? ''}
            </span>
          ))}
        </div>
        {products?.results?.length ? (
          <div>
            <div className='d-flex flex-wrap justify-content-center'>
              {products?.results?.map((product: IProduct, index: number) => (
                <div key={`shopByAllProducts_${index}`} className='w-xxl-20 w-xl-25 w-lg-33 w-md-33 w-sm-50 w-100 px-sm-2 pb-3'>
                  <Link to={`/products/${product?.id}`}>
                    <div className='card border-0 shadow-sm rounded-3'>
                      <div className='card-body py-2 px-3'>
                        <small className='d-block fw-bold mb-2 text-ellipse'>{product?.supplier_product?.title}</small>
                        <img
                          src={`${product?.supplier_product.images[0]?.image}`}
                          alt=''
                          className='card-img-top'
                          style={{
                            height: '9rem',
                            borderRadius: '10px',
                            objectFit: 'contain',
                          }}
                        />
                        <div className='d-flex py-2 justify-content-between border-bottom border-1'>
                          {/* <small>
                            <i className='fa-regular fa-heart'></i>
                          </small> */}
                          <small>
                            <i className='fa-solid fa-star text-warning'></i>
                            {product?.supplier_product?.average_rating}
                          </small>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-2 pb-1'>
                          <span className='fs-xs'>{product?.selling_price ? <b>৳{product && Math.floor(Number(product?.selling_price))}</b> : <b>N/A</b>}</span>
                          <button disabled={btnLoading[index]} className='btn btn-primary pt-0 pb-1 rounded-pill'>
                            {btnLoading[index] && <span className='spinner-border spinner-border-sm text-white' role='status'></span>}{' '}
                            <span onClick={(e) => handleCart(e, product.id, index)} className='fs-xs'>
                              Add to cart
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div>
              <div className='d-flex justify-content-center'>
                <div className='mx-1'>
                  <button disabled={!products?.previous ?? true} onClick={() => loadAllProductsByURL(products?.previous)} className='btn btn-primary'>
                    <i className='fa-solid fa-chevron-left'></i>
                  </button>
                </div>
                <div className='mx-1'>
                  <button disabled={!products?.next ?? true} onClick={() => loadAllProductsByURL(products?.next)} className='btn btn-primary'>
                    <i className='fa-solid fa-chevron-right'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>No product found</p>
        )}
      </div>
    </div>
  );
}

export default AllProduct;
