import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import '../../scss/VetDocProfile.scss';

import { notifyMessage } from '../../App';

import { IReview } from '../../shared/interface/review.interface';
import { IDoctor, ISimilarVet } from '../../shared/interface/doctor.interface';
import { ICreateAppointment, IPackage } from '../../shared/interface/appointment.interface';
import { IGlobalStorage } from '../../shared/interface/reduxStore.interface';

import PackageModal from '../PackageModal';

import { BASE_IMG_URL } from '../../store/constants/base.constant';

import { getAllReviews, getOneDoctor } from '../../services/doctorService';
import { createAppointment, getAllPackages, postPackage } from '../../services/appointmentService';

import TransparentSpinner from '../spinner/TransparentSpinner';

import DateSelect from './DateSelect';
import TimeSelect from './TimeSelect';
import StarRating from '../../utils/StarRating';

function DoctorProfileComponent() {
  const navigate = useNavigate();
  const { data } = useSelector((state: IGlobalStorage) => state.user);
  let { doctorId } = useParams();

  const [doctor, setDoctor] = useState<IDoctor | null>(null);
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<number | null>();
  const [slots, setSlots] = useState<string[]>([]);

  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handlePayNow = async () => {
    if (selectedPackage) {
      setLoading(() => true);
      const packageRes = await postPackage({ package_type: selectedPackage });
      setLoading(() => false);
      redirect(packageRes.data.GatewayPageURL[1]);
    } else {
      notifyMessage.SUCCESS('Please select packages');
    }
  };

  const handleBookNow = async () => {
    try {
      if (data?.id && doctorId && selectedDate && selectedTime) {
        setLoading(() => true);
        const payload: ICreateAppointment = {
          doctor: Number(doctorId),
          appointment_date: selectedDate,
          time_alloted: selectedTime,
          customer: data?.id,
        };
        const createRes = await createAppointment(payload);
        notifyMessage.SUCCESS(createRes?.message ?? 'Successfully created appointment');
        navigate(`/appointment/confirm/${createRes.data.id}`);
        setLoading(() => false);
      } else {
        notifyMessage.WARN('Time slot or Date is missing!');
      }
    } catch (error: any) {
      setLoading(() => false);
      if (error?.response?.status === 406) {
        setModalShow(true);
      }
      notifyMessage.ERROR(error?.response?.data?.details?.non_field_errors?.[0] ?? error?.response?.data?.message ?? 'Something went wrong!');
    }
  };

  const getPackages = async () => {
    const packageRes = await getAllPackages();
    setPackages(() => packageRes?.data ?? []);
  };

  const getDoctor = async (id: number) => {
    const res = await getOneDoctor(id);
    setDoctor(() => res?.data ?? null);
  };

  const getReviews = async (id: number) => {
    const res = await getAllReviews(id);
    setReviews(() => res?.data?.slice(0, 1) ?? []);
  };

  const initiate = async (id: number) => {
    try {
      setLoading(() => true);
      await getDoctor(id);
      await getReviews(id);
      await getPackages();
      setLoading(() => false);
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
      setLoading(() => false);
      navigate('/');
    }
  };

  useEffect(() => {
    if (doctorId !== undefined) {
      initiate(Number(doctorId));
    }
  }, [doctorId]);

  return (
    <>
      {loading && <TransparentSpinner />}
      <PackageModal setModalShow={setModalShow} handlePayNow={handlePayNow} packages={packages} setSelectedPackage={setSelectedPackage} show={modalShow} onHide={() => setModalShow(false)} />
      <div className='container-fluid'>
        <div className='row pt-2'>
          <div className='col-xl-4'>
            <div className='pt-3 d-md-flex d-xl-block'>
              <div className='vet-doc-img w-md-50 w-lg-35 w-xl-auto'>
                <img src={doctor?.profile_image} alt='' />
                <div className='doc-info rounded-4 bg-white shadow'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className='mb-1 text-secondary font-hahmlet-bold'>{doctor?.name ?? 'N/A'}</p>
                      {doctor?.services?.length && <span className='text-secondary font-pop-medium fs-md'>{doctor?.services[0]} </span>}
                    </div>
                    <div>{doctor?.average_rating && <p className='font-hahmlet-bold fs-2 text-white doc-rating mb-0 bg-primary-gradient shadow'>{doctor.average_rating}</p>}</div>
                  </div>
                  <div className='mt-2 d-flex justify-content-between'>
                    <span className='fs-sm text-gray-100'>{doctor?.professional_experience ?? 'N/A'}</span>
                    {doctor?.total_reviews && <span className='text-black fs-sm font-pop-medium'>{doctor.total_reviews} Reviews</span>}
                  </div>
                </div>
              </div>
              <div className='mt-5 mt-md-0 mt-xl-5 ps-md-3 ps-xl-0 w-md-50 w-lg-65 w-xl-auto'>
                <p className='text-gray'>{doctor?.biography ?? 'N/A'}</p>
                <div className='d-flex justify-content-between align-items-end'>
                  <div>
                    <p className='text-gray-100 fs-md mb-1'>
                      Verified Reviews <i className='ms-2 fa-solid fa-circle-check fs-sm text-success'></i>
                    </p>
                    <span>
                      <StarRating count={doctor?.average_rating ?? 0} />
                    </span>
                  </div>
                  <Link to={`/doctors/${doctorId}/reviews`}>
                    <a className='fs-md'>See All {doctor?.total_reviews} Reivews</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-md-6 pt-md-5 pt-xl-0'>
            <div className='pt-3'>
              <DateSelect doctorId={doctorId} selectedDate={selectedDate} setSlots={setSlots} setSelectedDate={setSelectedDate} />
              <TimeSelect slots={slots} selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
              <div className='mt-4'>
                <p className='font-hahmlet-medium text-secondary mb-2'>Biography</p>
                <p className='w-75 fs-sm text-gray mb-0'>{doctor?.biography ?? 'N/A'}</p>
              </div>
              <div className='mt-4'>
                <p className='font-hahmlet-medium text-secondary mb-2'>Education</p>
                <p className='w-84 fs-sm text-gray'>{doctor?.education ?? 'N/A'}</p>
              </div>
              <div className='mt-4'>
                <p className='font-hahmlet-medium text-secondary mb-2'>Professional experience</p>
                <ul className='w-84 fs-sm text-gray'>
                  <li className='d-flex mb-2'>
                    <small className='pe-2'>
                      <i className='fa-solid fa-circle text-warning'></i>
                    </small>
                    <span>{doctor?.professional_experience ?? 'N/A'}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-md-6 pt-3 pt-md-5 pt-xl-0'>
            <div className='bg-light-200 px-3 py-2 rounded-2'>
              <div className='d-flex justify-content-between mb-3'>
                <p className='font-hahmlet-medium text-secondary mb-1'>Reviews</p>
                {/* <Link to={'/'}>
                  <a className='text-secondary fs-md'>See all reviews &gt;</a>
                </Link> */}
              </div>
              <div>
                {reviews?.length ? (
                  reviews?.map((review: IReview) => {
                    return (
                      <div className='review-card bg-white rounded-4 mb-3'>
                        <div className='reviewer-info'>
                          <div className='reviewer-img'>
                            <img src={`${review?.customer?.profile_image ? BASE_IMG_URL + review?.customer?.profile_image : ''}`} alt='' />
                          </div>
                          <p className='mb-0 font-pop-medium fs-5'>{review?.customer?.name}</p>
                          <p>
                            <StarRating count={review?.rating ?? 0} />
                          </p>
                        </div>
                        <div className='px-3'>
                          <p className='text-gray fs-sm w-75 mt-5'>{review?.comment}</p>
                          <div className='d-flex justify-content-between pb-3'>
                            <p className='text-black fs-sm mb-0'>
                              Verified Review <i className='ms-2 fa-solid fa-circle-check fs-sm text-success'></i>
                            </p>
                            <p className='mb-0 text-secondary-tint-1 fs-md'>{dayjs(review?.created_at).format('MMMM D, YYYY')}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No reviews found!</p>
                )}
              </div>
              <div>
                <p className='font-hahmlet-medium text-secondary mb-2'>Other Vets</p>
                <div className='overflow-x-scroll pb-1 d-flex'>
                  {doctor?.similar_vets?.map((vet: ISimilarVet) => (
                    <div className='vet-card'>
                      <div className='d-flex pe-5'>
                        <div>
                          <img src={`${BASE_IMG_URL}/${vet?.profile_image}`} alt='' />
                        </div>
                        <div className='ms-3'>
                          <p className='font-pop-medium text-secondary mb-0 fs-5'>{vet?.name}</p>
                          {vet?.services?.length && <p className='text-gray font-manrope-medium fs-md mb-0'>{vet?.services[0]}</p>}
                          <p className='fs-sm mt-2'>
                            <span>
                              <StarRating count={vet?.average_rating ?? 0} />
                            </span>
                            <span className='text-gray-100'>{vet?.total_reviews ?? 0} reviews</span>
                          </p>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <span className='fs-sm'>
                          <span className='fs-md font-pop-medium me-1'>{vet?.experience_years}</span>years of experience
                        </span>
                        <span>
                          <div className='btn mb-0 btn-rounded btn-md bg-secondary-tint-2 text-secondary'>
                            <i className='fa-solid fa-wallet'></i>
                          </div>
                          <span className='fs-md text-secondary ms-2'>
                            <span className='fw-bold me-1'>৳</span>20
                          </span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='mt-1'>
              <button disabled={!selectedDate && !selectedTime && !doctorId} onClick={() => handleBookNow()} className='btn btn-primary w-100 rounded-4 py-3'>
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorProfileComponent;
