import { useContext, useEffect, useState } from 'react';
import axios from 'axios';

import QuantityBtnComponent from './QuentityBtn';

import { BASE_URL } from '../../store/constants/base.constant';

import { addToCart, createCart } from '../../services/cartService';

import { StateContext, notifyMessage } from '../../App';

import TransparentSpinner from '../spinner/TransparentSpinner';
import ReactPixel from 'react-facebook-pixel';

function DetailDescriptionComponent({ product }: any) {
  const { createCartId } = useContext(StateContext);
  const [quantity, setQuantity] = useState<number>(1);

  const [loading, setLoading] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean | null>(null);
  const [token, setToken] = useState<any>(null);
  const [role, setRole] = useState<any>(null);

  const handleCart = async (e: any) => {
    e.preventDefault();
    try {
      await createCartId();
      await handleAddToCart();
    } catch (error: any) {
      if (error?.response?.data?.message.includes('Invalid')) {
        await createCart();
        await handleAddToCart();
        setLoading(() => false);
      } else {
        setLoading(() => false);
        notifyMessage.ERROR(error?.response?.data?.message ?? 'Cannot be added to cart');
      }
    }
  };

  const handleAddToCart = async () => {
    setLoading(() => true);
    // Add to cart event pixel
    ReactPixel.track('AddToCart', {
      price: product?.selling_price,
      brand: product?.supplier_product?.product_type?.name,
      category: product?.supplier_product?.category,
      quantity: quantity,
      product_name: product?.supplier_product?.title
    });
    // ReactPixel.track('InitiateCheckout', {
    //   value: product?.selling_price,
    //   currency: 'BDT',
    //   content_ids: order.items.map(item => item.id),
    //   content_type: 'product',
    //   num_items: order.items.length
    // });

    const cartRes = await addToCart({
      product_id: product.id,
      quantity,
    });
    notifyMessage.SUCCESS(cartRes?.message ?? 'Successfully added to cart');
    setLoading(() => false);
  };

  const calculateAverageRating = () => {
    const totalStars = 5;
    const averageRating = product?.supplier_product?.average_rating || 0;
    return Math.round((averageRating / totalStars) * totalStars);
  };
  const goldStars = calculateAverageRating();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const role = localStorage.getItem('role');
    setToken(token);
    setRole(role);
    setIsFavorite(product?.is_favorite || null);
  }, [product]);

  const handleFavorite = async () => {
    try {
      const token = localStorage.getItem('access_token');
      setToken(token);
      const endpoint = `${BASE_URL}/customers/products/${product?.id}/toggle_favorite/`;
      const response = await axios({
        method: 'post',
        url: endpoint,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsFavorite(response?.data?.data?.is_favorite || null);
    } catch (error) {
      console.error('Error handling favorite:', error);
    }
  };
  return (
    <>
      {loading && <TransparentSpinner />}
      <div>
        <h1 style={{ fontSize: '1.4rem' }} className=' mb-3'>
          {product?.supplier_product?.title}
        </h1>
        <p style={{ fontSize: '0.87rem' }} className=' mb-3'>
          {[...Array(5)].map((_, index) => (
            <i key={index} className={`fa-solid ${index < goldStars ? 'text-warning-2' : 'text-gray'} fa-star`}></i>
          ))}
          <span className=' text-gray mx-2'>({product?.supplier_product?.total_reviews} Reviews)</span>
          <span className='text-gray mx-2'>|</span>
          <span className='text-gray mx-2 text-success-2'>{product?.quantity > 0 ? 'In Stock' : 'Stock Out'}</span>
        </p>
        <p style={{ fontSize: '1.4rem' }} className=' mb-3 fw-bolder'>
          ৳{product?.selling_price}
        </p>
      </div>
      <div className='text-gray border-bottom' style={{ height: '15rem', overflowY: 'scroll' }}>
        <p className='my-3'>{product?.supplier_product?.description}</p>
      </div>
      <div className='my-4 d-flex justify-content-between align-items-center'>
        <div>
          <QuantityBtnComponent quantity={quantity} setQuantity={setQuantity} />
        </div>
        <div className='d-flex gap-2 gap-sm-4'>
          <button className='btn btn-primary px-xl-4' style={{ borderRadius: '10px', fontSize: '16px' }} onClick={handleCart}>
            <span className='d-none d-sm-inline-block d-sm-inline-block d-lg-none d-xl-inline-block'>Add to Cart</span>
            <span className=' d-sm-none d-lg-inline-block d-xl-none'>
              <i className='fa-solid fa-cart-shopping'></i>
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default DetailDescriptionComponent;
