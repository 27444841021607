
function Unauthorized() {
  return (
    <>
      <div className="container-fluid d-flex justify-content-center align-items-center py-5">
        <p>Unauthorized Access</p>
      </div>
    </>
  );
}

export default Unauthorized;
