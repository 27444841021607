import { notifyMessage } from '../App';

import { MAX_IMAGE_UPLOAD_SIZE, SUPPORTED_FILE_TYPES } from '../shared/constant/file.constant';

export const fileValidation = (file: File): boolean => {
  if (file.size > MAX_IMAGE_UPLOAD_SIZE) {
    notifyMessage.WARN('Image size should be below 1 megabyte');
    return false;
  }

  const type = getFileType(file.name);

  if (!SUPPORTED_FILE_TYPES.includes(type)) {
    notifyMessage.WARN('File type should be jpeg or png');
    return false;
  }

  return true;
};

const getFileType = (name: string) => {
  return name.split('.')[1] ?? null;
};
