import { BASE_IMG_URL } from '../../store/constants/base.constant';

function ReviewComponent({ product }: any) {
  const formatDate = (dateString: any) => {
    const options: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  return (
    <>
      <div className=' bg-light-200 rounded p-3' style={{ height: '27rem', overflow: 'hidden' }}>
        <div>
          <div className='d-flex justify-content-between align-items-center'>
            <h1 style={{ fontSize: '1rem' }} className=''>
              Review product
            </h1>{' '}
            <p style={{ fontSize: '0.87rem' }} className=''>
              See More
            </p>
          </div>
          <p style={{ fontSize: '0.87rem' }} className=' mb-1'>
            <i className='fa-solid text-warning-2 fa-star'></i>
            <i className='fa-solid text-warning-2 fa-star'></i>
            <i className='fa-solid text-warning-2 fa-star'></i>
            <i className='fa-regular text-warning-2 fa-star'></i>
            <span className='text-gray mx-2'>{product?.rating} ({product?.product?.total_reviews} Review)</span>
          </p>
        </div>
        <div className='my-3'>
          <div className='d-flex align-items-center gap-4'>
            <img
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '35px',
              }}
              src={`${BASE_IMG_URL}/${product?.customer?.profile_image}`}
              alt=''
              className='border border-gray '
            />
            <div>
              <h6>{product?.customer.name || product?.customer.email || product?.customer.user}</h6>
              <p style={{ fontSize: '0.87rem' }} className=' mb-1'>
                <i className='fa-solid text-warning-2 fa-star'></i>
                <i className='fa-solid text-warning-2 fa-star'></i>
                <i className='fa-solid text-warning-2 fa-star'></i>
                <i className='fa-regular text-warning-2 fa-star'></i>
              </p>
            </div>
          </div>
          <div>
            <p className='mt-4 line-fix-4' style={{ fontSize: '0.87rem' }}>
              {product?.comment}
            </p>
            <div className='d-flex flex-wrap justify-content-between'>
              {/* <img src={pic2} alt='' className='border rounded mb-2 w-30' />
              <img src={pic2} alt='' className='border rounded mb-2 w-30' />
              <img src={pic2} alt='' className='border rounded mb-2 w-30' /> */}
              <img
                style={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '35px',
                }}
                src={`${BASE_IMG_URL}/${product?.product?.image}`}
                alt=''
                className='border rounded mb-2 w-30'
              />
            </div>
            <p className='my-2' style={{ fontSize: '0.87rem' }}>
              {product?.created_at && formatDate(product?.created_at)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewComponent;
