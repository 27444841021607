import { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer, Flip } from 'react-toastify';
import { Provider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import store from './store/store';
import Layout from './components/layout/layout';

import { getAllDoctors } from './services/doctorService';

import { IDoctor } from './shared/interface/doctor.interface';
import { IPet } from './shared/interface/pet.interface';
import { createCart } from './services/cartService';
import { ICartResponse } from './shared/interface/cart.interface';
import { IHomeCategories } from './shared/interface/home.interface';
import { getAllCategories } from './services/homeService';
import TransparentSpinner from './components/spinner/TransparentSpinner';
import { IProductType } from './shared/interface/product.interface';
import { getProductTypes } from './services/productService';

import ReactPixel from 'react-facebook-pixel';

export const notifyMessage = {
  SUCCESS: (msg: string) =>
    toast.success(msg, {
      className: 'toast-message',
      icon: ({ theme, type }: any) => <i className='fa-solid fa-check'></i>,
    }),
  ERROR: (msg: string) =>
    toast.error(msg, {
      className: 'toast-message',
    }),
  WARN: (msg: string) =>
    toast.warn(msg, {
      className: 'toast-message',
    }),
  INFO: (msg: string) =>
    toast.info(msg, {
      className: 'toast-message',
    }),
};

export const StateContext = createContext<any>(null);

function App() {
  const [loading, setLoading] = useState<boolean>(false);
  const [doctors, setDoctors] = useState<IDoctor[]>([]);
  const [vetTeam, setVetTeam] = useState<IDoctor[]>([]);
  const [pets, setPets] = useState<IPet[]>([]);
  const [carts, setCarts] = useState<ICartResponse | null>(null);
  const [categories, setCategories] = useState<IHomeCategories | null>(null);
  const [productTypes, setProductTypes] = useState<IProductType[]>([
    {
      name: 'All',
    },
  ]);

  const loadAllCategories = async () => {
    try {
      setLoading(true);
      const categoriesRes = await getAllCategories();
      const productTypes = await getProductTypes();
      setCategories(() => categoriesRes?.data ?? null);
      setProductTypes((prev) => [...prev, ...productTypes.data]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const loadDoctors = async () => {
    const res = await getAllDoctors();
    setVetTeam(() => res?.data?.slice(0, 3) ?? []);
    setDoctors(() => res?.data ?? []);
  };

  const createCartId = async () => {
    const cartId = localStorage.getItem('cartId');
    if (!cartId) {
      const created = await createCart();
      notifyMessage.SUCCESS('Cart ID is created successfully! Please try again!');
      return created;
    }
    return;
  };

  useEffect(() => {
    ReactPixel.pageView();
    loadAllCategories();
  }, []);

  return (
    <StateContext.Provider
      value={{
        doctorState: [doctors, setDoctors],
        vetTeamState: [vetTeam, setVetTeam],
        petState: [pets, setPets],
        cartState: [carts, setCarts],
        categoryState: [categories, setCategories],
        productTypeState: [productTypes, setProductTypes],
        loadDoctors: loadDoctors,
        createCartId: createCartId,
      }}>
      <Provider store={store}>
        <BrowserRouter>
          {loading && <TransparentSpinner />}
          <ToastContainer autoClose={3000} transition={Flip} theme={'dark'} progressClassName='toastProgress' position={'top-center'} limit={3} />
          <Layout />
        </BrowserRouter>
      </Provider>
    </StateContext.Provider>
  );
}

export default App;
