import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from '../../nexo-new-full-logo.png';

function MessagePopup() {
  return (
    <>
      <FloatingWhatsApp
        className='message-pop'
        buttonClassName='animate__animated animate__wobble animate__infinite object-shadow'
        phoneNumber='+8801919507237'
        accountName='NEXO'
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={logo}
        chatboxHeight={400}
      />
    </>
  );
}

export default MessagePopup;
