// import { useState } from "react";

function SortComponent({handleSortChange}:any) {
  return (
    <>
      <div>
        <select name='sort' onChange={(e) => handleSortChange(e)} defaultValue={'null'} id='size' className='fs-md bg-light-200 border-0 py-2 px-3 ' style={{ cursor: 'pointer' }}>
          <option className='option' defaultValue='' disabled>
            Sort by
          </option>
          <option className='option' value='selling_price'>
            price
          </option>
          <option className='option' value='quantity'>
            inventory
          </option>
        </select>
      </div>
    </>
  );
}

export default SortComponent;
