import { Link } from 'react-router-dom';
import '../../scss/ServiceOverview.scss';

function ServiceOverview({ services }: any) {
  const colors = ['bg-warning-2', 'bg-purple-2', 'bg-info-2', 'bg-success-2', 'bg-red-2'];
  return (
    <div className='mt-2'>
      <div className='row'>
        <div className='service-container col-12 mb-2 py-1'>
          <div className='card service-card border-0'>
            <div className='card-body p-0 rounded-3 shadow-md d-flex align-items-center'>
              <div className={`d-flex align-items-center justify-content-center h-100 w-25 ${colors[4]} text-white`}>
                <Link to={'/vet247'}>
                  <img src='/images/247-live-support.png' alt='' />
                  {/* <i className='fa-solid fs-5 fa-phone-volume object-shadow text-white'></i> */}
                </Link>
              </div>
              <div className='py-4 h-100 w-75 bg-primary'>
                <Link to={'/vet247'}>
                  <small className='d-block ms-2 fw-bold text-white fs-5 py-2 text-center'>24/7 Live Vet Support</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* {services?.map((item: any, index: number) => (
          <div key={`serviceOverView_${index}`} className='service-container col-sm-6 col-lg-12 col-xl-6 mb-2 py-1'>
            <div className='card service-card border-0'>
              <div className='card-body p-0 rounded-3 shadow-md d-flex align-items-center'>
                <div
                  className={`d-flex align-items-center justify-content-center h-100 w-25 
                ${colors[index] ? colors[index] : colors[0]} 
                text-white`}>
                  <i className='fa-solid fs-5 fa-phone-volume object-shadow'></i>
                </div>
                <div className='py-3 h-100 w-75'>
                  <small className='d-block ms-2 fw-bold'>
                    24/7 <br /> {item?.name}
                  </small>
                </div>
              </div>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
}

export default ServiceOverview;
