import axios from 'axios';

import { notifyMessage } from '../../../App';
import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from '../../constants/user/user';
import { BASE_URL } from '../../constants/base.constant';
import { getUserInfo } from '../../../services/userService';
import { IUserInfo } from '../../../shared/interface/user.interface';

export const fetchUserRequest = () => {
  return {
    type: FETCH_USER_REQUEST,
  };
};

export const fetchUserSuccess = (data: IUserInfo) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: data,
  };
};

export const fetchUserFailure = (error: any) => {
  return {
    type: FETCH_USER_FAILURE,
    payload: error,
  };
};

export const fetchUserData = (navigate: any) => {
  return async (dispatch: any) => {
    dispatch(fetchUserRequest());
    try {
      const token = localStorage.getItem('access_token');
      const response = await axios.post(`${BASE_URL}/users/verify-jwt-token`, {
        token,
      });
      const userInfoResponse = await getUserInfo();
      dispatch(fetchUserSuccess(userInfoResponse?.data));
      if (response && response?.status === 200) {
        localStorage.setItem('role', (response?.data?.data?.role).toUpperCase());
      }
    } catch (error: any) {
      if (error?.response && error?.response?.status) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('role');
        navigate('/login');
        notifyMessage.WARN('Please Login In Again');
      }
      dispatch(fetchUserFailure(error.message));
    }
  };
};
