
function NotFound() {
    return (
      <>
        <div className="container-fluid d-flex justify-content-center align-items-center py-5">
          <p>Page Not Found</p>
        </div>
      </>
    );
  }
  
  export default NotFound;
  