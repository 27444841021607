import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IAddSchedulePayload, IDoctor, IDoctorAvailableSlot, IGetDoctorAvailableSlot, ISchedule, IScheduleResponse } from '../shared/interface/doctor.interface';
import { IHttpResponse } from '../shared/interface/http.interface';
import { IReview } from '../shared/interface/review.interface';
import { IDoctorProfile, IDoctorReviewPayload } from '../shared/interface/doctorProfile.interface';

import { axiosConfig } from '../shared/constant/axiosConfig';

export const getAllDoctors = async (): Promise<IHttpResponse<IDoctor[]>> => {
  const res = await axios.get(`${BASE_URL}/doctors/doctors/?all`);
  return res.data;
};

export const getOneDoctor = async (id: number): Promise<IHttpResponse<IDoctor>> => {
  const res = await axios.get(`${BASE_URL}/doctors/doctors/${id}/`);
  return res.data;
};

export const getAllReviews = async (id: number): Promise<IHttpResponse<IReview[]>> => {
  const res = await axios.get(`${BASE_URL}/doctors/doctors/${id}/reviews/`);
  return res.data;
};

export const getSlots = async (payload: IGetDoctorAvailableSlot): Promise<IHttpResponse<IDoctorAvailableSlot>> => {
  const res = await axios.post(`${BASE_URL}/vet-clinic/schedules/available/`, payload, axiosConfig());
  return res.data;
};

export const getDoctorProfile = async (): Promise<IHttpResponse<IDoctorProfile>> => {
  const res = await axios.get<IHttpResponse<IDoctorProfile>>(`${BASE_URL}/doctors/doctor-info`, axiosConfig());
  return res.data;
};

export const updateDoctorProfile = async (payload: FormData): Promise<IHttpResponse<IDoctorProfile>> => {
  const res = await axios.patch<IHttpResponse<IDoctorProfile>>(`${BASE_URL}/users/update-user-info`, payload, axiosConfig());
  return res.data;
};

export const createSchedule = async (payload: IAddSchedulePayload) => {
  const res = await axios.post<IHttpResponse<IDoctorProfile>>(`${BASE_URL}/vet-clinic/schedules/`, payload, axiosConfig());
  return res.data;
};

export const updateSchedule = async (scheduleId: number, payload: IAddSchedulePayload) => {
  const res = await axios.patch<IHttpResponse<IDoctorProfile>>(`${BASE_URL}/vet-clinic/schedules/${scheduleId}/`, payload, axiosConfig());
  return res.data;
};

export const getSchedule = async (doctorId: number) => {
  const res = await axios.get<IHttpResponse<IScheduleResponse[]>>(`${BASE_URL}/doctors/doctors/${doctorId}/schedules/`, axiosConfig());
  return res.data;
};

export const updateBulkSchedule = async (doctorId: number, payload: ISchedule[]) => {
  const res = await axios.patch<IHttpResponse<IDoctorProfile>>(`${BASE_URL}/doctors/doctors/schedules/bulk-update`, payload, axiosConfig());
  return res.data;
};

export const getAllDoctorReview = async (doctorId: number) => {
  const { data } = await axios.get<IHttpResponse<any>>(`${BASE_URL}/doctors/doctors/${doctorId}/reviews/`, axiosConfig());
  return data;
};

export const addDoctorReview = async (doctorId: number, payload: IDoctorReviewPayload) => {
  const { data } = await axios.post<IHttpResponse<any>>(`${BASE_URL}/doctors/doctors/${doctorId}/reviews/`, payload, axiosConfig());
  return data;
};
