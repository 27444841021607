import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { getAllCategories, getAllSubCategories } from '../../../services/categoryService';
import { addSupplierProduct } from '../../../services/supplierService';

import { ICategory, ISubCategory } from '../../../shared/interface/category.interface';
import { IProductType, ISupplierProductPayload } from '../../../shared/interface/product.interface';

import TransparentSpinner from '../../spinner/TransparentSpinner';

import { StateContext, notifyMessage } from '../../../App';

import { fileValidation } from '../../../services/fileValidationService';

function DashboardAddProduct({ setIsAddedProductFinished }: any) {
  const { productTypeState } = useContext(StateContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [subcategories, setSubcategories] = useState<ISubCategory[]>([]);
  const [images, setImages] = useState<File[]>([]);
  const [productTypes] = productTypeState;

  const { register, handleSubmit, setValue, reset } = useForm<ISupplierProductPayload>({
    defaultValues: {
      title: '',
      description: '',
      price: 0,
      stock: 0,
    },
  });

  const loadCategories = async () => {
    const categoryRes = await getAllCategories();
    setCategories(categoryRes?.data ?? []);
    setValue('category', categoryRes.data[0].id);
  };

  const loadSubCategories = async () => {
    const subcategoryRes = await getAllSubCategories();
    setSubcategories(subcategoryRes?.data ?? []);
    setValue('sub_category', subcategoryRes.data[0].id);
  };

  const initiate = async () => {
    try {
      setLoading(true);
      await loadCategories();
      await loadSubCategories();
      setValue('product_type', productTypes[1].id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    initiate();
  }, []);

  const handleImageChange = (e: any) => {
    const files = e.target.files;
    for (let index = 0; index < files.length; index++) {
      const file: File = files[index];
      const isValidate = fileValidation(file);
      isValidate && setImages((prevImages: File[]) => [...prevImages, file]);
    }
  };

  const handleRemoveImage = (index: number) => {
    if (index > -1) {
      let tempImages = [...images];
      tempImages.splice(index, 1);
      setImages(tempImages);
    }
  };

  const onSubmit = async (data: ISupplierProductPayload) => {
    try {
      setBtnLoading(true);
      const { category, sub_category, title, description, stock, price, product_type } = data;
      const formData = new FormData();
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
      }

      formData.append('category', category.toString());
      formData.append('sub_category', sub_category.toString());
      formData.append('title', title);
      formData.append('slug', title.replace(/[^a-z0-9]+/g, '-'));
      formData.append('description', description);
      formData.append('stock', stock.toString());
      formData.append('price', price.toString());
      product_type && formData.append('product_type', product_type.toString());

      const createdRes = await addSupplierProduct(formData);
      notifyMessage.SUCCESS(createdRes?.message ?? 'Product added successfully');
      reset();
      setImages([]);
      setBtnLoading(false);
      setIsAddedProductFinished(true);
    } catch (error: any) {
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Product cannot be added');
      setBtnLoading(false);
    }
  };

  return (
    <>
      {loading && <TransparentSpinner />}
      <div className='container'>
        <div className='card rounded-4 pt-4 px-3 pb-2 border-snow-white shadow-sm h-100'>
          <div className='card-title'>
            <h5>Add New Product</h5>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='mb-3'>
                <label htmlFor='images' className='upload-supplier-product-image-container'>
                  <i className='position-absolute top-50 start-50 translate-middle fa-solid fa-image text-primary fs-1'></i>
                </label>
                <input hidden type='file' className='form-control' id='images' name='images' onChange={(e) => handleImageChange(e)} multiple />
              </div>
              <div>
                <div className='d-flex flex-wrap mb-3'>
                  {images?.map((image: any, index: number) => (
                    <div key={`supplier_add_product_image_${index}`} className='mx-1 my-1 supplier-product-image-container'>
                      <i onClick={() => handleRemoveImage(index)} className='fas fa-window-close position-absolute text-primary cursor-pointer rounded-circle'></i>
                      <img className='w-100 h-100 p-1' src={URL.createObjectURL(image)} />
                    </div>
                  ))}
                </div>
              </div>
              <div className='mb-3'>
                <label htmlFor='category' className='form-label'>
                  Category
                </label>
                <select {...register('category')} className='form-select' id='category' name='category'>
                  {categories?.map((category: ICategory) => (
                    <option key={`${category.slug}${category.id}`} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='mb-3'>
                <label htmlFor='subcategory' className='form-label'>
                  Subcategory
                </label>
                <select {...register('sub_category')} className='form-select' id='subcategory' name='sub_category'>
                  {subcategories?.map((subcategory: ISubCategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='mb-3'>
                <label htmlFor='product_type' className='form-label'>
                  Product Type
                </label>
                <select {...register('product_type')} className='form-select' id='product_type' name='product_type'>
                  {productTypes?.slice(1)?.map((productType: IProductType) => (
                    <option key={productType.id} value={productType.id}>
                      {productType.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='mb-3'>
                <label htmlFor='title' className='form-label'>
                  Title
                </label>
                <input {...register('title')} type='text' className='form-control' id='title' name='title' />
              </div>

              <div className='mb-3'>
                <label htmlFor='description' className='form-label'>
                  Description
                </label>
                <textarea {...register('description')} className='form-control' id='description' name='description' />
              </div>

              <div className='mb-3'>
                <label htmlFor='stock' className='form-label'>
                  Stock
                </label>
                <input {...register('stock')} type='number' className='form-control' id='stock' name='stock' />
              </div>

              <div className='mb-3'>
                <label htmlFor='price' className='form-label'>
                  Price
                </label>
                <input {...register('price')} type='number' className='form-control' id='price' name='price' />
              </div>

              <button disabled={btnLoading} type='submit' className='btn btn-warning w-100 py-3 rounded-3 text-white'>
                {btnLoading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardAddProduct;
