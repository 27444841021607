import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactPixel from 'react-facebook-pixel';
// require('dotenv').config();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// Initialize the Pixel
ReactPixel.init('885382600085807', undefined, {
    autoConfig: true, // This automatically configures the Pixel based on the page data
    debug: true, // Enables logging for debugging purposes
  });
  
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
