import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { notifyMessage } from '../App';

import { getOneAppointment } from '../services/appointmentService';

import { IAppointmentSuccess } from '../shared/interface/appointment.interface';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

function AppointmentConfirmComponent() {
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState<IAppointmentSuccess | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const loadOneAppointment = async () => {
    if (appointmentId) {
      try {
        setLoading(() => true);
        const appointmentRes = await getOneAppointment(Number(appointmentId));
        setAppointment(() => appointmentRes?.data ?? null);
        setLoading(() => false);
      } catch (error: any) {
        navigate('/');
        notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
        setLoading(() => false);
      }
    }
  };

  useEffect(() => {
    loadOneAppointment();
  }, [appointmentId]);
  return (
    <>
      {loading && <TransparentSpinner />}
      <div className='container'>
        <div className='px-2'>
          <div className=' bg-primary text-center rounded-3 mt-3'>
            <i className='fa-solid fa-circle-check fs-xl my-5 text-white'></i>
            <h4 className='text-white w-xl-27 mx-auto font-pop-semibold'>Your appointment has been booked</h4>
            <p className='text-white w-xl-19 mx-auto my-4'>Doctor will be waiting for you and your pet</p>
            <div className='mb-4'>
              <span className='bg-primary-shade-1 text-white py-1 px-3 rounded-pill fs-md'>
                <i className='fa-regular fa-clock me-2'></i> {appointment?.appointment_date} at {appointment?.time_alloted}
              </span>
            </div>
            <div className='py-5'>
              <Link to={'/appointment'}>
                <button className='btn btn-outline-white py-2 px-4 rounded-pill'>Go to my appointments</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentConfirmComponent;
