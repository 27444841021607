import { ChangeEvent, useState } from 'react';

import { getSlots } from '../../services/doctorService';
import TransparentSpinner from '../spinner/TransparentSpinner';
import { getCurrentDate } from '../../services/appService';

function DateSelect({ selectedDate, setSelectedDate, setSlots, doctorId }: any) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDateSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(() => true);
    setSelectedDate(() => e.target.value);
    const payload = {
      doctor_id: doctorId,
      appointment_date: e.target.value,
    };

    const res = await getSlots(payload);
    setSlots(() => res?.data?.available_time_slots ?? []);
    setLoading(() => false);
  };
  return (
    <>
      {loading && <TransparentSpinner />}
      <div className='bg-primary-light py-2 px-3 w-100 rounded-4 shadow-sm d-flex justify-content-between align-items-end align-items-sm-center'>
        <label htmlFor='doctor_profile_select_date'>
          <span className='btn btn-rounded bg-white text-gray-100'>
            <i className='fa-regular fa-calendar'></i>
          </span>
          <span className='text-gray fs-md px-3'>Select Date</span>
          <input
            onChange={(e) => handleDateSelect(e)}
            className='d-inline-block w-auto fs-sm form-control text-secondary bg-transparent border-0 shadow-none'
            id='doctor_profile_select_date'
            type='date'
            value={selectedDate}
            min={getCurrentDate()}
          />
        </label>
        <span className='mt-1 mt-sm-0'>
          <i className='text-secondary-tint-1 fa-solid fa-angle-right'></i>
        </span>
      </div>
    </>
  );
}

export default DateSelect;
