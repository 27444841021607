import DashboardHomeComponent from "../components/supplier-dashboard/DashboardHome";

function HomeDashboard() {
  return (
    <>
      <div className="overflow-hidden">
        <DashboardHomeComponent />
      </div>
    </>
  );
}

export default HomeDashboard;
