import { IAction, IState } from '../../../shared/interface/reduxStore.interface';
import { IUserInfo } from '../../../shared/interface/user.interface';

import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS } from '../../constants/user/user';

const initialState: IState<IUserInfo | null> = {
  data: null,
  loading: false,
  error: null,
};

const userReducer = (state = initialState, action: IAction<IUserInfo>) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
