import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import '../../scss/supplier-dashboard/Sidebar.scss';

import { sidebarListData } from '../../data/sidebarData';

function DashboardSidebar() {
  const [isToggled, setIsToggled] = useState(false);
  const [role, setRole] = useState<any>(null);

  useEffect(() => {
    const role = localStorage.getItem('role');
    setRole(role);
  }, [setRole]);

  const toggleStyle = () => {
    setIsToggled(!isToggled);
  };
  return (
    <>
      <div className={`sidebar-toggler d-lg-none d-inline-block ${isToggled ? 'toggled' : ''}`}>
        <button className='btn' onClick={toggleStyle}>
          <i className={`fs-4 fa-solid ${isToggled ? 'fa-xmark' : 'fa-bars-staggered'}`}></i>
        </button>
      </div>
      <div className={`sidebar-container ${isToggled ? 'toggled' : ''}`}>
        <div>
          <div className='sidebar-brand'>
            <Link to={'/dashboard'}>
              <img src='/images/supplier-dashboard/logo.png' alt='' />
            </Link>
            <Link to={'/dashboard'}>
              <span className='text-secondary fs-3 font-pop-bold'>Nexopet</span>
            </Link>
          </div>
          <div className='sidebar-nav'>
            <ul className='nav nav-menu nav-pills' aria-orientation='vertical'>
              {sidebarListData.map(
                (item: any, index: number) =>
                  item.entry?.includes(role) && (
                    <li className='nav-item' data-bs-toggle='pill' key={index}>
                      <Link to={item.path} className='sidebar-navlink'>
                        {item.icon}
                        <span>{item.display}</span>
                      </Link>
                    </li>
                  )
              )}

              <li className='nav-item' data-bs-toggle='modal' data-bs-target='#staticBackdrop'>
                <a type='button' role='button' className='sidebar-navlink'>
                  <i className='fa-solid fa-right-from-bracket'></i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='mt-auto pb-5'>
          <img src='/images/get-pro.png' alt='' />
        </div>
      </div>
    </>
  );
}

export default DashboardSidebar;
