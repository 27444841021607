import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { IOrderList } from '../shared/interface/order.interface';

import { getOneOrder } from '../services/orderService';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { FormatDateAndTime } from '../shared/constant/time.constant';

import ReactPixel from 'react-facebook-pixel';

function OrderSuccess() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<IOrderList | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const loadOrder = async () => {
    try {
      if (orderId && orderId !== undefined) {
        setLoading(true);
        const res = await getOneOrder(Number(orderId));
        setOrder(res?.data ?? null);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      navigate('/');
    }
  };

  useEffect(() => {
    if (order !== null) {

      const contents = order?.items?.map((item) => ({
        title: item.product_title,       // Product title
        price: item.total_price,       // Product price
        quantity: item.quantity, // Product quantity
        category: item.product?.category
      }));

      // Track the purchase event
      ReactPixel.track('Purchase', {
        content_name: 'Order', // Name of the content being purchased
        content_ids: order.items.map(item => item.id), // Array of product IDs
        content_type: 'product',
        contents: contents,
        value: order.total_amount, // Total purchase value
        currency: 'USD', // Currency code
        payment_method: order.payment_method,
        num_items: order.items.length // Number of items in the order
      });
    }
  }, [order]);

  useEffect(() => {
    if (orderId && orderId !== undefined) {
      loadOrder();
    }
  }, [orderId]);
  
  return (
    <>
      {loading ? (
        <TransparentSpinner />
      ) : (
        <div className='container pt-3'>
          <div className='bg-light-200 d-flex py-5 align-items-center justify-content-center rounded-3 confirmation-box-height'>
            <div className='w-30 text-center'>
              <img src='/images/order-confirm.png' className='mx-auto' alt='' />
              <h3 className='text-primary text-secondary font-hahmlet-bold'>Order Confirmed !</h3>
              <small className='text-gray py-1 d-block'>
                <strong>Status</strong> : <span>{order?.status}</span>
              </small>
              {order?.order_date && (
                <small className='text-gray py-1 d-block'>
                  <strong>Date</strong> : <span>{dayjs(order.order_date).format(FormatDateAndTime)}</span>
                </small>
              )}
              <small className='text-gray py-1 d-block'>
                <strong>Total Ammount</strong> : <span>{order?.total_price}</span>
              </small>
              <Link className='text-white display-9 ' to={'/products'}>
                <button className='btn btn-primary py-2 px-4 lh-2 rounded-3'>Continue Shopping</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderSuccess;
