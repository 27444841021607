import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import '../scss/Appointment.scss';

import { notifyMessage } from '../App';

import { EUserType } from '../shared/enum/user.enum';
import { EAppointmentFilterType } from '../shared/enum/filter.enum';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { BASE_IMG_URL } from '../store/constants/base.constant';

import { IGlobalStorage } from '../shared/interface/reduxStore.interface';
import { IVet247Appointment } from '../shared/interface/vet247.interface';

import { getVet247PastAppointments, getVet247UpcomingAppointments, updateVet247Service } from '../services/vet247Service';

import { setVet247VideoCallToken } from '../shared/service/storageService';

import AddPrescriptionModal from '../components/modal/AddPrescriptionModal';

import ReactPixel from 'react-facebook-pixel';

function Vet247Appointment() {
  const [appointments, setAppointments] = useState<IVet247Appointment[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>(EAppointmentFilterType.PAST);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [appointmentId, setAppointmentId] = useState<number | null>(null);
  const { data } = useSelector((state: IGlobalStorage) => state.user);

  const navigate = useNavigate();

  const loadAppointments = async (key: string) => {
    try {
      setLoading(() => true);
      if (key === EAppointmentFilterType.UPCOMING) {
        const appointmentRes = await getVet247UpcomingAppointments();
        setAppointments(() => appointmentRes?.data ?? []);
      }

      if (key === EAppointmentFilterType.PAST) {
        const appointmentRes = await getVet247PastAppointments();
        setAppointments(() => appointmentRes?.data ?? []);
      }

      setLoading(() => false);
    } catch (error: any) {
      setAppointments(() => []);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
      setLoading(() => false);
    }
  };

  const handleJoinCall = async (roomId: string, serviceId: number) => {
    try {
      setLoading(() => true);
      const appointmentRes = await updateVet247Service(serviceId);
      const contents = {
        duration: "limited",
        doctor_name: appointmentRes?.data?.vet247 ?? ""
      };
      // Track Custom event
      ReactPixel.track('Join Call', {
        content_name: 'Support',
        content_type: 'vet247 call',
        contents: contents
      });

      setVet247VideoCallToken(appointmentRes?.data?.token ?? null);
      if (appointmentRes?.data?.is_active) {
        navigate(`/video-call/${serviceId}/${roomId}`);
      }
      setLoading(() => false);
    } catch (error: any) {
      setAppointments(() => []);
      notifyMessage.ERROR(error?.response?.data?.message ?? 'Something went wrong!');
      setLoading(() => false);
    }
  };

  useEffect(() => {
    loadAppointments(selectedFilter);
  }, [selectedFilter]);


  return (
    <>
      {loading && <TransparentSpinner />}
      {modalShow && (
        <AddPrescriptionModal type={EUserType.VET247} setAppointments={setAppointments} appointmentId={appointmentId} setModalShow={setModalShow} show={modalShow} onHide={() => setModalShow(false)} />
      )}
      <div className='container'>
        <div className='pt-3'>
          <h5 className='text-secondary font-hahmlet-semibold mb-3'>Appointments</h5>
          <div className='appointment-nav d-inline-block rounded-pill'>
            {data?.user?.user_type === EUserType.VET247 && (
            <div className='nav-item d-inline-block'>
              <button
                onClick={() => setSelectedFilter(EAppointmentFilterType.UPCOMING)}
                className={`rounded-pill nav-button ${selectedFilter === EAppointmentFilterType.UPCOMING && 'bg-primary active'}`}
                type='button'>
                Upcoming
              </button>
            </div>
            )}
            <div className='nav-item d-inline-block'>
              <button onClick={() => setSelectedFilter(EAppointmentFilterType.PAST)} className={`rounded-pill nav-button ${selectedFilter === EAppointmentFilterType.PAST && 'bg-primary active'}`}>
                Past
              </button>
            </div>
          </div>
          <div className='mt-3'>
            <div className='tab-pane px-0'>
              {appointments.length ? (
                <div className='card bg-light-200 border-0'>
                  <div className='card-body doc-card-container'>
                    {appointments?.map((appointment: IVet247Appointment, index: number) => (
                      <div key={`appointment_list_${appointment.id}`} className='card border-0 shadow-sm vet-doc-card'>
                        <div className='card-body row align-items-center'>
                          <div className='col-lg-4 col-sm-6 d-flex align-items-center'>
                            <div className='vet-doc-img'>
                              <img src={appointment?.customer?.profile_image ? BASE_IMG_URL + appointment.customer.profile_image : '/images/doc-img-sqr.png'} alt='' />
                            </div>
                            <div className='ps-4'>
                              <p className='text-secondary font-pop-semibold mb-0'>{appointment?.customer?.name ?? 'N/A'}</p>
                              <p className='text-gray-100 fs-md mb-1'>{appointment?.customer?.user?.user_type ?? 'N/A'}</p>
                              {appointment?.prescription && (
                                <Link target='_blank' to={`${BASE_IMG_URL}${appointment?.prescription}`}>
                                  <a className='text-decoration-underline fs-sm'>View</a>
                                </Link>
                              )}
                              {selectedFilter===EAppointmentFilterType.PAST && data?.user?.user_type===EUserType.VET247 && (
                              <a
                                onClick={() => {
                                  setAppointmentId(appointment?.id);
                                  setModalShow(true);
                                }}
                                className='ms-2 text-decoration-underline fs-sm'
                                type='button'
                                role='button'>
                                {appointment?.prescription ? <span>Edit Prescription</span> : <span>Add Prescription</span>}
                              </a>
                              )}
                            </div>
                          </div>

                          <div className='col-lg-4 col-sm-6 pt-3 pt-sm-0'>
                            <div className='bg-light-200 p-2 rounded-3'>
                              <div className='d-flex align-items-center'>
                                <div>
                                  <span className='btn btn-rounded btn-secondary-tint-2'>
                                    <i className='fa-solid fa-briefcase'></i>
                                  </span>
                                </div>
                                <div className='ms-3'>
                                  <p className='fs-md text-secondary mb-0 font-pop-semibold'>Veterinary clinic "Alden-Vet"</p>
                                  <span className='fs-sm text-gray-100'>141 N Union Ave, Los Angeles, CA</span>
                                </div>
                              </div>
                              <div className='d-flex align-items-center mt-2'>
                                <div>
                                  <span className='btn btn-rounded btn-secondary-tint-2'>
                                    <i className='fa-regular fa-clock'></i>
                                  </span>
                                </div>
                                <div className='ms-3'>
                                  <span className='fs-sm text-secondary'>{appointment?.created_at && new Date(appointment.created_at).toLocaleString()}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {selectedFilter === EAppointmentFilterType.UPCOMING && (
                            <div className='col-lg-4 pt-3 pt-lg-0'>
                              <div className='w-lg-80 d-flex ms-auto'>
                                <button className='btn btn-primary rounded-pill w-100 me-1' onClick={() => handleJoinCall(appointment?.room_id, appointment?.id)}>
                                  Join Call
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No appointments found!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vet247Appointment;
