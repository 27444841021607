import DashboardSalesComponent from "../components/supplier-dashboard/DashboardSales";

function SalesDashboard() {
  return (
    <>
      <div className="overflow-hidden">
        <DashboardSalesComponent />
      </div>
    </>
  );
}

export default SalesDashboard;
