function BrandOffer({banner}:any) {
  return (
    <div className='mt-2 mt-lg-0'>
      {banner?.map((item:any,index:number)=>(
      <div key={index} className='card bg-primary border-0'>
        <div className='card-body position-relative px-5'>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <h6 className='font-manrope-medium text-white'>{item.name}</h6>
              <h5 className='font-manrope-bold text-white mb-0'>{item?.description}</h5>
            </div>
            <div className='position-absolute end-0 translate-middle-x bottom-0 o-25 o-sm-100'>
              <img src='/images/product-dashboard/surprize.png' alt='' />
            </div>
          </div>
        </div>
      </div>

      ))}
    </div>
  );
}

export default BrandOffer;
