// @ts-nocheck
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { notifyMessage } from '../../../App';

import { LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS } from '../../constants/auth/auth';

import { BASE_URL } from '../../constants/base.constant';
import { EUserType } from '../../../shared/enum/user.enum';

export const loginRequest = () => {
  return {
    type: LOGIN_USER_REQUEST,
  };
};

export const loginSuccess = (data) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: data,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_USER_FAILURE,
    payload: error,
  };
};

export const login = (userData, reset, location, navigate) => async (dispatch) => {
  dispatch(loginRequest());
  let role: string | null = null;
  try {
    const response = await axios.post(`${BASE_URL}/users/verify-otp`, userData);
    dispatch(loginSuccess(response.data));
    if (response.status === 200) {
      const token = response.data.access_token;
      localStorage.setItem('access_token', token);
      const decoded_token = jwtDecode(token);
      localStorage.setItem('phone', decoded_token.phone);
      localStorage.setItem('role', decoded_token.role);
      role = decoded_token.role;
    }
    reset();
    notifyMessage.SUCCESS('logged in');
    if (role === EUserType.SUPPLIER) {
      navigate('/dashboard');
    } else if (role === EUserType.DOCTOR) {
      navigate('/doctor/profile');
    } else if (role === EUserType.VET247) {
      navigate('/vet247/appointment');
    } else if (location.state?.prevUrl) {
      navigate(location.state?.prevUrl);
    } else {
      navigate('/');
    }
  } catch (error) {
    dispatch(loginFailure(error?.response?.data?.message));
    console.error(error);
  }
};
