import React, { useState } from 'react';

function DetailImageComponent({ images }: any) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleImageClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div className='col-sm-4 order-1 order-sm-0 mt-2 mt-sm-0'>
        <div className='row flex-row justify-content-between gx-0'>
          {images?.length === 1 ? (
            <img src={images[0].image} className='rounded border border-success w-23 w-sm-100 p-2 object-fit-contain' style={{ height: 'calc(27rem / 4 - 6px)', backgroundColor: '#F5F6F8' }} />
          ) : (
            images?.map((image: any, index: number) => (
              <img
                key={index}
                src={image.image}
                className={`cursor-pointer mb-3 rounded w-23 w-sm-100 p-2 object-fit-contain border ${index === activeIndex ? 'border-success' : ''}`}
                style={{ height: 'calc(27rem / 4 - 6px)', backgroundColor: '#F5F6F8' }}
                onClick={() => handleImageClick(index)}
              />
            ))
          )}
        </div>
      </div>
      <div className='col-sm-8 order-0 order-sm-1 rounded-3 border' style={{ height: '27rem', backgroundColor: '#F5F6F8' }}>
        <img className='rounded w-100 h-100 p-3 object-fit-contain' src={images && images[activeIndex]?.image} />
      </div>
    </>
  );
}

export default DetailImageComponent;
