import {
FETCH_SHOP_BY_PET_FAILURE,FETCH_SHOP_BY_PET_REQUEST,FETCH_SHOP_BY_PET_SUCCESS
  } from "../../constants/shopByPet/shopByPet";
  
  const initialState = {
    data: [],
    loading: false,
    error: null,
  };
  
  const shopByPetReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case FETCH_SHOP_BY_PET_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_SHOP_BY_PET_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case FETCH_SHOP_BY_PET_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default shopByPetReducer;
  