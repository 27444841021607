import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IHttpResponse } from '../shared/interface/http.interface';
import { IPet, IReminder } from '../shared/interface/pet.interface';

import { axiosConfig } from '../shared/constant/axiosConfig';

export const getAllPets = async (): Promise<IHttpResponse<IPet[]>> => {
  const res = await axios.get(`${BASE_URL}/vet-clinic/pets/`, axiosConfig());
  return res.data;
};

export const getPetDetails = async (petId: number): Promise<IHttpResponse<IPet>> => {
  const res = await axios.get(`${BASE_URL}/vet-clinic/pets/${petId}/`, axiosConfig());
  return res.data;
};

export const createPet = async (payload: FormData): Promise<IHttpResponse<IPet>> => {
  const res = await axios.post(`${BASE_URL}/vet-clinic/pets/`, payload, axiosConfig());
  return res.data;
};

export const updatePet = async (id: number, payload: FormData): Promise<IHttpResponse<IPet>> => {
  const res = await axios.patch(`${BASE_URL}/vet-clinic/pets/${id}/`, payload, axiosConfig());
  return res.data;
};

export const getReminers = async (petId: number): Promise<IHttpResponse<IReminder[]>> => {
  const res = await axios.get<IHttpResponse<IReminder[]>>(`${BASE_URL}/vet-clinic/pets/${petId}/reminders/`, axiosConfig());
  return res.data;
};

export const createReminder = async (petId: number, payload: IReminder): Promise<IHttpResponse<IReminder>> => {
  const res = await axios.post<IHttpResponse<IReminder>>(`${BASE_URL}/vet-clinic/pets/${petId}/reminders/`, payload, axiosConfig());
  return res.data;
};
