function FullScreenSpinner() {
  return (
    <div className='full-screen-spinner-container'>
      <div className='position-relative h-100'>
        <div className='spinner-content'>
          <div style={{ width: '3rem', height: '3rem' }} className='spinner-border text-white' role='status'></div>
        </div>
      </div>
    </div>
  );
}

export default FullScreenSpinner;
