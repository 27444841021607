import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { IOrderItem, IOrderList } from '../shared/interface/order.interface';

import { BASE_IMG_URL } from '../store/constants/base.constant';

import GiveReviewModal, { EReviewModalType } from './GiveReviewModal';

import { EOrderFilterType } from '../shared/enum/filter.enum';

interface IProps {
  order: IOrderList | null;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  selectedFilter: string;
  onHide: () => void;
}

function ViewProductsFromOrder(props: IProps) {
  const { order, selectedFilter, setModalShow } = props;

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [productId, setProductId] = useState<number | undefined>(undefined);

  return (
    <>
      {openReviewModal && (
        <GiveReviewModal type={EReviewModalType.CUSTOMER} orderId={order?.id} productId={productId} onHide={() => setOpenReviewModal(false)} show={openReviewModal} setModalShow={setOpenReviewModal} />
      )}
      <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <form>
          <div className='modal-header py-3 bg-primary border-0'>
            {order?.invoice_no && <h6 className='text-white mb-0 font-pop-semibold'>Order ID: {order.invoice_no}</h6>}
            <button onClick={() => setModalShow(false)} type='button' className='btn text-white py-0 px-1'>
              <i className='fa-solid fa-xmark'></i>
            </button>
          </div>
          <Modal.Body>
            <div className='py-4 px-4'>
              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th style={{ width: '10%' }}>#</th>
                      <th style={{ width: '30%' }}>Name</th>
                      <th style={{ width: '10%' }}>Quantity</th>
                      <th style={{ width: '15%' }}>Unit Price</th>
                      <th style={{ width: '15%' }}>Total Price</th>
                      {selectedFilter === EOrderFilterType.PAST && <th style={{ width: '25%' }}>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {order?.items?.map((item: IOrderItem, index: number) => (
                      <tr key={item.id}>
                        <td>
                          <img style={{ height: '50px', width: '50px' }} src={`${BASE_IMG_URL}/${item.product_image}`} alt='' />
                        </td>
                        <td>{item?.product_title}</td>
                        <td className='text-center'>{item?.quantity}</td>
                        <td className='text-center'>
                          {item?.unit_price} <i className='fa-solid fa-bangladeshi-taka-sign'></i>
                        </td>
                        <td className='text-center'>
                          {item?.total_price} <i className='fa-solid fa-bangladeshi-taka-sign'></i>
                        </td>
                        {selectedFilter === EOrderFilterType.PAST && (
                          <td className='text-center'>
                            <a
                              onClick={() => {
                                setOpenReviewModal(() => true);
                                setProductId(() => item.id);
                              }}
                              className='text-underline cursor-pointer'>
                              Add review
                            </a>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
}

export default ViewProductsFromOrder;
