import { useState, useEffect } from 'react';

import DashboardSaleHistoryComponent from './dashboard-sales/SaleHistory';
import TransparentSpinner from '../spinner/TransparentSpinner';

import { ISupplierSale } from '../../shared/interface/supplier.interface';

import { getAllSaleHistory } from '../../services/supplierService';

function DashboardSalesComponent() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [saleHistoryData, setSaleHistoryData] = useState<ISupplierSale[]>([]);

  const loadSaleHistory = async () => {
    try {
      setLoading(true);
      const topProductsResponse = await getAllSaleHistory();
      setSaleHistoryData(topProductsResponse?.data ?? []);
      setLoading(false);
    } catch (error) {
      setError('Error fetching data from the server');
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSaleHistory();
  }, []);
  return (
    <>
      {loading ? (
        <TransparentSpinner />
      ) : (
        <div>
          <div className='row pb-4'>
            {error ? (
              <div className='text-center py-5'>{error}</div>
            ) : (
              <div className='col-lg-6'>
                <DashboardSaleHistoryComponent data={saleHistoryData} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default DashboardSalesComponent;
