import { Image } from 'react-bootstrap';
import LoginForm from '../components/auth/loginForm';

function Login() {
  return (
    <>
      <section className='position-absolute top-50 start-50 translate-middle d-flex align-items-center w-100 h-100'>
        <div className='w-100 h-100'>
          <div className='row gx-5 h-100 align-items-center m-0 p-0'>
            <div className='col-12 col-md-6 p-0 h-100 bg-light-100'>
              <div className='row align-items-center w-100 h-100 mx-0 py-5 overflow-y-auto'>
                <div className='col-12'>
                  <div className='text-center'>
                    <Image width={216} src='/images/nexo-new-full-logo.png' alt='' />
                  </div>
                  <div className='text-center mt-5'>
                    <div className='row w-100 justify-content-center mx-0'>
                      <div className='col-md-8'>
                        <h5 className='text-secondary display-8 fw-semi-bold'>Enter OTP</h5>

                        <p className='text-gray-100 mb-4 mt-3'>
                          Check your mobile and enter the <br /> OTP code here
                        </p>
                        <div className='my-4'>
                          <LoginForm />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 h-100 px-0 bg-primary overflow-hidden'>
              <div className='row align-items-center w-100 h-100 mx-0 py-5 overflow-y-auto'>
                <div className='col-12 text-center vector_image_auth'>
                  <img className='w-75 px-5' src='/images/login-bg.png' alt='login' />
                  <div className='text-center'>
                    <h4 className='text-white display-6 fw-bold mt-4'>Loved by pets trusted by parents</h4>
                    <p className='text-white display-10 mt-3'>
                      Bringing together dynamic scheduling and an intuitive user experience, Hazira Scheduling simplifies the day-to-day by allowing administrators to easily create error-free,
                      demand-driven schedules by location, department, position and skills.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
