import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IHttpResponse } from '../shared/interface/http.interface';
import { IAddToCartPayload, ICartResponse, IGenerateCartResponse } from '../shared/interface/cart.interface';

export const getCarts = async () => {
  const cartId = localStorage.getItem('cartId');
  const { data } = await axios.get<IHttpResponse<ICartResponse>>(`${BASE_URL}/customers/carts/${cartId}/`);
  return data;
};

export const createCart = async () => {
  const { data } = await axios.post<IHttpResponse<IGenerateCartResponse>>(`${BASE_URL}/customers/carts/`, {});
  localStorage.setItem('cartId', data?.data?.id ?? null);
  return data;
};

export const applyCoupon = async (payload: { cart_id: string; coupon_code: string | null }) => {
  const { data } = await axios.post<{ success: boolean; message: string; cart: ICartResponse }>(`${BASE_URL}/customers/apply-coupon`, payload);
  return data;
};

export const addToCart = async (payload: IAddToCartPayload) => {
  const cartId = localStorage.getItem('cartId');
  const { data } = await axios.post<IHttpResponse<any>>(`${BASE_URL}/customers/carts/${cartId}/items/`, payload);
  return data;
};

export const updateCartItem = async (id: number, payload: { product_id: number; quantity: number }) => {
  const cartId = localStorage.getItem('cartId');
  const { data } = await axios.patch<IHttpResponse<any>>(`${BASE_URL}/customers/carts/${cartId}/items/${id}/`, payload);
  return data;
};

export const removeCartItems = async (cartItemId: number) => {
  const cartId = localStorage.getItem('cartId');
  const { data } = await axios.delete<IHttpResponse<any>>(`${BASE_URL}/customers/carts/${cartId}/items/${cartItemId}/`);
  return data;
};
