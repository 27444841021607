import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IHomeCategories } from '../shared/interface/home.interface';

import BrandNameSliderComponent from '../components/shopbybrand/BrandNameSlider';
import ProductSlider from '../components/shopbybrand/ProductSlider';
import BrandOfferComponent from '../components/shopbybrand/BrandOffer';
import AllProductComponent from '../components/shopbybrand/AllProducts';
import TopBrandsComponent from '../components/shopbybrand/TopBrands';

import { fetchShopByBrandData } from '../store/actions/shopByBrand/fetchAction';

import TransparentSpinner from '../components/spinner/TransparentSpinner';

import { EProductSliderType, EShopByType } from '../shared/enum/product.enum';
import { StateContext } from '../App';

function ShopByBrand() {
  const { data, error, loading } = useSelector((state: any) => state.shopByBrand);
  const dispatch = useDispatch<any>();

  const { categoryState } = useContext<{ categoryState: [IHomeCategories, React.Dispatch<React.SetStateAction<IHomeCategories | null>>] }>(StateContext);
  const [categories] = categoryState;

  useEffect(() => {
    dispatch(fetchShopByBrandData());
  }, [dispatch]);

  return (
    <>
      <div className='container-fluid'>
        <h5 className='text-center text-secondary font-hahmlet-bold py-2 mb-0'>Shop By Brand</h5>
        {error ? (
          <div className='text-center py-5'>{error}</div>
        ) : loading ? (
          <TransparentSpinner />
        ) : (
          <div className='row'>
            <div className='col-lg-4'>
              <BrandNameSliderComponent categories={categories?.brand_subcategories ?? []} />
              {data?.popular_products?.length > 0 && <ProductSlider type={EProductSliderType.POPULAR} popularProducts={data?.popular_products ?? []} />}
              {data?.promoted_products?.length > 0 && <ProductSlider type={EProductSliderType.DISCOUNT} popularProducts={data?.promoted_products ?? []} />}
            </div>
            <div className='col-lg-8'>
              <BrandOfferComponent banner={data?.banner} />
              <AllProductComponent category={'brand'} />
            </div>
          </div>
        )}
        <div className='mt-2'>
          <TopBrandsComponent type={EShopByType.BRAND} topProducts={data?.top_products} />
        </div>
      </div>
    </>
  );
}

export default ShopByBrand;
