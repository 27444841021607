import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import '../../scss/Navbar.scss';

import { StateContext, notifyMessage } from '../../App';

import { BASE_IMG_URL } from '../../store/constants/base.constant';

import { IPet } from '../../shared/interface/pet.interface';
import { IGlobalStorage } from '../../shared/interface/reduxStore.interface';

import { getAllPets } from '../../services/petService';

import TransparentSpinner from '../spinner/TransparentSpinner';

import { EUserType } from '../../shared/enum/user.enum';
import { EGenericPageRouteType } from '../../shared/enum/generic.enum';
import { Image } from 'react-bootstrap';
import { removeCustomerVideoCallToken, removeDoctorVideoCallToken, removeStorage, removeVet247VideoCallToken } from '../../shared/service/storageService';

function NavbarComponent() {
  const { data } = useSelector((state: IGlobalStorage) => state.user);

  const { petState, cartState } = useContext(StateContext);
  const [pets, setPets] = petState;
  const [carts] = cartState;

  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('access_token');

  const navigate = useNavigate();

  const handleLogout = () => {
    removeStorage();
    notifyMessage.SUCCESS('logged out');

    navigate('/login');
  };

  const loadAllPets = async () => {
    try {
      setLoading(() => true);
      const petRes = await getAllPets();
      setPets(() => petRes?.data ?? []);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  useEffect(() => {
    loadAllPets();
  }, []);

  return (
    <>
      {loading && <TransparentSpinner />}
      <nav style={{ zIndex: '999' }} className='navbar navbar-expand-xl bg-light'>
        <div className='container-fluid justify-content-between py-2'>
          <Link to={'/'}>
            <a className='navbar-brand order-0'>
              <Image width={130} src='/images/nexo-new-full-logo.png' alt='' />
            </a>
          </Link>
          <div className='order-1 order-xl-3 flex-grow-1 me-2 text-end d-xl-none d-inline-block'>
            <button
              className='btn btn-outline-primary btn-rounded'
              type='button'
              data-bs-toggle='collapse'
              data-bs-target='#navbarNavAltMarkup'
              aria-controls='navbarNavAltMarkup'
              aria-expanded='false'
              aria-label='Toggle navigation'>
              <i className='fa-solid fa-bars'></i>
            </button>
          </div>
          <div className='collapse justify-content-xl-center navbar-collapse order-3 order-xl-1 pb-xl-0 pb-3' id='navbarNavAltMarkup'>
            <div className='navbar-nav'>
              <Link to={`/products`}>
                <a className='nav-link'>Products</a>
              </Link>
              <Link to={`/${EGenericPageRouteType.PET_LIFESTYLE}`}>
                <a className='nav-link'>Pet Lifestyle</a>
              </Link>
              <Link to={`/${EGenericPageRouteType.PET_ENTERTAINMENT}`}>
                <a className='nav-link'>Pet Entertainment</a>
              </Link>
              <Link to={`/${EGenericPageRouteType.PET_GROOMING}`}>
                <a className='nav-link'>Pet Grooming</a>
              </Link>
              <Link to={`/${EGenericPageRouteType.PET_GUARD}`}>
                <a className='nav-link'>Pet E-Guard</a>
              </Link>
              <Link to={`/${EGenericPageRouteType.PET_CARE}`}>
                <a className='nav-link'>Pet Care</a>
              </Link>
              <Link to={`/${EGenericPageRouteType.PET_FRIENDLY}`}>
                <a className='nav-link'>Pet Friendly</a>
              </Link>
              {/* <div className='search-input'>
                <input type='text' className='form-control rounded-pill bg-primary-shade border-0' placeholder='Search' />
                <span>
                  <i className='fa-solid fa-magnifying-glass'></i>
                </span>
              </div> */}
            </div>
          </div>
          <div className='navigation order-2 order-xl-4'>
            <a href='tel:+8801919507237' className='btn btn-outline-primary btn-rounded mx-1'>
              <i className='fa-solid fa-phone object-shadow'></i>
            </a>

            <Link to={'/cart'}>
              <button className='btn btn-outline-primary position-relative btn-rounded mx-2'>
                <i className='fa-solid fa-cart-shopping object-shadow'></i>
                {carts?.items?.length > 0 && (
                  <span className='cart-item-count-container'>
                    <div>
                      <span className='position-relative'>
                        <span className='cart-item-count position-absolute'>{carts.items.length}</span>
                      </span>
                    </div>
                  </span>
                )}
              </button>
            </Link>
            {token ? (
              <div className='d-inline-block'>
                <button className='btn btn-rounded btn-outline-primary mx-1' type='button' data-bs-toggle='dropdown' data-bs-auto-close='false' aria-expanded='false'>
                  <i className='fa-solid fa-user object-shadow'></i>
                </button>
                <ul className='dropdown-menu rounded-3 user-dropdown dropdown-menu-end'>
                  <div className='bg-primary rounded-top-3 pt-4 pb-5 mb-4 position-relative'>
                    <div className='user-img overflow-hidden'>
                      {data?.profile_image ? (
                        <img src={`${BASE_IMG_URL}${data.profile_image}`} alt='' />
                      ) : (
                        <div className='bg-white position-relative h-100'>
                          <div className='position-absolute top-50 start-50 translate-middle'>
                            <i className='fa-regular fa-user text-primary fs-3'></i>
                          </div>
                        </div>
                      )}

                      {/* <button>
                        <i className='fa-solid fa-plus'></i>
                      </button> */}
                    </div>
                    <Link to={'/profile'}>
                      <a className='user-edit-tool'>
                        <i className='fa-solid fa-pencil text-white'></i>
                      </a>
                    </Link>
                  </div>
                  <div className='text-center pt-3'>
                    <p className='px-4 mb-1 line-fix-1 font-pop-semibold text-secondary'>{data?.name} </p>
                    <p className='fs-sm text-gray'>{data?.user?.user_type}</p>
                  </div>
                  <div className='my-3 border-bottom-dashed mx-3 border-bottom border-secondary-tint-1'></div>
                  <div className='accordion pb-4 mx-3 accordion-flush user-accordion' id='accordionFlushExample'>
                    {data?.user?.user_type === EUserType.CUSTOMER && (
                      <>
                        <div className='accordion-item py-2'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#flush-collapseOne'
                            aria-expanded='false'
                            aria-controls='flush-collapseOne'>
                            <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                              <i className='fa-solid fa-paw'></i>
                            </button>
                            <span className='text-gray'>My pets</span>
                          </button>
                          <div id='flush-collapseOne' className='accordion-collapse collapse' data-bs-parent='#accordionFlushExample'>
                            <div className='accordion-body pe-0'>
                              <ul className='bg-primary-tint-1 rounded-2'>
                                {pets?.map((pet: IPet) => (
                                  <li className='border-bottom border-white ps-3 pe-2 py-2'>
                                    <Link to={`/pet/${pet?.id}`}>
                                      <a className='d-flex justify-content-between align-items-center'>
                                        <div>
                                          <i className='fa-solid fa-paw pe-3'></i>
                                          <span className='text-gray'>{pet?.name}</span>
                                        </div>
                                        {/* <i className='fa-solid fa-angle-right text-secondary-tint-1'></i> */}
                                      </a>
                                    </Link>
                                  </li>
                                ))}
                                <li className='border-bottom border-white ps-3 pe-2 py-2'>
                                  <Link to={'/add-pet'}>
                                    <a className='d-flex justify-content-between align-items-center'>
                                      <div>
                                        <i className='fa-solid fa-plus pe-3'></i>
                                        <span className='text-gray'>Add a new Pet</span>
                                      </div>
                                      {/* <i className='fa-solid fa-angle-right text-secondary-tint-1'></i> */}
                                    </a>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='accordion-item py-2'>
                          <Link to={'/order-list'}>
                            <button className='accordion-button collapsed' type='button'>
                              <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                                <i className='fa-solid fa-clipboard-list'></i>
                              </button>
                              <span className='text-gray'>Order List</span>
                            </button>
                          </Link>
                        </div>
                        <div className='accordion-item py-2'>
                          <Link to={'/appointment'}>
                            <button className='accordion-button collapsed' type='button'>
                              <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                                <i className='fa-solid fa-calendar-plus'></i>
                              </button>
                              <span className='text-gray'>Appointments</span>
                            </button>
                          </Link>
                        </div>
                        {/* customer vet 247  */}
                        <div className='accordion-item py-2'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#flush-collapseTwo'
                            aria-expanded='false'
                            aria-controls='flush-collapseTwo'>
                            <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                              <i className='fa-solid fa-clock'></i>
                            </button>
                            <span className='text-gray'>Vet 24/7</span>
                          </button>
                          <div id='flush-collapseTwo' className='accordion-collapse collapse' data-bs-parent='#accordionFlushExample'>
                            <div className='accordion-body pe-0'>
                              <ul className='bg-primary-tint-1 rounded-2'>
                                <li className='border-bottom border-white ps-3 pe-2 py-2'>
                                  <Link to={`/vet247`}>
                                    <a className='d-flex justify-content-between align-items-center'>
                                      <div>
                                        <span className='text-gray'>Subscription</span>
                                        {data && data?.remaining_days && <span className='text-xs'> (left {data.remaining_days} days)</span>}
                                      </div>
                                    </a>
                                  </Link>
                                </li>
                                <li className='border-bottom border-white ps-3 pe-2 py-2'>
                                  <Link to={'/vet247/appointment'}>
                                    <a className='d-flex justify-content-between align-items-center'>
                                      <div>
                                        <span className='text-gray'>24/7 Services</span>
                                      </div>
                                    </a>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {data?.user?.user_type === EUserType.DOCTOR && (
                      <>
                        <div className='accordion-item py-2'>
                          <Link to={'/doctor/profile'}>
                            <button className='accordion-button collapsed' type='button'>
                              <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                                <i className='fa-solid fa-user'></i>
                              </button>
                              <span className='text-gray'>My Profile</span>
                            </button>
                          </Link>
                        </div>
                        <div className='accordion-item py-2'>
                          <Link to={'/appointment'}>
                            <button className='accordion-button collapsed' type='button'>
                              <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                                <i className='fa-solid fa-calendar-plus'></i>
                              </button>
                              <span className='text-gray'>Appointments</span>
                            </button>
                          </Link>
                        </div>
                      </>
                    )}
                    {data?.user?.user_type === EUserType.VET247 && (
                      <>
                        <div className='accordion-item py-2'>
                          <Link to={'/vet247/appointment'}>
                            <button className='accordion-button collapsed' type='button'>
                              <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                                <i className='fa-solid fa-calendar-plus'></i>
                              </button>
                              <span className='text-gray'>Appointments</span>
                            </button>
                          </Link>
                        </div>
                      </>
                    )}
                    {data?.user?.user_type === EUserType.SUPPLIER && (
                      <>
                        <div className='accordion-item py-2'>
                          <Link to={'/dashboard'}>
                            <button className='accordion-button collapsed' type='button'>
                              <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                                <i className='fa-solid fa-clipboard-list'></i>
                              </button>
                              <span className='text-gray'>Dashboard</span>
                            </button>
                          </Link>
                        </div>
                      </>
                    )}
                    {/* <div className='accordion-item py-2'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-collapseFour'
                        aria-expanded='false'
                        aria-controls='flush-collapseFour'>
                        <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                          <i className='fa-solid fa-gear'></i>
                        </button>
                        <span className='text-gray'>Settings</span>
                      </button>
                      <div id='flush-collapseFour' className='accordion-collapse collapse' data-bs-parent='#accordionFlushExample'>
                        <div className='accordion-body'>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam illum ea vero at deserunt voluptas tempora, veniam voluptates quasi, placeat ex eaque facilis corrupti
                          beatae nobis quia temporibus quas nisi.
                        </div>
                      </div>
                    </div> */}
                    <div className='accordion-item py-2'>
                      <button
                        onClick={handleLogout}
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-collapseFive'
                        aria-expanded='false'
                        aria-controls='flush-collapseFive'>
                        <button className='btn btn-rounded bg-primary-tint-1 text-primary me-3'>
                          <i className='fa-solid fa-power-off'></i>
                        </button>
                        <span className='text-gray'>Logout</span>
                      </button>
                    </div>
                  </div>
                </ul>
              </div>
            ) : (
              <Link to={'/login'}>
                <button className='btn btn-primary rounded-pill text-white px-4 mx-1' type='button'>
                  Login
                </button>
              </Link>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavbarComponent;
