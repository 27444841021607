function DashboardAllProducts({ data }: any) {
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  return (
    <>
      <div className='card rounded-4 pt-4 px-3 pb-2 border-snow-white shadow-sm'>
        <div className='card-title'>
          <h5>All Products</h5>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{ width: '10%' }}>#</th>
                  <th style={{ width: '40%' }}>Name</th>
                  <th style={{ width: '10%' }}>Stock</th>
                  <th style={{ width: '20%' }}>Date</th>
                  <th style={{ width: '20%' }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>
                      <div className='value p-2 text-center rounded-3'>{item.stock}</div>
                    </td>
                    <td>{formatDate(item.updated_at)}</td>
                    <td>৳{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardAllProducts;
