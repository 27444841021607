import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { login } from '../../store/actions/auth/loginAction';

import OTPInput from './otpInput';

function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const { error: errorMsg, loading } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch<any>();
  const [phone, setPhone] = useState<any>(null);

  const { register, handleSubmit, setValue, reset } = useForm<any>({
    resolver: yupResolver(yup.object().shape({})),
  });

  const handleOtpChange = (otp: string) => {
    setValue('otp', otp);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: any) => {
    const otpValue = data.otp;
    await dispatch(login({ otp: otpValue, phone: phone }, reset, location, navigate));
  };

  useEffect(() => {
    const phone = localStorage.getItem('phone');
    if (!phone) {
      navigate('/signup');
    }
    setPhone(phone);
  }, [phone]);

  return (
    <>
      {errorMsg && <div className='text-primary'>{errorMsg}</div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='my-2'>
          <OTPInput
            autoFocus
            isNumberInput
            length={5}
            className='otpContainer'
            inputClassName='otpInput'
            onChangeOTP={handleOtpChange}
            {...register('otp')} // Register the OTP field with React Hook Form
          />
        </div>
        <button type='submit' disabled={loading} className='btn btn-primary text-white w-100 rounded-3 my-4 my-4'>
          {loading ? 'Loading...' : 'Confirm'}
        </button>
      </form>
    </>
  );
}

export default LoginForm;
