import { IFaq } from '../../shared/interface/home.interface';

function faq({ faqs }: { faqs: IFaq[] }) {
  return (
    <div className='mt-2'>
      <div className='card border-0 bg-light-200 pt-0'>
        <div className='card-body'>
          <h5 className='text-secondary font-hahmlet-medium'>FAQ</h5>
          <div className='accordion' id='accordionExample'>
            {faqs?.map((faq: IFaq, index) => (
              <div key={faq.id + '_faq_' + index} className='py-2'>
                <button
                  className='w-100 border-0 bg-transparent accordion-toggle  collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#${'faq' + faq.id + index}`}
                  aria-expanded='true'
                  aria-controls='collapseOne'>
                  <div className='d-flex justify-content-between'>
                    <small className='text-start'>{faq?.question ?? '-'}</small>
                    <small className='text-primary'>
                      <i className='fa-regular fa-square-plus'></i>
                    </small>
                  </div>
                </button>
                <div id={`${'faq' + faq.id + index}`} className='accordion-collapse collapse' data-bs-parent='#accordionExample'>
                  <p className='fs-xs px-3 accordion-body'>{faq?.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default faq;
