import { useContext, useState } from 'react';

import { IProduct } from '../../shared/interface/product.interface';

import { addToCart, createCart, getCarts } from '../../services/cartService';

import { StateContext, notifyMessage } from '../../App';

import ReactPixel from 'react-facebook-pixel';

function AddToCartBtnComponent({ product }: { product: IProduct }) {
  const { createCartId, cartState } = useContext(StateContext);
  const setCarts = cartState[1];

  const [loading, setLoading] = useState<boolean>(false);

  const loadCarts = async () => {
    try {
      setLoading(() => true);
      const cartRes = await getCarts();
      setCarts(() => cartRes?.data ?? null);
      setLoading(() => false);
    } catch (error) {
      setLoading(() => false);
    }
  };

  const handleAddToCart = async () => {
    setLoading(() => true);
    // Add to cart event pixel
    ReactPixel.track('AddToCart', {
      price: product?.selling_price,
      brand: "",
      category: product?.supplier_product?.category,
      quantity: 1,
      product_name: product?.supplier_product?.title
    });
    const cartRes = await addToCart({
      product_id: product.id,
      quantity: 1,
    });
    await loadCarts();
    notifyMessage.SUCCESS(cartRes?.message ?? 'Successfully added to cart');
    setLoading(() => false);
  };

  const handleCart = async (e: any) => {
    e.preventDefault();
    try {
      await createCartId();
      await handleAddToCart();
    } catch (error: any) {
      if (error?.response?.data?.message.includes('Invalid')) {
        await createCart();
        await handleAddToCart();
        setLoading(() => false);
      } else {
        setLoading(() => false);
        notifyMessage.ERROR(error?.response?.data?.message ?? 'Cannot be added to cart');
      }
    }
  };

  return (
    <div>
      <button disabled={loading} onClick={handleCart} className='btn btn-primary px-3 px-sm-2 py-2 btn-sm fw-bold rounded-2 rounded-4'>
        {loading && <span className='spinner-border spinner-border-sm text-white' role='status'></span>} <span className='d-none d-sm-inline-block'>Add to Cart</span>
        <span className='d-sm-none d-inline-block'>
          <i className='fa-solid fa-cart-shopping'></i>
        </span>
      </button>
    </div>
  );
}

export default AddToCartBtnComponent;
