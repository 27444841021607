import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { axiosConfig } from '../shared/constant/axiosConfig';

import { IHttpResponse } from '../shared/interface/http.interface';
import { IUserInfo, IUserUpdate } from '../shared/interface/user.interface';

export const getUserInfo = async () => {
  const { data } = await axios.get<IHttpResponse<IUserInfo>>(`${BASE_URL}/users/user-info`, axiosConfig());
  return data;
};

export const updateUserInfo = async (payload: FormData) => {
  const { data } = await axios.patch<IHttpResponse<IUserUpdate>>(`${BASE_URL}/users/update-user-info`, payload, axiosConfig());
  return data;
};
