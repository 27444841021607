import axios from 'axios';

import { BASE_URL } from '../store/constants/base.constant';

import { IPagination } from '../shared/interface/app.interface';
import { IHttpResponse } from '../shared/interface/http.interface';
import { IAddReviewPayload } from '../shared/interface/review.interface';
import { IProduct, IProductType } from '../shared/interface/product.interface';

import { axiosConfig } from '../shared/constant/axiosConfig';

export const getProductTypes = async () => {
  const token = localStorage.getItem('access_token');

  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};

  const { data } = await axios.get<IHttpResponse<IProductType[]>>(`${BASE_URL}/suppliers/product-types`, config);
  return data;
};

export const getProductsByURL = async (url: string) => {
  const token = localStorage.getItem('access_token');

  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};

  const { data } = await axios.get<IHttpResponse<IPagination<IProduct[]>>>(url, config);
  return data?.data;
};

export const getAllProducts = async (params?: { search?: string; ordering?: string; category?: string; sub_category?: string; product_type?: string; page_size?: number }) => {
  const token = localStorage.getItem('access_token');

  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};

  const { data } = await axios.get<IHttpResponse<IPagination<IProduct[]>>>(`${BASE_URL}/customers/products/`, { ...config, params });
  return data?.data;
};

export const getOneProduct = async (id: number) => {
  const token = localStorage.getItem('access_token');

  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};

  const { data } = await axios.get<IHttpResponse<IProduct>>(`${BASE_URL}/customers/products/${id}/`, config);
  return data;
};

export const addProductReview = async (productId: number, payload: IAddReviewPayload) => {
  const { data } = await axios.post<IHttpResponse<any>>(`${BASE_URL}/customers/products/${productId}/reviews/`, payload, axiosConfig());
  return data;
};
