import { EShopByType } from '../../shared/enum/product.enum';
import { BASE_IMG_URL } from '../../store/constants/base.constant';

function TopBrands({ type, topProducts }: { type: string; topProducts: any }) {
  return (
    <div>
      <h5 className='text-secondary font-hahmlet-medium mb-2 '>
        Top {type === EShopByType.PET && 'Pets'} {type === EShopByType.BRAND && 'Brands'}
      </h5>
      <div className='row'>
        {topProducts?.map((item: any, index: number) => (
          <div key={index} className='col-xl-1 col-lg-2 col-md-3 col-sm-3 col-4 text-center py-2 py-xl-0 my-2'>
            <img className='shadow-sm rounded-3 w-100 h-100' src={`${BASE_IMG_URL}/${item?.supplier_product?.images[0]?.image}`} alt='' />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopBrands;
